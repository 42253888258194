import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { addContentOfPages, getContentForManagePages } from '../api';
import { decodeBase64 } from '../../../../helpers/commonFunctions';
import { StaticContentTypeEnum } from '../../../helpers/enum';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';

const TermsConditions = () => {
  const [bodyText, setBodyText] = useState<any>('');

  const {
    mutateAsync: getTermsAndConditions,
    data: privacyPolicyData,
    isLoading: isLoading,
  } = useMutation('terms', getContentForManagePages);

  const { mutateAsync: addEditContent, isLoading: isAdding } = useMutation(
    'add-about-us',
    addContentOfPages
  );

  useEffect(() => {
    getTermsAndConditions({ type: StaticContentTypeEnum?.TermsAndCondition });
  }, []);

  useEffect(() => {
    if (privacyPolicyData?.Result?.[0]?.Content) {
      const decodedValue = decodeBase64(
        privacyPolicyData?.Result?.[0]?.Content
      );
      setBodyText(decodedValue);
    }
  }, [privacyPolicyData]);

  const handleSubmit = async () => {
    const encodedData = btoa(bodyText);
    const bodyParams = {
      id:
        privacyPolicyData?.Result && privacyPolicyData?.Result?.length > 0
          ? privacyPolicyData?.Result?.[0]?.StaticContentId
          : 0,
      contentType: StaticContentTypeEnum?.TermsAndCondition,
      content: encodedData,
    };
    let response = await addEditContent(bodyParams);
    if (response?.IsSuccess) {
      snackActions.success(
        response?.message ?? 'Terms and Condition Updated Successfully!'
      );
    } else {
      snackActions.error(response?.message ?? 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10 p-14">
        <ReactQuill
          theme="snow"
          placeholder="Write something..."
          modules={TermsConditions.modules}
          formats={TermsConditions.formats}
          style={{ height: '50vh' }}
          value={bodyText}
          onChange={e => setBodyText(e)}
        />
        <div className="d-flex justify-content-end">
          {/* <Button variant="success" style={{ width: "100px", marginTop: "50px" }} onClick={handleSubmit} disabled={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "")} color={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, "") ? "disabled" : "success"}>{isAddingTerms ? "Loading." : "Submit"}</Button>{' '} */}
          <Button
            variant="success"
            style={{ width: '100px', marginTop: '50px' }}
            onClick={handleSubmit}
          >
            {isAdding ? 'Loading..' : 'Submit'}
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};

export default TermsConditions;

TermsConditions.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: [] }],
  ],
};
TermsConditions.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];
