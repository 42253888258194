import { APP_URLs, config } from '../../../../constants/appURLs';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import httpClient from './httpClient';

const refreshToken = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.refreshToken.refresh}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const Forgotpassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.forgotpassword.sendotpforverification}?email=${payloadData?.email}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const OTPverification = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.otpverification.verifyotp}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const passwordchange = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.passwordchange.changedpassword}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const ChangePassword = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.passwordchange.ChangePassword}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const createcompany = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.company.CreateCompany}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getDashboard = async (query: any) => {
  try {
    const params: any = {};

    if (query?.startDate) {
      params.startDate = query?.startDate;
    }
    if (query?.endDate) {
      params.endDate = query?.endDate;
    }
    if (query?.startTime) {
      params.startTime = query?.startTime;
    }
    if (query?.endTime) {
      params.endTime = query?.endTime;
    }
    if (query?.golfId) {
      params.golfId = query?.golfId;
    }
    if (query?.player) {
      params.player = query?.player;
    }

    if (query?.responseTime) {
      params.responseTime = query?.responseTime;
    }

    const queryString = new URLSearchParams(params).toString();
    const { data } = await httpClient.get(
      `${APP_URLs.dashboard.getDashboard}?${queryString}`,

      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(
      error.response.data.Message ?? 'Something went wrong!, please try again'
    );
  }
};

const getCountries = async () => {
  try {
    const { data } = await httpClient.get(
      APP_URLs.commonEndPoints.getCountries,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getStates = async (payloadData: { countryId: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getStates}?countryId=${
        payloadData?.countryId
      }&isActive=${true}&Take=${1000}`,
      config
    );

    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanies = async () => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.companydetails.companies}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const getCompaniesWithoutGeneral = async () => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.companydetails.companies}?isIncludeGeneralCompany=false`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCities = async (payloadData: { stateId: any; countryId: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.commonEndPoints.getCities}?stateId=${payloadData?.stateId}&countryId=${payloadData.countryId}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const uploadPicture = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.mediaUpload,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getAppProviderList = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.companyId = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.appUser.appProviderList}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanyDetails = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.Id = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();
    const { data } = await httpClient.get(
      `${APP_URLs.companydetails.companylist}?${queryString}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanyDetailsById = async (payloadData: { id: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.companydetailsbyid.companylist}?id=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const getViewAssignmentSchedule = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.assignmentschedule.getViewAssignmentschedule}?assignmentId=${payloadData}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanyAddress = async (payloadData: { id: number }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.companydetails.companyaddress}?companyId=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getNursingHomeAddresseById = async () => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.nursinghome.NursingHomeAddressesByCompanyId}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const providerData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.provider.providerAPI}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const addProviderThrive = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.thriveEndpoints.thriveCommonAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const editClinician = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.appUser.editClinician,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderStatus = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.appUser.updateProviderStatus}?Id=${payloadData?.id}&Status=${payloadData?.status}&Remarks=${payloadData?.reason}`,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateCompany = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.companydetailsbyid.updateCompany,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deactivateCompany = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.companydetailsbyid.deactiveCompany}?id=${payloadData?.id}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getDocumentList = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.companyId = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.appUser.companyDocument}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getDocumentTypeList = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.documentCategory = 3;
    // params.OrderByDirection = 'DESC';
    // params.OrderByColumn = 'CreatedOn';

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.appUser.documentTypes}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const getSkills = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.skill.getSkills}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanyName = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.companydetails.companies}?isIncludeGeneralCompany=${false}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCompanyDocument = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? 0;
    params.Take = query?.limit ?? 10;
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.companyId = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.appUser.companyDocument}?${queryString}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deActiveCompany = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.companydetails.updateCompanyStatus,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const documentData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.documents.documentAPI}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const documentTypeData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.documents.documentTypeAPI}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderProfile = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.appUser.updateProviderProfile}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const addUpdateCompanyProviderDocuments = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.appUser.addUpdateCompanyProviderDocuments}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deleteSkill = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(`${APP_URLs.skill.deleteSkill}`, {
      ...config,
      data: payloadData,
    });
    return data;
  } catch (error: any) {}
};

const deleteCity = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(`${APP_URLs.city.deletecity}`, {
      ...config,
      data: payloadData,
    });
    return data;
  } catch (error: any) {}
};

const getSkillsData = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.skill.getSkillsData}?Skip=${query?.offset ?? ''}&Take=${
        query?.limit ?? ''
      }&SearchText=${
        query?.search ?? ''
      }&OrderByDirection=DESC&OrderByColumn=CreatedOn`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getCityData = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.city.getcity}?Skip=${query?.offset ?? ''}&Take=${
        query?.limit ?? ''
      }&SearchText=${
        query?.search ?? ''
      }&OrderByDirection=DESC&OrderByColumn=CreatedOn`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const editdocumentData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.documents.editdocumentAPI}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const editdocumentTypeData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.documents.editdocumentTypeAPI}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deleteDocument = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.documents.deletedocumentAPI}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const deleteDocumentType = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.documents.deletedocumentTypeAPI}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getAssignment = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.companyId = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.assignments.getAssignment}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getAssignmentById = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.assignments.getAssignmentById}?id=${payloadData.id}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const dropAssignments = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.assignments.dropAssignment}?Id=${payloadData.Id}&Status=${payloadData.Status}&Remarks=${payloadData.Remarks}`,
      '',
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const UpdateAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.assignments.updateAssignment,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const DeleteAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.assignments.deleteAssignment}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getSearchprovider = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.provider.searchprovider}?companyId=${query.id}&skillIds=${
        query.skillIds
      }&Skip=${query?.offset ?? ''}&Take=${query?.limit ?? ''}&SearchText=${
        query?.search ?? ''
      }&programId=${query?.programId ?? []}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getViewInviteeData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.provider.viewInvitee}?assignmentScheduleId=${payloadData}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const addAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.assignments.addAssignment,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getPatientdetail = async () => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.patinet.patientdetail}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getStaff = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.staff.getStaff}?Skip=${query?.offset ?? ''}&Take=${
        query?.limit ?? ''
      }&SearchText=${
        query?.search ?? ''
      }&OrderByDirection=DESC&OrderByColumn=CreatedOn`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.Message ?? 'Something went wrong');
  }
};

const addStaff = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.staff.addStaff}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(
      error?.response.data.Message
        ? error?.response.data.Message
        : 'Something went wrong'
    );
  }
};

const editStaff = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.staff.editStaff,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? 'Something went wrong');
  }
};

const editSkill = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.skill.editskillAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const skillData = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.skill.createSkill}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Skill already exist.');
  }
};

const updateProviderSkill = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.appUser.updateProviderSkill}?Id=${payloadData?.id}&Status=${
        payloadData?.status
      }&UpdatedByCompanyId=${1}&Remarks=${payloadData?.reason}
      `,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderReference = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.provider.updateProviderReferenceStatus}?Id=${payloadData?.id}&Status=${payloadData?.status}&Remarks=${payloadData?.reason}
      `,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getproviderbyid = async (payloadData: { id: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.provider.editprovider}?id=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateStatusOfClinician = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.provider.updateClinicianStatus}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {}
};

const getAssignmentSchedules = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.assignmentschedule.getAssignmentschedule}?Skip=${
        query?.offset ?? ''
      }&Take=${query?.limit ?? ''}&SearchText=${query?.search ?? ''}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.message ?? 'Something went wrong');
  }
};

const getAssignmentSchedulesbyassignment = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.assignmentschedule.getAssignmentschedulebyassignment}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.message ?? 'Something went wrong');
  }
};

const getassignmentscheduleid = async (payloadData: { id: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.assignmentschedule.getAssignmentscheduleid}?assignmentId=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const verifyassignmentschedule = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.assignmentschedule.assignmentStatus}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const DropAssignment = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.assignmentschedule.DropAssignment}?Id=${payloadData?.id}&Status=${payloadData?.status}&Remarks=${payloadData?.reason}`,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const addNursinghome = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.nursinghome.createnursinghome,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const addCity = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.city.addcity,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getNursinghome = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    if (query?.companyId) {
      params.companyId = query?.companyId;
    }

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.nursinghome.getNursinghome}?${queryString}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.message ?? 'Something went wrong');
  }
};

const editnursinghome = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.nursinghome.editnursinghomeAPI,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const editCity = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.city.editcity,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deletenursinghome = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.nursinghome.deletenursinghomeAPI}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getShift = async () => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.nursinghome.getShifts}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getShiftDetail = async (query: any) => {
  try {
    const params: any = {};
    params.Skip = query?.offset ?? '';
    params.Take = query?.limit ?? '';
    params.SearchText = query?.search ?? '';
    params.OrderByDirection = 'DESC';
    params.OrderByColumn = 'CreatedOn';

    const queryString = new URLSearchParams(params).toString();

    const { data } = await httpClient.get(
      `${APP_URLs.nursinghome.getShiftsDetails}?${queryString}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const createShift = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      APP_URLs.nursinghome.createShift,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const editShift = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      APP_URLs.nursinghome.editShift,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deleteShift = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.nursinghome.deletedShiftAPI}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getassignmentschedulebyid = async (payloadData: { id: any }) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.assignmentschedule.getAssignmentschedulebyidAPI}?id=${payloadData?.id}`,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

//Program API's
const getProgramDetailsList = async (query: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.program.getProgramDetails}?Skip=${query?.offset ?? ''}&Take=${
        query?.limit ?? ''
      }&SearchText=${
        query?.search ?? ''
      }&OrderByDirection=DESC&OrderByColumn=CreatedOn`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const getProgram = async (query: any) => {
  // for assignment dropdown
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.program.getPrograms}?Skip=${query?.offset ?? ''}&Take=${
        query?.limit ?? ''
      }&SearchText=${
        query?.search ?? ''
      }&OrderByDirection=DESC&OrderByColumn=CreatedOn`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProgram = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.program.editProgram}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const createProgram = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.program.createProgram}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const deleteProgram = async (payloadData: any) => {
  try {
    const { data } = await httpClient.delete(
      `${APP_URLs.program.deleteProgram}`,
      {
        ...config,
        data: payloadData,
      }
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.message ?? 'Something went wrong');
  }
};

// Clinician status update APIs

const updateProviderDNRStatus = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.provider.updateProviderDNRStatus}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderDocument = async (payloadData: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.appUser.updateProviderDocument}?Id=${payloadData?.id}&Status=${payloadData?.status}&Remarks=${payloadData?.reason}
      `,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const getProviderDocumentById = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.provider.getProviderDocumentsByProviderId}?id=${payloadData.id}`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderDNRStatusToTerminate = async (payload: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.provider.updateProviderDNRStatusToTerminate}?ProviderId=${payload?.ProviderId}&IsTerminated=${payload?.IsTerminated}&Remarks=${payload?.Remarks}`,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

const updateProviderDNRStatusToSuspend = async (payload: any) => {
  try {
    const { data } = await httpClient.put(
      `${APP_URLs.provider.updateProviderDNRStatusToSuspend}?ProviderId=${payload?.ProviderId}&IsSuspended=${payload?.IsSuspended}&SuspensionDays=${payload?.SuspensionDays}&Remarks=${payload?.Remarks}`,
      '',
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

//document related APIs

const getDocumentsDetails = async (query: any) => {
  const { parentId, hasParent = false } = query;
  const skip = 0;
  const take = 1000;
  let url = `${APP_URLs.documents?.getClinicianDocument}?Take=${take}&hasParent=${hasParent}`;
  if (parentId != null) {
    url += `&parentId=${parentId}`;
  }

  try {
    const { data } = await httpClient.get(url, config);
    return data || [];
  } catch (error: any) {
    snackActions.error(
      error?.response?.data?.Message ?? 'Something went wrong'
    );
  }
};

// manage-pages related api
const getContentForManagePages = async (payloadData: any) => {
  try {
    const { data } = await httpClient.get(
      `${APP_URLs.staticContent?.genericAPIs}?contentType=${payloadData.type}&isActive=true`,
      config
    );
    return data || [];
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};
const addContentOfPages = async (payloadData: any) => {
  try {
    const { data } = await httpClient.post(
      `${APP_URLs.staticContent?.postContent}`,
      payloadData,
      config
    );
    return data;
  } catch (error: any) {
    snackActions.error(error?.response.data.Message ?? 'Something went wrong');
  }
};

export {
  getContentForManagePages,
  addContentOfPages,
  addAssignment,
  getCompaniesWithoutGeneral,
  addNursinghome,
  addProviderThrive,
  addStaff,
  updateProviderProfile,
  ChangePassword,
  createcompany,
  createProgram,
  createShift,
  deactivateCompany,
  deActiveCompany,
  DeleteAssignment,
  deleteDocument,
  deleteDocumentType,
  deletenursinghome,
  deleteProgram,
  deleteShift,
  deleteSkill,
  documentData,
  documentTypeData,
  DropAssignment,
  dropAssignments,
  editClinician,
  editdocumentData,
  editdocumentTypeData,
  editnursinghome,
  editShift,
  editSkill,
  editStaff,
  Forgotpassword,
  getAppProviderList,
  getAssignment,
  getAssignmentById,
  getassignmentschedulebyid,
  getassignmentscheduleid,
  getAssignmentSchedules,
  getAssignmentSchedulesbyassignment,
  getCities,
  getCompanies,
  getCompanyAddress,
  getCompanyDetails,
  getCompanyDetailsById,
  getCompanyDocument,
  getCompanyName,
  getCountries,
  getDashboard,
  getDocumentList,
  getDocumentTypeList,
  getDocumentsDetails,
  getNursinghome,
  getNursingHomeAddresseById,
  getPatientdetail,
  getProgram,
  getProgramDetailsList,
  getproviderbyid,
  getProviderDocumentById,
  getSearchprovider,
  getShift,
  getShiftDetail,
  getSkills,
  getSkillsData,
  getStaff,
  getStates,
  getViewAssignmentSchedule,
  getViewInviteeData,
  OTPverification,
  passwordchange,
  providerData,
  skillData,
  UpdateAssignment,
  updateCompany,
  updateProgram,
  updateProviderDNRStatus,
  updateProviderDNRStatusToSuspend,
  updateProviderDNRStatusToTerminate,
  updateProviderDocument,
  updateProviderReference,
  updateProviderSkill,
  updateProviderStatus,
  updateStatusOfClinician,
  uploadPicture,
  verifyassignmentschedule,
  addUpdateCompanyProviderDocuments,
  refreshToken,
  addCity,
  getCityData,
  deleteCity,
  editCity
};
