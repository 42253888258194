import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Outlet } from 'react-router-dom';
import { I18nProvider } from './_metronic/i18n/i18nProvider';
import { MasterInit } from './_metronic/layout/MasterInit';
import { Theme } from './_metronic/layout/ThemeProvider/style';
import { LayoutProvider, LayoutSplashScreen } from './_metronic/layout/core';
import { SnackbarUtilsConfigurator } from './helpers/SnackUtilsConfigurator';

const App = () => {
  const queryClient = new QueryClient();

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <QueryClientProvider client={queryClient}>
          <LayoutProvider>
            <SnackbarProvider>
              <ThemeProvider theme={Theme}>
                <Outlet />
              </ThemeProvider>
              <MasterInit />
              <SnackbarUtilsConfigurator />
            </SnackbarProvider>
          </LayoutProvider>
        </QueryClientProvider>
      </I18nProvider>
    </Suspense>
  );
};

export { App };
