import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slice/auth/authSlice';
import headerReducer from './slice/header/headerSlice';
import assignmentReducer from './slice/form/assignment_form';
import clinicianDocReducer from './slice/form/document_form';

const store = configureStore({
  reducer: {
    auth: authReducer,
    header: headerReducer,
    assignment: assignmentReducer,
    clinicianDoc: clinicianDocReducer,
  },
});

export default store;
