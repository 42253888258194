const charValidate = (text: string, limit: number) => {
  if (text && typeof text !== 'undefined') {
    if (text.length > limit) {
      return `${text.substring(0, limit - 3)}...`;
    } else {
      return `${text.substring(0, limit)}`;
    }
  } else {
    return '';
  }
};

export { charValidate };
