import { Box } from '@mui/material';
import { Col, Row } from 'react-bootstrap';
import { PageLink } from '../../_metronic/layout/core';
import '../chat/Chat.css';

const chatBreadCrumbs: Array<PageLink> = [
  {
    title: 'Chat',
    path: '/apps/chat/private-chat',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
];

const ChatPage = () => {
  return (
    <div>
      <Row>
        <Col xs={3}>
          <div
            className="rounded-4"
            style={{ backgroundColor: 'white', height: '100vh' }}
          >
            <Box sx={{ color: '#f9f9f9' }}>
              <div
                className="mb-sm-0 mb-4 p-5"
                style={{ position: 'relative' }}
              >
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid chat-search-provider"
                  placeholder="Search Clinician..."
                  aria-label="Search"
                />
              </div>
            </Box>
          </div>
        </Col>

        <Col xs={9}>
          <div
            className="rounded-4"
            style={{ backgroundColor: 'white', height: '100vh' }}
          >
            <div className="d-flex py-5 mx-12 profile-header-main">
              <div className="chat-profile-image">
                <img
                  className="profile-image"
                  src="https://img.freepik.com/free-psd/3d-illustration-person-with-sunglasses_23-2149436188.jpg?w=740&t=st=1719403848~exp=1719404448~hmac=2f888fc7ff43bee966b00aee5af6dea542f34d46eb7cc63a5c56da60aa3a42c7"
                  alt="Profile"
                />
              </div>
              <div className="d-flex flex-column p-1 ms-2">
                <label>John Doe</label>
                <label style={{ color: '#919EAB' }}>Available</label>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {/* <Route element={<Outlet />}>
        <Route
          path="private-chat"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Private chat</PageTitle>
              <Private />
            </>
          }
        />
        <Route
          path="group-chat"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Group chat</PageTitle>
              <Group />
            </>
          }
        />
        <Route
          path="drawer-chat"
          element={
            <>
              <PageTitle breadcrumbs={chatBreadCrumbs}>Drawer chat</PageTitle>
              <Drawer />
            </>
          }
        />
        <Route index element={<Navigate to="/apps/chat/private-chat" />} />
      </Route>
    </Routes> */}
    </div>
  );
};

export default ChatPage;
