import { useFormik } from 'formik';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import {
  createProgram,
  updateProgram,
} from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import './program.css';

const modalsRoot = document.getElementById('root-modals') || document.body;

const Addprogram = ({ show, handleClose, editData }: any) => {
  const validationSchema = Yup.object().shape({
    programName: Yup.string().trim().required('Program is required'),
  });

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'add-program',
    createProgram
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    'edit-program',
    updateProgram
  );

  const formik: any = useFormik({
    initialValues: {
      programName: editData?.Name ?? '',
    },
    validationSchema,
    onSubmit: async values => {
      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        name: values?.programName ?? '',
      };
      let response = editData
        ? await editAPI(bodyParams)
        : await addAPI(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response?.Message);
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.Message);
      }
    },
  });

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3 align-items-center justify-content-center"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="programModel">
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Program</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="gy-3">
                <Col xs={12}>
                  <label className="form-label fw-bold">
                    Program{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <div>
                    <input
                      type="number"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 programInput autofillField"
                      placeholder="Enter Program"
                      {...formik.getFieldProps('programName')}
                    />
                    {formik.touched.programName &&
                      formik.errors.programName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.programName}
                          </div>
                        </div>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="d-flex justify-content-end p-6 pt-3">
            <Button type="submit" size="lg" variant="primary">
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default Addprogram;
