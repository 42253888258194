import clsx from 'clsx';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { setData } from '../../../../../store/slice/header/headerSlice';
import { ReactComponent as Assignment } from '../../../../assets/svg/ic_assignment.svg';
import { ReactComponent as Chat } from '../../../../assets/svg/ic_chat.svg';
import { ReactComponent as Clincian } from '../../../../assets/svg/ic_clinician_leads.svg';
import { ReactComponent as Company } from '../../../../assets/svg/ic_company.svg';
import { ReactComponent as Documents } from '../../../../assets/svg/ic_documents.svg';
import { ReactComponent as Provider } from '../../../../assets/svg/ic_provider.svg';
import { ReactComponent as TimeSheet } from '../../../../assets/svg/ic_timesheet.svg';
import { ReactComponent as Staff } from '../../../../assets/svg/ic_staff.svg';
import { ReactComponent as Skill } from '../../../../assets/svg/ic_skill.svg';
import { ReactComponent as Shiftapproval } from '../../../../assets/svg/ic_shiftapproval.svg';
import { ReactComponent as CreateShift } from '../../../../assets/svg/nurshing-home.svg';
import { ReactComponent as ShiftCreation } from '../../../../assets/svg/medical.svg';
import { ReactComponent as Program } from '../../../../assets/svg/event.svg';
import { ReactComponent as City } from '../../../../assets/svg/city-icon.svg';

import { checkIsActive, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';
import path from 'path';

type Props = {
  to: string;
  title: string;
  icon?: string;
  fontIcon?: string;
  hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const { config } = useLayout();
  const { app } = config;
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const getBaseRoute = (path: any) => {
    return path.split('/')[1];
  };

  const baseRoute = getBaseRoute(to);
  const isActive = pathname.includes(baseRoute);

  useEffect(() => {
    const previousPathnameKey = `previousPathname-${to}`;
    if (!localStorage.getItem(previousPathnameKey)) {
      localStorage.setItem(previousPathnameKey, pathname);
    }
  }, [to, pathname]);

  const HandleMenuClick = async () => {
    dispatch(setData(title));
  };

  return (
    <div className="menu-item">
      <Link
        className={clsx('menu-link without-sub', { active: isActive })}
        to={to}
        onClick={() => HandleMenuClick()}
      >
        {hasBullet && (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
          <span className="menu-icon">
            {' '}
            {getIcons(title)}
            {/* <KTIcon iconName={icon} className="fs-1" /> */}
          </span>
        )}
        {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
          <i className={clsx('bi fs-3', fontIcon)}></i>
        )}
        <span className="menu-title">{title}</span>
      </Link>
      {children}
    </div>
  );
};

function getIcons(iconTag: string): JSX.Element | null {
  if (iconTag === 'Clinician') {
    return <Provider />;
  } else if (iconTag === 'Assignments') {
    return <Assignment />;
  } else if (iconTag === 'Documents') {
    return <Documents />;
  } else if (iconTag === 'Manage Shifts') {
    return <Shiftapproval />;
  } else if (iconTag === 'Timesheet') {
    return <TimeSheet />;
  } else if (iconTag === 'Chat') {
    return <Chat />;
  } else if (iconTag === 'Clincian Leads') {
    return <Clincian />;
  } else if (iconTag === 'Facility') {
    return <Company />;
  } else if (iconTag === 'Admin') {
    return <Staff />;
  } else if (iconTag === 'Skill') {
    return <Skill />;
  } else if (iconTag === 'City') {
    return <City />;
  } else if (iconTag === 'Nursing Home') {
    return <CreateShift />;
  } else if (iconTag === 'Manage Shift Time') {
    return <ShiftCreation />;
  } else if (iconTag === 'Program') {
    return <Program />;
  }

  return null;
}

export default getIcons;

export { SidebarMenuItem };
