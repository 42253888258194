import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';

const ConfirmEventPopup = ({
  showPopup,
  handleClose,
  handleConfirmed,
  title,
  message,
  loading,
  rejectReasonRequired,
  placeHolder = undefined,
}: {
  showPopup: boolean;
  handleClose: () => void;
  handleConfirmed: (any: string) => void;
  handleRejected?: (any: string) => void;
  title: string;
  placeHolder?: string;
  message: string;
  loading: boolean;
  rejectReasonRequired?: string;
}) => {
  const [reason, setReason] = useState('');
  const [reasonError, setReasonError] = useState(false);

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <div className="modal-header" style={{ padding: '14px 24px' }}>
        <h2 style={{ marginBottom: '0px' }}>{title}</h2>
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: '#FE7253' }}
          >
            cancel
          </span>
        </div>
      </div>
      <div className="d-flex flex-column align-items-left">
        <div className="p-7">
          <h5>{message}</h5>

          {(rejectReasonRequired === 'Reject' ||
            rejectReasonRequired === 'Drop') && (
            <div className="mt-3">
              <label
                className="form-label fw-normal"
                style={{ fontSize: '16px' }}
              >
                Reason
                {''}{' '}
                <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                  *
                </span>
              </label>
              <textarea
                rows={4}
                cols={40}
                value={reason}
                className={`form-control form-control-solid mb-3 ${
                  reasonError ? 'is-invalid' : ''
                }`}
                style={{ border: '1px solid #919EAB' }}
                onChange={e => {
                  const reasonValue = e.target.value;
                  setReason(reasonValue);
                  setReasonError(!reasonValue.trim());
                }}
                placeholder={
                  placeHolder ? placeHolder : 'Enter reason for reject'
                }
              />
              {reasonError && (
                <div className="invalid-feedback">Please provide a reason.</div>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="w-100 d-flex align-items-center justify-content-end p-5">
        <Button
          disabled={loading}
          onClick={handleClose}
          size="lg"
          variant="secondary"
        >
          Cancel
        </Button>
        <div className="w-15px"></div>
        <Button
          disabled={loading}
          onClick={() => {
            if (
              rejectReasonRequired === 'Reject' ||
              rejectReasonRequired === 'Drop'
            ) {
              if (!reason.trim()) {
                setReasonError(true);
                return;
              }
              handleConfirmed(reason);
            } else {
              handleConfirmed('');
            }
          }}
          size="lg"
          variant="primary"
        >
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmEventPopup;
