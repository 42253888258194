import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import RestoreIcon from '@mui/icons-material/Restore';
import { format } from 'date-fns';
import 'moment-timezone';
import {
  Accordion,
  AccordionSummary,
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { charValidate } from '../../../../components/utility';
import {
  convertTimesToUTC,
  convertUTCTimesToLocalFuncForCheckInOut,
  formatCharges,
  formatPhoneNumber,
} from '../../../../helpers/commonFunctions';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import {
  AssignmentScheduleVerificationStatusEnum,
  AssignmentscheduleStatusEnum,
} from '../../../helpers/enum';
import {
  DropAssignment,
  getassignmentscheduleid,
  verifyassignmentschedule,
} from '../api';
import UpdateClinicianStatusToSuspend from '../Clinician/clinician-status/UpdateClinicianStatusToSuspend';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import CustomTooltip from '../utility/CustomTooltip';
import { ReactComponent as Visibility } from '../../../assets/svg/visibility.svg';
import './shift-style.css';
import { APP_URLs } from '../../../../constants/appURLs';

interface LocationState {
  assignmentdata: any;
}
const ShiftDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [showDropConfirmModal, setShowDropConfirmModal] = useState(false);
  const [showdropassignmentModal, setShowDropAssignmentModal] = useState(false);
  const [openDropAssignmentPopUp, setOpenDropAssignmentPopUp] = useState(false);
  const [showStatusModel, setshowStatusModel] = useState({
    id: '',
    status: '',
  });
  const [modifiedclockin, setModifiedClockIn] = useState('');
  const [modifiedclockout, setModifiedClockOut] = useState('');
  const [extrahours, setExtraHours] = useState(0);
  const [scheduleList, setScheduleList] = useState([]);

  const AssignmentData =
    (location.state as LocationState)?.assignmentdata || [];
  const {
    data: assignmentscheduledata,
    mutateAsync: getassignmentid,
    isLoading: isAssignmentDataLoading,
  } = useMutation('add-golf', getassignmentscheduleid);

  const {
    mutateAsync: assignmentschedule,
    isLoading: isAssignmentScheduleVerifyLoading,
  } = useMutation('updateProviderStatus', verifyassignmentschedule);

  const { mutateAsync: dropAssignmentAPI, isLoading: isAssignmentDropped } =
    useMutation('updateProviderStatus', DropAssignment);

  const handleStatus = (
    status: string,
    id: any,
    modifiedTime?: any,
    clockInTime?: any
  ) => {
    const scheduleTime: any = scheduleList?.filter((timeid: any) => timeid?.Id == id?.id) 
    if (status === 'Drop') {
      setShowDropConfirmModal(true);
    } else if (status === 'Approve') {
      if (
        modifiedTime ||
        clockInTime ||
        (scheduleTime[0]?.inputClockOut && scheduleTime[0]?.inputClockIn)
      ) 
      {
        setShowDropAssignmentModal(true);
      } 
      else {
        snackActions.error('Modified time is required.');
      }
    }
    else if(status === 'Reject'){
      setShowDropAssignmentModal(true)
    }
    setshowStatusModel({ status, id });
  };

  const handleTimeChange = (fieldName: any, value: string) => {
    const timeWithSeconds = `${value}:00`;
    if (fieldName === 'ModifiedClockIn') {
      setModifiedClockIn(timeWithSeconds);
    } else if (fieldName === 'ModifiedClockOut') {
      setModifiedClockOut(timeWithSeconds);
    }
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setShowDropAssignmentModal(false);
    setshowStatusModel({ id: '', status: '' });
  };

  const handleCloseApproveRejectPopUp = () => {
    setShowDropAssignmentModal(false);
    setshowStatusModel({ id: '', status: '' });
  };

  const handleCloseCancelShiftPopUp = () => {
    setShowDropConfirmModal(false);
    setshowStatusModel({ id: '', status: '' });
  };

  const updateAssignmentscheduleStatusAPI = async (
    status: any,
    id: any,
    reason: string
  ) => {
    let statusValue;
    if (status == 'pending') {
      statusValue = 1;
    } else if (status == 'Approve') {
      statusValue = 2;
    } else if (status == 'Reject') {
      statusValue = 3;
    }
    const scheduleTime: any = scheduleList?.filter((timeid: any) => timeid?.Id == id?.id)
    const currentDate = moment().format('YYYY-MM-DD');
    const modifiedTime = convertTimesToUTC(
      currentDate,
      scheduleTime[0]?.inputClockIn,
      scheduleTime[0]?.inputClockOut
    );
    let payload = {
      id: id.id,
      status: statusValue,
      modifyCheckIn:
        modifiedTime?.startTime !== 'Invalid date'
          ? modifiedTime?.startTime
          : '',
      modifyCheckOut:
        modifiedTime?.endTime !== 'Invalid date' ? modifiedTime?.endTime : '',
      extraHours: extrahours ?? 0,
      remarks: (statusValue = 3 ? reason : undefined),
    };

    let response = await assignmentschedule(payload);
    if (response?.IsSuccess) {
      snackActions.success(response?.Message);
      setScheduleList([])
      setShowDeleteConfirmModal(false);
      getassignmentid({ id: AssignmentData.Id });
      handleCloseApproveRejectPopUp();
    } else {
      snackActions.error(response?.message);
    }
  };

  const dropAssignment = async (status: any, id: any, reason: any) => {
    let statusValue;
    if (status == 'Drop') {
      statusValue = 4;
    }
    let payload = {
      id: id?.id,
      status: statusValue,
      reason: reason,
    };
    let response = await dropAssignmentAPI(payload);
    if (response?.IsSuccess) {
      snackActions.success(response?.Message);
      handleCloseCancelShiftPopUp();
      getassignmentid({ id: AssignmentData.Id });
    } else {
      snackActions.error(response?.message);
    }
  };

  useEffect(() => {
    if (AssignmentData) {
      getassignmentid({ id: AssignmentData.Id });
    }
  }, [AssignmentData]);

  const getStatusColor = (
    statusName: string
  ): { backgroundColor: string; statusTextColor: string } => {
    switch (statusName) {
      case 'Completed':
        return { backgroundColor: '#0d69434d', statusTextColor: '#0D6943' };
      case 'Approved':
        return { backgroundColor: '#0d69434d', statusTextColor: '#0D6943' };
      case 'Rejected':
        return { backgroundColor: '#e81c2b4d', statusTextColor: '#E81C2B' };
      case 'Pending':
        return { backgroundColor: '#F6851F29', statusTextColor: '#F6851F' };
      case 'Inprogress':
        return { backgroundColor: '#FFAB0029', statusTextColor: '#FFAB00' };
      case 'Accepted':
        return { backgroundColor: '#006dd64d', statusTextColor: '#006DD6' };
      case 'Dropped':
        return { backgroundColor: '#6633994d', statusTextColor: '#663399' };
      default:
        return { backgroundColor: '', statusTextColor: '' };
    }
  };
  const assignmentSchedulesData = assignmentscheduledata?.Result;
  const resultDatas = assignmentSchedulesData?.AssignmentSchedules[0];

  const handleBackClick = () => {
    navigate('/shifts');
  };

  const closeSuspendPopup = (isSubmit: boolean) => {
    setOpenDropAssignmentPopUp(false);
    setshowStatusModel({ id: '', status: '' });
    if (isSubmit) {
      getassignmentid({ id: AssignmentData.Id });
    }
  };

  const handleview = (value: any) => {
    navigate('/view-timeSheet', { state: { shiftdetailview: value } });
  };

  const handleDocumentOpen = (documentId: any) => {
    setLoading(true);
    setError(null);
    try {
      let documentUrl: string | undefined;
      const companyDocument = assignmentSchedulesData?.CompanyDocuments.find(
        (document: any) => document?.Id === documentId
      );

      if (companyDocument) {
        documentUrl = `${APP_URLs?.viewDocBaseURL}${
          companyDocument?.DocumentUrl?.startsWith('/') ? '' : '/'
        }${companyDocument?.DocumentUrl}`;
      } else {
        const assignmentDocument =
          assignmentSchedulesData?.AssignmentDocuments?.find(
            (document: any) => document?.Id === documentId
          );

        if (assignmentDocument) {
          documentUrl = `${APP_URLs?.viewDocBaseURL}${
            assignmentDocument?.DocumentUrl?.startsWith('/') ? '' : '/'
          }${assignmentDocument?.DocumentUrl}`;
        } else {
          throw new Error('Assignment document not found.');
        }
      }
      window.open(documentUrl, '_blank');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClinicianDocumentOpen = (documentId: any) => {
    setLoading(true);
    setError(null);
    try {
      let documentUrl: string | undefined;
      const companyDocument =
        assignmentSchedulesData?.AssignmentSchedules?.flatMap(
          (document: any) => document?.Documents
        );
      if (companyDocument) {
        const clinicianDocument = companyDocument?.find(
          (docId: any) => docId?.Id == documentId
        );
        documentUrl = `${APP_URLs?.viewDocBaseURL}${
          clinicianDocument?.DocumentUrl?.startsWith('/') ? '' : '/'
        }${clinicianDocument?.DocumentUrl}`;
      }
      window.open(documentUrl, '_blank');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleReset = (item: any) => {
    setScheduleList((prevAssignments: any) =>
      prevAssignments.map((assignment: any) =>
        assignment.Id === item?.Id ? { ...assignment, inputClockIn: "", inputClockOut: "" } : assignment
      )
    );
  }

  const handleClockIn = (id: any, date: any) => {
    setScheduleList((prevAssignments: any) =>
          prevAssignments.map((assignment: any) =>
            assignment.Id === id ? { ...assignment, inputClockIn: date } : assignment
          )
        );
      }

      const handleClockOut = (id: any, date: any) => {
        setScheduleList((prevAssignments: any) =>
              prevAssignments.map((assignment: any) =>
                assignment.Id === id ? { ...assignment, inputClockOut: date } : assignment
              )
            );
          }

  useEffect(() => {
    if(assignmentSchedulesData?.AssignmentSchedules){
      const formattedAssignments = assignmentSchedulesData?.AssignmentSchedules.map((assignment: any) => ({
        ...assignment,
        inputClockIn: null, 
        inputClockOut: null,
                }));
                setScheduleList(formattedAssignments)       
    }
  }, [assignmentSchedulesData?.AssignmentSchedules])

  return (
    <div>
      <div className="mb-5 backButton" onClick={handleBackClick}>
        <ArrowBackIcon />
      </div>

      <div className="position-relative">
        <div className="row">
          <div className="col-xl-4">
            <div className="shiftDetail-sticky customeboxShadow card p-4">
              <div className="d-flex justify-content-between">
                <h3>{assignmentSchedulesData?.AssignmentCode}</h3>
                <span className="fw-normal" style={{ fontSize: '18px' }}>
                  {assignmentSchedulesData?.CompanyName}
                </span>
              </div>
              {assignmentSchedulesData?.StatusName !== 'Cancelled' &&
                assignmentscheduledata && (
                  <div className="text-end my-4 pb-3 borderBottom">
                    <button
                      className="cancelAssignmentbtn"
                      onClick={() => {
                        setOpenDropAssignmentPopUp(true);
                      }}
                    >
                      Cancel Assignment
                    </button>
                  </div>
                )}
              {assignmentSchedulesData?.StatusName == 'Cancelled' &&
                assignmentscheduledata && (
                  <div className="my-4 pb-3 borderBottom">
                    <div className="row">
                      <div className="col-5">
                        <h5 className="shieftHeading">Status:</h5>
                      </div>
                      <div
                        className="col-7"
                        style={{ fontWeight: '400', color: '#212B36' }}
                      >
                        <p className="mb-2 fw-normal shieftCancled">
                          Cancelled
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-5">
                        <h5 className="shieftHeading">Reason:</h5>
                      </div>
                      <div className="col-7">
                        <p className="mb-2 shieftData">
                          {assignmentSchedulesData?.Remarks ?? '-'}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <div className="mt-2 mb-4 pb-3 borderBottom">
                <div className="row">
                  <div className="col-5">
                    <h5 className="shieftHeading">Patient Info:</h5>
                  </div>
                  <div className="col-7">
                    <p className="mb-2 shieftData">
                      {assignmentSchedulesData &&
                      assignmentSchedulesData?.PatientDetails
                        ? assignmentSchedulesData?.PatientDetails.FirstName +
                          ' ' +
                          assignmentSchedulesData?.PatientDetails.LastName
                        : 'Loading...'}
                    </p>
                    <p className="mb-2 shieftData">
                      {`+${formatPhoneNumber(
                        assignmentSchedulesData?.PatientDetails?.CountryCode +
                          assignmentSchedulesData?.PatientDetails?.ContactNumber
                      )}`}
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Secondary Info:</h5>
                  </div>
                  <div className="col-7 d-flex flex-column">
                    <p className="mb-2 shieftData">
                      {assignmentSchedulesData &&
                      assignmentSchedulesData?.PatientDetails
                        ? (assignmentSchedulesData?.PatientDetails
                            ?.ContacPersonFirstName
                            ? assignmentSchedulesData?.PatientDetails
                                ?.ContacPersonFirstName
                            : '-') +
                          ' ' +
                          assignmentSchedulesData?.PatientDetails
                            ?.ContacPersonLastName
                        : 'Loading...'}
                    </p>
                    <p className="mb-2 shieftData">
                      {assignmentSchedulesData?.PatientDetails
                        ?.ContacPersonContactNumber
                        ? `+${formatPhoneNumber(
                            assignmentscheduledata.Result.PatientDetails
                              .CountryCode +
                              assignmentscheduledata.Result.PatientDetails
                                .ContacPersonContactNumber
                          )}`
                        : ''}
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Scheduled Time:</h5>
                  </div>
                  <div className="col-7 d-flex">
                    <p className="mb-2 shieftData">
                      {`${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'checkIn'
                      )} - ${convertUTCTimesToLocalFuncForCheckInOut(
                        resultDatas?.StartTime,
                        resultDatas?.StartTime,
                        resultDatas?.EndTime,
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                        'clockOut'
                      )}`}
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading mb-3">Weekdays Rate:</h5>
                    <h5 className="shieftHeading">Weekend Rate:</h5>
                  </div>
                  <div className="col-7">
                    <p className="shieftData mb-2">
                      {formatCharges(assignmentSchedulesData?.RatePerHour) ??
                        '-'}
                    </p>
                    <p className="shieftData mb-2">
                      {formatCharges(assignmentSchedulesData?.WeekOffRate) ??
                        '-'}
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Location:</h5>
                  </div>
                  <div className="col-7 d-flex">
                    <p className="mb-2 shieftData">
                      {assignmentSchedulesData &&
                      assignmentSchedulesData?.AddressLine1
                        ? assignmentSchedulesData?.AddressLine1 +
                          ', ' +
                          assignmentSchedulesData?.CityName +
                          ', ' +
                          assignmentSchedulesData?.ZipCode +
                          ', ' +
                          assignmentSchedulesData?.StateName
                        : 'Loading...'}
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Instructions:</h5>
                  </div>
                  <div className="col-7 d-flex">
                    <p className="shieftData">
                      <CustomTooltip
                        title={
                          assignmentSchedulesData?.Instruction ||
                          'No instructions available'
                        }
                      >
                        <h1 className="heading">
                          {assignmentSchedulesData?.Instruction
                            ? charValidate(
                                assignmentSchedulesData?.Instruction,
                                100
                              )
                            : '-'}
                        </h1>
                      </CustomTooltip>
                    </p>
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Other Info:</h5>
                  </div>
                  <div className="col-7">
                    {assignmentSchedulesData?.PatientDetails
                      ?.PatientOtherInformations ? (
                      <ul className="shieftData">
                        {assignmentSchedulesData?.PatientDetails?.PatientOtherInformations?.map(
                          (otherinfo: any, index: any) => (
                            <li className="heading" key={index}>
                              {otherinfo?.OtherInformationDescription}
                            </li>
                          )
                        )}
                      </ul>
                    ) : (
                      <h5 className="shieftData">-</h5>
                    )}
                  </div>
                  <div className="col-5">
                    <h5 className="shieftHeading">Notes:</h5>
                  </div>
                  <div className="col-7 d-flex">
                    <p className="shieftData">
                      <CustomTooltip
                        title={
                          assignmentSchedulesData?.PatientDetails?.Note ||
                          'No notes available'
                        }
                      >
                        <h1 className="heading">
                          {assignmentSchedulesData?.PatientDetails?.Note
                            ? charValidate(
                                assignmentSchedulesData?.PatientDetails?.Note,
                                100
                              )
                            : '-'}
                        </h1>
                      </CustomTooltip>
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <h6 className="mb-3 fw-bolder">Total Shifts:</h6>
                  <h6 className="mb-3 fw-bolder">Opened</h6>
                  <h6 className="fw-bolder">Filled</h6>
                  <div>
                    <h6 className="mb-3">Accepted</h6>
                    <h6 className="mb-3">Completed</h6>
                    <h6 className="mb-3">Approved</h6>
                    <h6 className="mb-3">Inprogress</h6>
                    <h6 className="mb-3">Rejected</h6>
                    <h6 className="mb-3">Noshow</h6>
                  </div>
                  <h6 className="mb-3 fw-bolder">Cancelled</h6>
                </div>
                <div className="col-7">
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.TotalShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.OpenShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.FilledShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.AcceptedShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.CompletedShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.ApprovedShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.InprogressShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.RejectedShiftCount ?? '-'}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.NoShowShiftCount}
                  </h6>
                  <h6 className="mb-3 shieftData">
                    {assignmentSchedulesData?.CancelledShiftCount}
                  </h6>
                </div>
              </div>
              <div className="mt-2 pt-2">
                {(assignmentSchedulesData?.CompanyDocuments?.length > 0 ||
                  assignmentSchedulesData?.AssignmentDocuments?.length > 0) && (
                  <label className="form-label fw-bolder mb-3">
                    Documents:
                  </label>
                )}
                {assignmentSchedulesData?.CompanyDocuments.map(
                  (document: any, index: number) => (
                    <Card>
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-between px-4 py-2 documentCard"
                      >
                        <Typography variant="subtitle1">
                          {document.FileName}
                        </Typography>
                        <IconButton
                          edge="end"
                          onClick={() => handleDocumentOpen(document?.Id)}
                        >
                          <Visibility />
                        </IconButton>
                      </div>
                    </Card>
                  )
                )}

                {assignmentSchedulesData?.AssignmentDocuments?.map(
                  (document: any, index: number) => (
                    <Card>
                      <div
                        key={index}
                        className="d-flex align-items-center justify-content-between px-4 py-2 documentCard"
                      >
                        <Typography variant="subtitle1">
                          {document?.FileName}
                        </Typography>
                        <IconButton
                          edge="end"
                          onClick={() => handleDocumentOpen(document?.Id)}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    </Card>
                  )
                )}
              </div>
            </div>
          </div>

          {isAssignmentDataLoading ? (
            <div className="col-xl-8">
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <CircularProgress />
              </Box>
            </div>
          ) : (
            <div className="col-xl-8">
              <div>
                {scheduleList?.map(
                  (item: any, index: any) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          {convertUTCTimesToLocalFuncForCheckInOut(
                            item?.StartTime,
                            item?.StartTime,
                            item?.EndTime,
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                            'assignmentDate'
                          )}
                          <div className="d-flex align-items-center justify-content-center">
                            <label
                              className="shieftLable"
                              style={{
                                color: getStatusColor(
                                  item?.VerificationStatusId ===
                                    AssignmentScheduleVerificationStatusEnum.Approved ||
                                    item?.VerificationStatusId ===
                                      AssignmentScheduleVerificationStatusEnum.Rejected
                                    ? item?.VerificationStatusName
                                    : item.StatusName
                                ).statusTextColor,
                                backgroundColor: getStatusColor(
                                  item?.VerificationStatusId ===
                                    AssignmentScheduleVerificationStatusEnum.Approved ||
                                    item?.VerificationStatusId ===
                                      AssignmentScheduleVerificationStatusEnum.Rejected
                                    ? item?.VerificationStatusName
                                    : item.StatusName
                                ).backgroundColor,
                              }}
                            >
                              {/* {item.StatusName} */}
                              {item?.VerificationStatusId ===
                                AssignmentScheduleVerificationStatusEnum.Approved ||
                              item?.VerificationStatusId ===
                                AssignmentScheduleVerificationStatusEnum.Rejected
                                ? item?.VerificationStatusName
                                : item.StatusName}
                            </label>
                            {item.StatusId === 1 || item.StatusId === 2 ? (
                              <button
                                className="d-flex align-items-center justify-content-center cancleShieftBtn"
                                onClick={() => {
                                  handleStatus('Drop', { id: item?.Id });
                                }}
                              >
                                Cancel shift
                              </button>
                            ) : null}
                          </div>
                        </div>
                      </AccordionSummary>
                      {item?.StatusId === 4 || item?.StatusId === 1 ? (
                        <div>
                          <div className="text-center noData">
                            <p className="my-4">No data available.</p>
                          </div>
                        </div>
                      ) : (
                        <Card className="py-3 px-1 mb-4">
                          <Row className="p-3">
                            <Col lg={7} xs={12}>
                              <Row>
                                <Col xl={5} xs={6}>
                                  <div className="mb-3">
                                    <h5 className="shieftHeading mb-0">
                                      Clinician:
                                    </h5>
                                  </div>
                                  <div className="mb-3">
                                    <h5 className="shieftHeading mb-0">
                                      Scheduled Date:
                                    </h5>
                                  </div>
                                  {item?.VerificationStatusName &&
                                    item.Remarks != null && (
                                      <div className="mb-3">
                                        <h5 className="shieftHeading mb-0">
                                          Reject Reason:
                                        </h5>
                                      </div>
                                    )}
                                </Col>
                                <Col xl={5} xs={6}>
                                  <div className="mb-3">
                                    <label className="fw-normal shieftData">
                                      {item.ProviderName &&
                                      item.ProviderName !== ''
                                        ? item.ProviderName
                                        : '-'}
                                    </label>
                                  </div>
                                  <div className="mb-2">
                                    <label className="fw-normal shieftData">
                                      {convertUTCTimesToLocalFuncForCheckInOut(
                                        item?.StartTime,
                                        item?.StartTime,
                                        item?.EndTime,
                                        Intl.DateTimeFormat().resolvedOptions()
                                          .timeZone,
                                        'assignmentDate'
                                      )}
                                    </label>
                                  </div>
                                  {item?.VerificationStatusName &&
                                    item.Remarks != null && (
                                      <div className="mb-3">
                                        <label className="fw-normal shieftData">
                                          {item.Remarks ?? '-'}
                                        </label>
                                      </div>
                                    )}
                                  <div className="d-flex flex-column">
                                    <div>
                                      <label className="fw-normal shieftData">
                                        {item.OverTimeReason}
                                      </label>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              {/* {item?.VerificationStatusId != 3 && ( */}
                              {item?.StatusId ===
                                AssignmentscheduleStatusEnum?.Completed ||
                              item?.StatusId ===
                                AssignmentscheduleStatusEnum?.NoShow ? (
                                <Row className="mt-3 align-items-end">
                                  <Col xl={5} xs={6}>
                                    <h5 className="shieftHeading mb-3">
                                      Modified Clock-In:
                                    </h5>
                                    <input
                                      type="time"
                                      className="form-control form-control-solid mb-3"
                                      style={{
                                        border: '1px solid #919EAB',
                                        backgroundColor:
                                          item?.ModifyCheckIn ||
                                          item.VerificationStatusId ===
                                            AssignmentScheduleVerificationStatusEnum.Approved ||
                                          item.VerificationStatusId ===
                                            AssignmentScheduleVerificationStatusEnum.Rejected
                                            ? '#e0e0e0'
                                            : '',
                                      }}
                                      onChange={(e: any) =>
                                        handleClockIn(
                                          item?.Id,
                                          e.target.value
                                        )
                                      }
                                      disabled={
                                        item?.ModifyCheckIn ||
                                        item.VerificationStatusId ===
                                          AssignmentScheduleVerificationStatusEnum.Approved ||
                                        item.VerificationStatusId ===
                                          AssignmentScheduleVerificationStatusEnum.Rejected
                                      }
                                      value={
                                        !item?.ModifyCheckIn
                                          ? item?.inputClockIn
                                          : convertUTCTimesToLocalFuncForCheckInOut(
                                              item?.ModifyCheckIn,
                                              item?.ModifyCheckIn,
                                              item?.ModifyCheckOut,
                                              Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone,
                                              'checkIn'
                                            )
                                      }
                                    />
                                  </Col>
                                  <Col xl={5} xs={6}>
                                    <h5 className="shieftHeading mb-3">
                                      Modified Clock-Out:
                                    </h5>
                                    <input
                                      type="time"
                                      className="form-control form-control-solid mb-3"
                                      style={{
                                        border: '1px solid #919EAB',
                                        backgroundColor:
                                          item?.ModifyCheckOut ||
                                          item.VerificationStatusId ===
                                            AssignmentScheduleVerificationStatusEnum.Approved ||
                                          item.VerificationStatusId ===
                                            AssignmentScheduleVerificationStatusEnum.Rejected
                                            ? '#e0e0e0'
                                            : '',
                                      }}
                                      onChange={(e: any) =>
                                        handleClockOut(
                                          item?.Id,
                                          e.target.value
                                        )
                                      }
                                      disabled={
                                        item?.ModifyCheckOut ||
                                        item.VerificationStatusId ===
                                          AssignmentScheduleVerificationStatusEnum.Approved ||
                                        item.VerificationStatusId ===
                                          AssignmentScheduleVerificationStatusEnum.Rejected
                                      }
                                      value={
                                        !item?.ModifyCheckOut
                                          ? item?.inputClockOut
                                          : convertUTCTimesToLocalFuncForCheckInOut(
                                              item?.ModifyCheckIn,
                                              item?.ModifyCheckIn,
                                              item?.ModifyCheckOut,
                                              Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone,
                                              'clockOut'
                                            )
                                      }
                                    />
                                  </Col>
                                  {!item?.ModifyCheckIn &&
                                  !item?.modifiedclockout &&
                                  ![
                                    AssignmentScheduleVerificationStatusEnum.Rejected,
                                    AssignmentScheduleVerificationStatusEnum.Approved,
                                  ].includes(item.VerificationStatusId) ? (
                                    <Col xl={2} xs={6}>
                                      <CustomTooltip title={'Reset'}>
                                        <RestoreIcon
                                          style={{
                                            height: '30px',
                                            width: '30px',
                                            marginBottom: '3px',
                                          }}
                                          onClick={() => handleReset(item)}
                                        />
                                      </CustomTooltip>
                                    </Col>
                                  ) : null}
                                </Row>
                              ) : null}
                            </Col>
                            <Col lg={5} xs={12} className="shiftdetailStep">
                              <Row>
                                {!(
                                  item.StatusId ===
                                  AssignmentscheduleStatusEnum.Pending
                                ) &&
                                  item.StatusName !== 'Cancelled' && (
                                    <>
                                      <Col xs="7">
                                        <div className="mb-15">
                                          <div className="circle-box">
                                            <div
                                              className={
                                                item?.CheckIn
                                                  ? 'circle-bg'
                                                  : 'circle'
                                              }
                                            ></div>
                                            <div className="d-inline-block ms-7">
                                              <p className="d-inline-block mb-0">
                                                Clock-In
                                              </p>
                                              {item?.CheckIn ? (
                                                <label
                                                  style={{
                                                    color: 'rgb(255 255 255)',
                                                    fontSize: '12px',
                                                    display: 'block',
                                                    backgroundColor:
                                                      item?.CheckIn
                                                        ? '#0080009c'
                                                        : '#0080009c',
                                                    padding: '3px',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                  }}
                                                >
                                                  {convertUTCTimesToLocalFuncForCheckInOut(
                                                    item?.CheckIn,
                                                    item?.CheckIn,
                                                    item?.CheckOut,
                                                    Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone,
                                                    'checkIn'
                                                  )}
                                                </label>
                                              ) : null}
                                            </div>
                                          </div>

                                          <div className="circle-box">
                                            <div
                                              className={
                                                item?.CheckOut
                                                  ? 'circle-bg'
                                                  : 'circle'
                                              }
                                            ></div>
                                            <div className="d-inline-block ms-7">
                                              <p className="d-inline-block mb-0">
                                                Clock-Out
                                              </p>
                                              {item?.CheckOut ? (
                                                <label
                                                  style={{
                                                    color: 'rgb(255 255 255)',
                                                    fontSize: '12px',
                                                    display: 'block',
                                                    backgroundColor:
                                                      item?.CheckOut
                                                        ? '#0080009c'
                                                        : '',
                                                    padding: '2px 2px',
                                                    borderRadius: '5px',
                                                    textAlign: 'center',
                                                    width: '55px',
                                                  }}
                                                >
                                                  {convertUTCTimesToLocalFuncForCheckInOut(
                                                    item?.CheckIn,
                                                    item?.CheckIn,
                                                    item?.CheckOut,
                                                    Intl.DateTimeFormat().resolvedOptions()
                                                      .timeZone,
                                                    'clockOut'
                                                  )}
                                                </label>
                                              ) : null}
                                            </div>
                                          </div>
                                          <div className="circle-box">
                                            <div
                                              className={
                                                item.VerificationStatusId == 2
                                                  ? 'circle-bg'
                                                  : 'circle'
                                              }
                                            ></div>
                                            <div className="d-inline-block ms-7">
                                              <p className="d-inline-block mb-0">
                                                Shift Approved
                                              </p>
                                            </div>
                                          </div>
                                          <div className="circle-box">
                                            <div
                                              className={
                                                item.VerificationStatusId == 2
                                                  ? 'circle-bg'
                                                  : 'circle'
                                              }
                                            ></div>
                                            <div className="d-inline-block ms-7">
                                              <p className="d-inline-block mb-0">
                                                Timesheet Generated
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                      {item.StatusId ===
                                        AssignmentscheduleStatusEnum.Pending && (
                                        <Col xs="7">
                                          <div className="mb-15 shieftHeading">
                                            <div className="text-center notimeData">
                                              <p>No data available.</p>
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                    </>
                                  )}
                                <Col
                                  xs={
                                    !(
                                      item.StatusId ===
                                      AssignmentscheduleStatusEnum.Pending
                                    )
                                      ? '5'
                                      : '12'
                                  }
                                >
                                  <div className="d-flex align-items-end justify-content-end">
                                    {(item.StatusId ===
                                      AssignmentscheduleStatusEnum.NoShow ||
                                      item.StatusId ===
                                        AssignmentscheduleStatusEnum.Completed) &&
                                    item.VerificationStatusId !==
                                      AssignmentScheduleVerificationStatusEnum.Approved &&
                                    item.VerificationStatusId !==
                                      AssignmentScheduleVerificationStatusEnum.Rejected ? (
                                      <div>
                                        <button
                                          onClick={() =>
                                            handleStatus('Reject', {
                                              id: item?.Id,
                                            })
                                          }
                                          className="d-flex align-items-center justify-content-center shieftRejectButton"
                                        >
                                          Reject
                                        </button>
                                        <button
                                          onClick={() =>
                                            handleStatus(
                                              'Approve',
                                              { id: item?.Id },
                                              item?.ModifyCheckIn,
                                              item?.CheckIn
                                            )
                                          }
                                          className="d-flex align-items-center justify-content-center shieftApproveButton"
                                        >
                                          Approve
                                        </button>
                                      </div>
                                    ) : null}
                                    {item.StatusId ===
                                      AssignmentscheduleStatusEnum.Pending && (
                                      <button
                                        className="cancletimeShieft"
                                        onClick={() => {
                                          handleStatus('Drop', {
                                            id: item?.Id,
                                          });
                                        }}
                                      >
                                        Cancel shift
                                      </button>
                                    )}
                                  </div>
                                  {item.StatusId ===
                                    AssignmentscheduleStatusEnum.Completed &&
                                    item.VerificationStatusId ===
                                      AssignmentScheduleVerificationStatusEnum.Approved && (
                                      <div className="d-flex justify-content-end p-0 mt-3">
                                        <button
                                          onClick={() =>
                                            handleview({ id: item?.Id })
                                          }
                                          className="viewTimesheetBtn"
                                        >
                                          View Timesheet
                                        </button>
                                      </div>
                                    )}
                                </Col>
                                {item.StatusId ===
                                  AssignmentscheduleStatusEnum.Pending && (
                                  <Col xs="7">
                                    <div className="mb-15 shieftHeading">
                                      <div className="text-center notimeData">
                                        <p>No data available.</p>
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                          <Card className="mt-3 mb-3 px-3">
                            {item.Documents.length > 0 && (
                              <div>
                                <h4 className="fw-bolder mb-5 pb-5 uploadDocument">
                                  Upload Documents
                                </h4>
                              </div>
                            )}
                            {item.Documents.map((upload: any) => (
                              <div>
                                <Row>
                                  <Col xs={12} md={6} xl={4}>
                                    <div className="uploadfileList d-flex justify-content-between align-items-center">
                                      <label className="fw-normal uploadfileList-label">
                                        {upload.FileName}
                                      </label>
                                      <Visibility
                                        onClick={() =>
                                          handleClinicianDocumentOpen(
                                            upload?.Id
                                          )
                                        }
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            ))}
                          </Card>
                        </Card>
                      )}
                    </Accordion>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {showDeleteConfirmModal && (
        <ConfirmEventPopup
          showPopup={showDeleteConfirmModal}
          handleClose={handleCloseDelete}
          handleConfirmed={() =>
            updateAssignmentscheduleStatusAPI(
              showStatusModel.status,
              showStatusModel.id,
              ''
            )
          }
          loading={isAssignmentScheduleVerifyLoading}
          title={'Update Status'}
          message={`Are you sure, do you want to ${showStatusModel.status} this Shift?`}
        />
      )}

      {showdropassignmentModal && (
        <ConfirmEventPopup
          showPopup={showdropassignmentModal}
          handleClose={handleCloseApproveRejectPopUp}
          handleConfirmed={reason =>
            updateAssignmentscheduleStatusAPI(
              showStatusModel.status,
              showStatusModel.id,
              reason
            )
          }
          rejectReasonRequired={showStatusModel.status}
          loading={isAssignmentScheduleVerifyLoading}
          title={'Update Status'}
          message={`Are you sure, do you want to ${showStatusModel.status} this Shift?`}
        />
      )}

      {showDropConfirmModal && (
        <ConfirmEventPopup
          showPopup={showDropConfirmModal}
          handleClose={handleCloseCancelShiftPopUp}
          handleConfirmed={reason =>
            dropAssignment(showStatusModel.status, showStatusModel.id, reason)
          }
          loading={isAssignmentDropped}
          title={'Cancel Shift'}
          placeHolder={'Enter reason for cancel'}
          message={`Are you sure, do you want to cancel this Shift?`}
          rejectReasonRequired={showStatusModel.status}
        />
      )}

      {openDropAssignmentPopUp && (
        <UpdateClinicianStatusToSuspend
          showPopup={openDropAssignmentPopUp}
          editData={assignmentscheduledata}
          handleClose={closeSuspendPopup}
        />
      )}
    </div>
  );
};
export default ShiftDetail;
