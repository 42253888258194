import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import { setCompanyProviders } from '../../../../../store/slice/form/document_form';
import { getDocumentsDetails, getSkills, uploadPicture } from '../../api';

interface IProps {
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  type: any;
  show: boolean;
  getIdOnDocumentClick: any;
  companyProviderDataFromAPI: any;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditVitasDoc = ({
  show,
  handleClose,
  editData,
  type,
  companyProviderDataFromAPI,
  getIdOnDocumentClick,
}: IProps) => {
  const getVitasDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.companyProviders
  );

  const getProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const dispatch = useDispatch();

  let imgUrl: string = editData?.DocumentUrl
    ? editData?.DocumentUrl
      ? editData?.DocumentUrl
      : ''
    : '';

  const [documentFile, setDocumentFileURL] = useState('');
  const [documentFileName, setDocumentFileName] = useState('');
  const [FileName, setFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );

  const validationSchema = Yup.object().shape({
    documentURL: Yup.string().required('This field is required'),
  });

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { data: documentTypes, mutateAsync: getDocumentByParent } = useMutation(
    'documentsDetails',
    getDocumentsDetails
  );

  useEffect(() => {
    getDocumentByParent({
      skip: 0,
      take: 1000,
      hasParent: true,
      parentId: type?.Id,
    });
  }, []);

  const formik: any = useFormik({
    initialValues: {
      documentURL: editData ? editData?.documentURL : '',
      documentNameField: editData ? editData?.documentNameField : '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values: any, { setFieldError }) => {
      if (!documentFile) {
        setFieldError('image', 'Image is required');
        return;
      }
      let getSelectedDate = values?.expiryDate?.$d ?? undefined;

      const defaultDataMap: any = new Map(
        getProviderDocumentData.map((doc: any) => [doc.documentTypeId, doc])
      );

      const payloadType = [
        {
          id: companyProviderDataFromAPI?.length
            ? companyProviderDataFromAPI[0]?.Id
            : 0,
          parentId:
            (Array.isArray(getIdOnDocumentClick) &&
              (getIdOnDocumentClick as any)?.ProviderDocument
                ?.ParentDocumentTypeId) ||
            getIdOnDocumentClick?.DocumentType?.Id,
          document: {
            documentUrl:
              values.documentURL ||
              defaultDataMap.get(values.skillName)?.document?.documentUrl ||
              '',
            fileName:
              FileName ||
              defaultDataMap.get(values.skillName)?.document?.fileName ||
              '',
            expiryDate: '',
          },
        },
      ];
      dispatch(setCompanyProviders(payloadType));
      handleClose(true);
    },
  });

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response: any = await pictureUpload(formData);
      if (response?.IsSuccess) {
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
        setDocumentFileURL(response?.Result[0]?.FileUrl);
        setDocumentFileName(response?.Result[0]?.FileName);
        formik.setFieldValue('documentURL', response?.Result[0]?.FileUrl);
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  useEffect(() => {
    if (getVitasDocumentData) {
      formik.setFieldValue(
        'documentURL',
        getVitasDocumentData[0]?.document?.documentUrl
      );
      formik.setFieldValue(
        'documentURL',
        getVitasDocumentData[0]?.document?.documentUrl
      );
      setDocumentFileURL(getVitasDocumentData[0]?.document?.documentUrl);
      formik.setFieldValue(
        'documentNameField',
        getVitasDocumentData[0]?.document?.documentName
      );
      setDocumentFileName(getVitasDocumentData[0]?.document?.fileName);
    }
  }, [getVitasDocumentData]);

  const handleRemoveFile = () => {
    formik.setFieldValue('documentURL', '');
    setDocumentFileName('');
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        {isLoadingDoc && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '15px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>VITAS Document</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div
            className="p-6"
            style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
          >
            <Container fluid>
              <Typography
                variant="body1"
                sx={{
                  mb: 2,
                  color: '#103558',
                  fontSize: '13px',
                  fontWeight: 600,
                }}
              >
                VITAS Documents standardize healthcare records, ensuring
                accurate patient care, legal compliance, and efficient
                management in hospice settings.
              </Typography>
              <Row>
                <Col xs={12}>
                  <label className="form-label fw-normal">
                    Attach Document{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <div>
                    {documentFileName ? (
                      <div
                        className="d-flex align-items-center"
                        style={fileInputWithDataStyle}
                      >
                        <span>{documentFileName}</span>
                        <span
                          className="material-symbols-outlined"
                          style={{ color: '#FE7253', cursor: 'pointer' }}
                          onClick={handleRemoveFile}
                        >
                          cancel
                        </span>
                      </div>
                    ) : (
                      <>
                        <input
                          type="file"
                          accept=".pdf,.doc,.docx,image/*"
                          onChange={e => {
                            const selectedFile = e.currentTarget.files?.[0];
                            if (selectedFile) {
                              formik.setFieldValue('file', selectedFile);
                              handleChangeImageUpload(e);
                            }
                          }}
                          className="rounded form-control-file chooseFile"
                        />
                        {formik.touched.documentURL &&
                          formik.errors.documentURL && (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors.documentURL}
                              </div>
                            </div>
                          )}
                      </>
                    )}
                  </div>
                  {formik.touched.file && formik.errors.file && (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.file}</div>
                    </div>
                  )}
                </Col>
              </Row>
            </Container>
          </div>
          <div
            className="w-100 d-flex align-items-end justify-content-end px-8 py-4"
            style={{ borderTop: '1.5px solid #919EAB66' }}
          >
            <div>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{ padding: '7px 45px' }}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditVitasDoc;

const fileInputWithDataStyle = {
  backgroundColor: '#10355812',
  border: '1px solid #919eab',
  borderRadius: '5px',
  padding: '10px 15px',
  justifyContent: 'space-between',
};
