import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { KTIcon } from '../../../helpers';

type Skill = {
  id: any;
  skillName: string;
};

const ConfirmEditPopup = ({
  showPopup,
  handleCloseEdit,
  handleConfirmed,
  title,
  message,
  loading = false,
  initialValue,
}: {
  showPopup: boolean;
  handleCloseEdit: () => void;
  handleConfirmed: (any: any) => void;
  title: string;
  message: string;
  loading: boolean;
  initialValue: any;
}) => {
  const [inputValue, setInputValue] = useState<Skill | undefined>(initialValue);
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(prevState => ({
      ...prevState!,
      skillName: e.target.value,
    }));
  };

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-400px"
      show={showPopup}
      backdrop="static"
    >
      <div className="modal-header">
        <h2>{title}</h2>
        <div className="btn btn-sm btn-icon btn-active-color-primary">
          <KTIcon className="fs-1" iconName="cross" />
        </div>
      </div>

      <div className="d-flex flex-column align-items-center">
        <input
          type="text"
          className="form-control form-control-lg form-control-solid mw-20"
          value={inputValue?.skillName}
          onChange={handleChangeInput}
          style={{
            marginTop: '20px',
            width: '300px',
          }}
        />
      </div>

      <div className="w-100 d-flex align-items-center justify-content-end mt-4 p-5">
        <Button
          disabled={loading}
          onClick={handleCloseEdit}
          size="lg"
          variant="secondary"
        >
          Cancel
        </Button>
        <div className="w-15px"></div>
        <Button
          disabled={loading}
          onClick={() => handleConfirmed(inputValue)}
          size="lg"
          variant="primary"
        >
          {loading ? 'Loading...' : 'Confirm'}
        </Button>
      </div>
    </Modal>
  );
};
export default ConfirmEditPopup;
