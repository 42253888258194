import { IconButton } from '@mui/material';
import { Button, Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import InfoIcon from '@mui/icons-material/Info';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import { DocumentCategoryEnum } from '../../../../helpers/enum';
import { ReactComponent as Visibility } from '../../../../assets/svg/visibility.svg';
import { ReactComponent as Verify } from '../../../../assets/svg/verify.svg';
import { ReactComponent as Verified } from '../../../../assets/svg/verified.svg';
import { ReactComponent as Rejected } from '../../../../assets/svg/rejected.svg';
import { ReactComponent as Clinicianrejecte } from '../../../../assets/svg/clinician_reject.svg';
import { APP_URLs } from '../../../../../constants/appURLs';
import { charValidate } from '../../../../../components/utility';

const ViewOnBoardingDocs = ({
  editData,
  providerData,
  setShowUpdateSkillConfirmModal,
  setshowSkillModel,
  setDocumentType,
}: any) => {
  const onBoardingDocs = providerData?.Result?.Documents?.filter(
    (item: any) =>
      item?.DocumentType?.DocumentCategoryId ===
      DocumentCategoryEnum?.OnBoardDocument
  );

  const handleVisibilityIcon = (url: string) => {
    const baseURL = APP_URLs?.viewDocBaseURL;
    window.open(`${baseURL}/${url}`, '_blank');
  };

  const handleProviderSkillStatus = (status: any, id: any, type: any) => {
    if (editData?.ProfileStatusId === 3) {
      snackActions.error(
        'Your profile has been rejected. You are not authorized to verify or reject any documents.'
      );
    } else {
      setShowUpdateSkillConfirmModal(true);
      const skillValue = {
        id: id,
        status: status,
      };
      setshowSkillModel(skillValue);
      setDocumentType(type);
    }
  };

  return (
    <div>
      {/* {isLoadingDoc && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )} */}
      <Row className="gy-7">
        {onBoardingDocs?.map((item: any, _: any) => {
          return (
            <Col sm={6} xs={12} key={item?.Id}>
              <div className="documentBox">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="documentNametext">
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-top">
                          {item?.DocumentType?.DocumentType}
                        </Tooltip>
                      }
                    >
                      <span>
                        {charValidate(item?.DocumentType?.DocumentType, 25)}
                      </span>
                    </OverlayTrigger>
                    {!item?.ProviderDocuments?.[0]?.Document
                      ?.DocumentUrl ? null : (
                      <Visibility
                        className="ms-1"
                        onClick={() =>
                          handleVisibilityIcon(
                            item?.ProviderDocuments?.[0]?.Document?.DocumentUrl
                          )
                        }
                      />
                    )}
                    {item?.ProviderDocuments?.[0]?.StatusName === 'Rejected' ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={
                          <Tooltip id="tooltip-top">
                            {item?.DocumentType?.Id == 1
                              ? providerData?.Result?.ProviderSkill?.Remarks
                              : item?.ProviderDocuments[0]?.Remarks}
                          </Tooltip>
                        }
                      >
                        <IconButton className="ms-2">
                          <InfoIcon color="error" />
                        </IconButton>
                      </OverlayTrigger>
                    ) : null}
                  </p>
                  <div className="d-flex flex-row align-items-center mt-1">
                    {!(
                      item.ProviderDocuments[0]?.StatusName === 'Approved' ||
                      item.ProviderDocuments[0]?.StatusName === 'Rejected'
                    ) &&
                      item?.ProviderDocuments?.length > 0 && (
                        <>
                          <Button
                            id="documentVerifyBtn"
                            size="sm"
                            variant="secondary"
                            onClick={() =>
                              handleProviderSkillStatus(
                                'Verify',
                                item?.DocumentType?.Id === 1
                                  ? providerData?.Result?.ProviderSkill
                                  : item,
                                item?.DocumentType?.DocumentType
                              )
                            }
                          >
                            <Verify className="me-1" />
                            Verify
                          </Button>
                          <Button
                            id="documentRejectBtn"
                            size="sm"
                            variant="secondary"
                            onClick={() =>
                              handleProviderSkillStatus(
                                'Reject',
                                item?.DocumentType?.Id === 1
                                  ? providerData?.Result?.ProviderSkill
                                  : item,
                                item?.DocumentType?.DocumentType
                              )
                            }
                          >
                            <Clinicianrejecte className="me-1" />
                            Reject
                          </Button>
                        </>
                      )}
                    {item?.ProviderDocuments?.[0]?.StatusName ===
                      'Approved' && (
                      <div className="d-flex align-items-center">
                        <h5>
                          <Verified className="me-1" />
                          Verified
                        </h5>
                      </div>
                    )}
                    {item?.ProviderDocuments?.[0]?.StatusName ===
                      'Rejected' && (
                      <span>
                        <div className="d-flex">
                          <Rejected className="mt-1 me-1" />
                          <p className="documentReject">Rejected</p>
                        </div>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default ViewOnBoardingDocs;
