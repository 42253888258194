import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { convertDateToMMDDYYYY } from '../../../../../helpers/commonFunctions';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import {
  setClinicianDocuments,
  setProviderSkills,
} from '../../../../../store/slice/form/document_form';
import {
  getDocumentsDetails,
  getProviderDocumentById,
  getSkills,
  updateProviderProfile,
  uploadPicture,
} from '../../api';
import { getBodyText, getLabel } from '../../../../../helpers/getDocText';

interface IProps {
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  type: any;
  show: boolean;
  currentPageIsView: boolean;
  providerSkillFromAPI: any;
  getIdOnDocumentClick: any;
  companiesData?: any;
  selectedCompany?: any;
  selectedProgram?: any;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const AddEditClinicianDocs = ({
  show,
  handleClose,
  editData,
  type,
  providerSkillFromAPI,
  getIdOnDocumentClick,
  companiesData,
  selectedCompany,
  selectedProgram,
  currentPageIsView,
}: IProps) => {
  const getSkillDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerSkills
  );

  const getProviderDocumentData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerDocuments
  );

  const [documentUrl, setDocumentUrl] = useState('');
  const tomorrow = dayjs().add(1, 'day');

  const dispatch = useDispatch();

  let imgUrl: string = editData?.DocumentUrl
    ? editData?.DocumentUrl
      ? editData?.DocumentUrl
      : ''
    : '';

  const [documentFile, setDocumentFileURL] = useState('');
  const [selectedSkillId, setSelectedSkillId] = useState('');
  const [documentFileName, setDocumentFileName] = useState('');
  const [isShowHasExpiry, setIsShowHasExpiry] = useState(true);
  const [FileName, setFileName] = useState<string>(
    imgUrl ? imgUrl?.split('/img/')[1] : ''
  );

  const validationSchema = Yup.object().shape({
    skillName: Yup.string().required('This field is required'),
    documentURL: Yup.string().required('This field is required'),
    expiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
  });

  const validationSchemaForCovid = Yup.object().shape({
    documentURL: Yup.string().required('This field is required'),
    skillName: Yup.string().required('This field is required'),
  });

  const validationSchemaForResume = Yup.object().shape({
    documentURL: Yup.string().required('This field is required'),
  });

  const validationSchema1 = Yup.object().shape({
    documentNameField: Yup.string(),
    documentURL: Yup.string().required('This field is required'),
    expiryDate: Yup.date()
      .required('This field is required')
      .min(new Date(), 'Date cannot be in the past'),
  });

  const { data: skillData } = useQuery('skill-data', getSkills);

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { data: documentTypes, mutateAsync: getDocumentByParent } = useMutation(
    'documentsDetails',
    getDocumentsDetails
  );

  useEffect(() => {
    getDocumentByParent({
      skip: 0,
      take: 1000,
      hasParent: true,
      parentId: type?.Id,
    });
  }, []);

  const detailsTypeList = documentTypes?.Result;
  const isTypeLicense = type?.Id === 1;
  const isTypeCPR = type?.Id === 2;
  const isTypeTBTest = type?.Id === 3;
  const isTypePhysicalStatement = type?.Id === 4;
  const isTypeCovidVaccine = type?.Id === 5;
  const isTypeResume = type?.Id === 6;
  const isTypeStateVerification = type?.Id === 7;
  const isTypeACHA = type?.Id === 8;
  const isTypeOFAC = type?.Id === 9;
  const isTypeOIG = type?.Id === 10;
  const isTypeSexualDefender = type?.Id === 11;
  const isTypeSAM = type?.Id === 12;
  const isTypeDrug = type?.Id === 13;
  const isTypeReliasCEUs = type?.Id === 14;

  const typeDropdownData = isTypeLicense ? skillData?.Result : detailsTypeList;

  useEffect(() => {
    if (isTypeLicense && typeDropdownData && selectedSkillId) {
      const expiryStatus =
        typeDropdownData?.find((e: any) => e?.Id == selectedSkillId)
          ?.HasExpiry ?? false;
      setIsShowHasExpiry(expiryStatus);
    }
  }, [selectedSkillId, isTypeLicense, typeDropdownData]);

  let checkValidationSchema;
  if (isTypeLicense || isTypeCPR || isTypeTBTest || isTypeReliasCEUs) {
    if (!isShowHasExpiry) {
      checkValidationSchema = validationSchemaForCovid;
    } else {
      checkValidationSchema = validationSchema;
    }
  } else if (isTypeCovidVaccine) {
    checkValidationSchema = validationSchemaForCovid;
  } else if (isTypeResume) {
    checkValidationSchema = validationSchemaForResume;
  } else {
    checkValidationSchema = validationSchema1;
  }

  const { mutateAsync: updateProviderProfileAPI } = useMutation(
    'clinician-profile',
    updateProviderProfile
  );

  const { data: providerDocumentData, mutateAsync: getDocumentById } =
    useMutation('program-document-list', getProviderDocumentById);

  const getDocumentDataFromSelector: any = useSelector(
    (state: any) => state?.clinicianDoc?.documentFormData?.providerDocuments
  );

  const getCompanyDocumentDataFromSelector: any = useSelector(
    (state: any) => state?.clinicianDoc?.documentFormData?.companyProviders
  );

  const uploadBackgroundCheckDocuments = async (editPayloadType: any) => {
    let dataToUse =
      editPayloadType?.providerDocuments &&
      editPayloadType?.providerDocuments?.length > 0
        ? editPayloadType?.providerDocuments
        : getDocumentDataFromSelector;

    if (dataToUse?.length > 0) {
      let updatedProviderDocuments = dataToUse?.filter(
        (e: any) => e?.parentId !== 14
      );

      let otherDocs = dataToUse?.find((e: any) => e?.parentId === 14);

      const updatedDocument = otherDocs?.providerDocuments?.map((item: any) => {
        return {
          ...item,
          id: item?.id,
        };
      });
      updatedProviderDocuments = [
        ...updatedProviderDocuments,
        ...updatedDocument,
      ];

      const vitasCompanyData = companiesData?.Result?.find(
        (item: any) => item?.IsRequiredNdadocument
      );

      const { documentUrl, fileName } =
        getCompanyDocumentDataFromSelector[0]?.document;
      const selectedCompanyProviders = selectedCompany?.map((e: any) => {
        const isVitas = vitasCompanyData?.Id == e.value;
        return {
          companyId: e?.value,
          document: {
            documentUrl: isVitas ? documentUrl : '',
            fileName: isVitas ? fileName : '',
            expiryDate: '',
          },
        };
      });
      const getValueOfSelectedProgram = selectedProgram?.map(
        (item: any) => item?.value
      );

      let response = await updateProviderProfileAPI({
        id: editData?.Id,
        providerDocuments: updatedProviderDocuments,
        companyProviders: selectedCompanyProviders,
        programIds: getValueOfSelectedProgram,
      });
      if (response?.IsSuccess) {
        handleClose(true);
        getDocumentById({ id: editData.Id });
      }
    }
  };

  const formik: any = useFormik({
    initialValues: {
      skillName: editData?.skillName,
      documentURL: editData ? editData?.documentURL : '',
      expiryDate: editData ? editData?.expiryDate : '',
      fileName: editData ? editData?.fileName : '',
      documentNameField: editData ? editData?.documentNameField : '',
    },
    validationSchema: checkValidationSchema,
    onSubmit: async (values: any, { setFieldError }) => {
      if (!documentFile) {
        setFieldError('image', 'Image is required');
        return;
      }

      let getSelectedDate = values?.expiryDate?.$d ?? undefined;
      let formattedExpiryDate = convertDateToMMDDYYYY(getSelectedDate);

      const defaultDataMap: any = new Map(
        getProviderDocumentData.map((doc: any) => [doc.documentTypeId, doc])
      );

      const filterDocuments = getProviderDocumentData?.filter((e: any) => {
        const id = editData?.Id ? e?.parentId : e?.id;
        return id !== type?.Id;
      });

      let updatedDocuments = [
        {
          id: editData?.Id
            ? getIdOnDocumentClick?.ProviderDocuments?.[0]?.Id
            : type?.Id,
          parentId:
            (Array.isArray(getIdOnDocumentClick) &&
              (getIdOnDocumentClick as any)?.ProviderDocument
                ?.ParentDocumentTypeId) ||
            getIdOnDocumentClick?.DocumentType?.Id,
          documentTypeId: values.skillName
            ? values.skillName
            : type?.Id || defaultDataMap.get(values.skillName)?.documentTypeId,
          document: {
            documentUrl:
              values.documentURL ||
              defaultDataMap.get(values.skillName)?.document?.documentUrl ||
              '',
            fileName: FileName ?? values.fileName,
            expiryDate: formattedExpiryDate ?? null,
            providerDocument: getIdOnDocumentClick?.ProviderDocument,
          },
        },
      ];

      const editPayloadType = {
        providerDocuments: [...filterDocuments, ...updatedDocuments],
      };

      const getDefaultDocumentData = (skillId: string) => {
        return (
          getSkillDocumentData.find((doc: any) => doc.skillId === skillId)
            ?.document || {}
        );
      };

      const defaultDocumentDataForLicence = getDefaultDocumentData(
        values?.skillName || ''
      );

      const payloadTypeForLicense = {
        providerSkills: [
          {
            id: editData?.Id
              ? providerSkillFromAPI?.ProviderSkill?.Id
              : type?.Id,
            parentId:
              (Array.isArray(getIdOnDocumentClick) &&
                (getIdOnDocumentClick as any)?.ProviderDocument
                  ?.ParentDocumentTypeId) ||
              getIdOnDocumentClick?.DocumentType?.Id,
            skillId: values?.skillName,
            document: {
              documentUrl:
                values?.documentURL ??
                (editData?.Id
                  ? providerSkillFromAPI?.ProviderSkill?.DocumentUrl
                  : defaultDocumentDataForLicence.documentUrl),

              fileName: FileName ?? values.fileName,
              expiryDate: !isShowHasExpiry
                ? ''
                : formattedExpiryDate && formattedExpiryDate != 'Invalid date'
                  ? formattedExpiryDate
                  : defaultDocumentDataForLicence.expiryDate,
              providerDocument: getIdOnDocumentClick?.ProviderDocument,
            },
          },
        ],
      };

      if (isTypeLicense) {
        dispatch(setProviderSkills(payloadTypeForLicense));
      } else {
        dispatch(setClinicianDocuments(editPayloadType));
        if (currentPageIsView) {
          uploadBackgroundCheckDocuments(editPayloadType);
        }
      }
      handleClose(true);
    },
  });

  useEffect(() => {
    if (editData) {
      setDocumentUrl(editData.DocumentUrl);
      formik.setFieldValue('companyName', editData.CompanyId);
    }
  }, [editData]);

  const handleChangeImageUpload = async (e: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setFileName(file?.name ?? '');
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response: any = await pictureUpload(formData);
      if (response?.IsSuccess) {
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
        setDocumentFileURL(response?.Result[0]?.FileUrl);
        setDocumentFileName(response?.Result[0]?.FileName);
        formik.setFieldValue('documentURL', response?.Result[0]?.FileUrl);
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  useEffect(() => {
    if (type?.Id || editData?.Id) {
      const skillData = getSkillDocumentData?.find((item: any) => {
        return 1 === type?.Id;
      });
      const providerData = getProviderDocumentData?.find((item: any) => {
        const id = editData?.Id ? item?.parentId : item?.id;
        return id === type?.Id;
      });
      const dataToUse = skillData ?? providerData;
      if (dataToUse) {
        formik.setFieldValue(
          'skillName',
          dataToUse?.skillId ?? dataToUse?.documentTypeId
        );
        setSelectedSkillId(dataToUse?.skillId ?? dataToUse?.documentTypeId);
        // if (isTypeLicense && typeDropdownData) {
        //   const isExpiryStatus =
        //     typeDropdownData?.find(
        //       (e: any) =>
        //         e?.Id == dataToUse?.skillId ?? dataToUse?.documentTypeId
        //     )?.HasExpiry ?? false;
        //   setIsShowHasExpiry(isExpiryStatus);
        // }
        formik.setFieldValue('documentURL', dataToUse?.document?.documentUrl);
        formik.setFieldValue('fileName', dataToUse?.document?.fileName);
        setFileName(dataToUse?.document?.fileName);
        setDocumentFileURL(dataToUse?.document?.documentUrl);
        if (dataToUse?.document || dataToUse?.document?.expiryDate) {
          formik.setFieldValue(
            'expiryDate',
            dayjs(dataToUse?.document?.expiryDate)
          );
          formik.setFieldValue(
            'documentNameField',
            dataToUse?.document?.documentName
          );
          setDocumentFileName(dataToUse?.document?.fileName);
        }
      }
    }
  }, []);
  // }, [type, getSkillDocumentData, getProviderDocumentData, typeDropdownData]);

  const handleRemoveFile = () => {
    formik.setFieldValue('documentURL', '');
    setDocumentFileName('');
  };

  const handleSkillChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = event.target;
    formik.setFieldValue('skillName', value);
    setSelectedSkillId(value);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        {isLoadingDoc && (
          <Box className="spinner-loader">
            <CircularProgress />
          </Box>
        )}
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '15px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>{getLabel(type?.Id)}</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div
            className="p-6"
            style={{ backgroundColor: '#f9f9f9', borderRadius: '5px' }}
          >
            <Container fluid>
              <Typography
                variant="body1"
                sx={{
                  color: '#103558',
                  fontSize: '14px',
                  fontWeight: 500,
                  marginBottom: '20px',
                }}
              >
                {getBodyText(type?.Id)}
              </Typography>
              <Row>
                {(isTypeLicense ||
                  isTypeCPR ||
                  isTypeTBTest ||
                  isTypeReliasCEUs) && (
                  <Col sm={isShowHasExpiry ? 12 : 6} xs={12}>
                    <label className="form-label fw-bold">
                      Type <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <div className="mb-3">
                      <select
                        style={{ border: '1px solid #919EAB' }}
                        className="form-select form-select-solid form-select-lg"
                        value={formik.values.skillName}
                        onChange={handleSkillChange}
                      >
                        <option value="">Select Type</option>
                        {typeDropdownData?.map((skill: any) => (
                          <option value={skill?.Id} key={skill?.Id}>
                            {isTypeLicense
                              ? skill?.SkillName
                              : skill?.DocumentType}
                          </option>
                        ))}
                      </select>
                      {formik.touched.skillName && formik.errors.skillName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.skillName}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                )}
                {isTypeCovidVaccine ? null : (
                  <>
                    {!isTypeResume && isShowHasExpiry && (
                      <Col
                        sm={
                          !(
                            isTypeLicense ||
                            isTypeCPR ||
                            isTypeTBTest ||
                            isTypeReliasCEUs
                          ) || isShowHasExpiry
                            ? 12
                            : 6
                        }
                      >
                        <label className="form-label fw-bold">
                          Expiry Date{' '}
                          <span className="fw-bolder mt-5 text-danger">*</span>
                        </label>
                        <div className="mb-3">
                          <div className="input-group providerSkill">
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                name="expiryDate"
                                value={formik?.values?.expiryDate}
                                onChange={date =>
                                  formik.setFieldValue('expiryDate', date)
                                }
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    error: false,
                                  },
                                }}
                                views={['year', 'month', 'day']}
                                minDate={tomorrow}
                                sx={{
                                  '&.MuiFormControl-root': {
                                    width: '100%',
                                  },
                                  border: '1px solid #919EAB',
                                  borderRadius: '0.475rem !important',
                                  height: '3.4rem !important',
                                  padding: '2px 2px !important',
                                }}
                              />
                            </LocalizationProvider>
                            {formik.touched.expiryDate &&
                              formik.errors.expiryDate && (
                                <div className="fv-plugins-message-container">
                                  <div className="fv-help-block">
                                    {formik.errors.expiryDate}
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </Col>
                    )}
                  </>
                )}
              </Row>

              {isTypeCovidVaccine && (
                <Row>
                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      Type <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <div className="mb-3">
                      <select
                        style={{ border: '1px solid #919EAB' }}
                        className="form-select form-select-solid form-select-lg"
                        {...formik.getFieldProps('skillName')}
                      >
                        <option value="">Select Type</option>
                        {typeDropdownData
                          ? typeDropdownData?.map((skill: any) => (
                              <option value={skill?.Id} key={skill?.Id}>
                                {isTypeLicense
                                  ? skill?.SkillName
                                  : skill?.DocumentType}
                              </option>
                            ))
                          : null}
                      </select>
                      {formik.touched.skillName && formik.errors.skillName && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.skillName}
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      Attach Document{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <div>
                      {documentFileName ? (
                        <div
                          className="d-flex align-items-center"
                          style={fileInputWithDataStyle}
                        >
                          <span>{documentFileName}</span>
                          <span
                            className="material-symbols-outlined"
                            style={{ color: '#FE7253', cursor: 'pointer' }}
                            onClick={handleRemoveFile}
                          >
                            cancel
                          </span>
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx,image/*"
                            onChange={e => {
                              const selectedFile = e.currentTarget.files?.[0];
                              if (selectedFile) {
                                formik.setFieldValue('file', selectedFile);
                                handleChangeImageUpload(e);
                              }
                            }}
                            className="rounded form-control-file chooseFile"
                          />
                          {formik.touched.documentURL &&
                            formik.errors.documentURL && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.documentURL}
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {formik.touched.file && formik.errors.file && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.file}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}
              {isTypeResume && (
                <Row>
                  <Col xs={12}>
                    <label className="form-label fw-bold">
                      Attach Document{' '}
                      <span className="fw-bolder mt-5 text-danger">*</span>
                    </label>
                    <div>
                      {documentFileName ? (
                        <div
                          className="d-flex align-items-center"
                          style={fileInputWithDataStyle}
                        >
                          <span>{documentFileName}</span>
                          <span
                            className="material-symbols-outlined"
                            style={{ color: '#FE7253', cursor: 'pointer' }}
                            onClick={handleRemoveFile}
                          >
                            cancel
                          </span>
                        </div>
                      ) : (
                        <>
                          <input
                            type="file"
                            accept=".pdf,.doc,.docx,image/*"
                            onChange={e => {
                              const selectedFile = e.currentTarget.files?.[0];
                              if (selectedFile) {
                                formik.setFieldValue('file', selectedFile);
                                handleChangeImageUpload(e);
                              }
                            }}
                            className="rounded form-control-file chooseFile"
                          />
                          {formik.touched.documentURL &&
                            formik.errors.documentURL && (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors.documentURL}
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </div>
                    {formik.touched.file && formik.errors.file && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.file}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              )}

              <Row>
                {isTypeCovidVaccine ? null : (
                  <>
                    {!isTypeResume && (
                      <Col xs={12}>
                        <label className="form-label fw-bold">
                          Attach Document{' '}
                          <span className="fw-bolder mt-5 text-danger">*</span>
                        </label>
                        <div>
                          {documentFileName ? (
                            <div
                              className="d-flex align-items-center"
                              style={fileInputWithDataStyle}
                            >
                              <span
                                style={{
                                  width: '90%',
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {documentFileName}
                              </span>
                              <span
                                className="material-symbols-outlined"
                                style={{ color: '#FE7253', cursor: 'pointer' }}
                                onClick={handleRemoveFile}
                              >
                                cancel
                              </span>
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                accept=".pdf,.doc,.docx,image/*"
                                onChange={e => {
                                  const selectedFile =
                                    e.currentTarget.files?.[0];
                                  if (selectedFile) {
                                    formik.setFieldValue('file', selectedFile);
                                    handleChangeImageUpload(e);
                                  }
                                }}
                                className="rounded form-control-file chooseFile"
                              />
                              {formik.touched.documentURL &&
                                formik.errors.documentURL && (
                                  <div className="fv-plugins-message-container">
                                    <div className="fv-help-block">
                                      {formik.errors.documentURL}
                                    </div>
                                  </div>
                                )}
                            </>
                          )}
                        </div>
                        {formik.touched.file && formik.errors.file && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.file}
                            </div>
                          </div>
                        )}
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Container>
          </div>
          <div className="w-100 d-flex align-items-end justify-content-end px-6 pb-4">
            <div>
              <Button
                type="submit"
                size="lg"
                variant="primary"
                style={{ padding: '7px 45px' }}
              >
                Save
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditClinicianDocs;

const fileInputWithDataStyle = {
  backgroundColor: '#10355812',
  border: '1px solid #919eab',
  borderRadius: '5px',
  padding: '10px 15px',
  justifyContent: 'space-between',
};
