import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import { KTIcon } from '../../../helpers';
import { ChangePassword } from '../api';

const Changepassword = ({
  showPopup,
  handleClose,
  loading = false,
}: {
  showPopup: boolean;
  handleClose: () => void;
  handleConfirmed: (any: string) => void;
  loading: boolean;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [newpassword, setNewPassword] = useState('');
  const [error, setError] = useState('');
  const [loadingapi, setLoadingapi] = useState(false);

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };
  const navigate = useNavigate();

  const { mutateAsync: changepassword, isLoading: chnagingpassword } =
    useMutation('Change-password', ChangePassword);

  const logout = () => {
    localStorage.clear();
    window.location.reload();
  };

  const ChangePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string()
      .required('Currrent password is required')
      .min(6, 'Password must be at least 6 characters long'),
    newPassword: Yup.string().required('New password is required'),
    confirmNewPassword: Yup.string()
      .required('Confirm new password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: ChangePasswordSchema,
    onSubmit: async values => {
      let bodyParams = {
        newPassword: values.newPassword,
        oldPassword: values.currentPassword,
      };

      try {
        setLoadingapi(true);
        let response = await changepassword(bodyParams);

        if (response?.IsSuccess) {
          snackActions.success(response?.Message);
          setLoadingapi(false);
          setTimeout(() => {
            logout();
          }, 1000);
        } else {
          console.error('API Error:', response?.Message);
          snackActions.error(response?.Message);
          setLoadingapi(false);
        }
      } catch (error) {
        console.error('Error calling API:', error);
        setLoadingapi(false);
      }
    },
  });

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-500px"
      show={showPopup}
      onHide={handleClose}
      backdrop="static"
    >
      <div className="modal-header d-flex align-items-center justify-content-between py-3">
        <h2 style={{ marginTop: '8px' }}>Change Password</h2>
        {/* begin::Close */}
        <div
          className="btn btn-sm btn-icon btn-active-color-primary"
          onClick={handleClose}
        >
          <span
            className="material-symbols-outlined"
            style={{ color: '#FE7253' }}
          >
            cancel
          </span>
          {/* <KTIcon className="fs-1" iconName="cross" /> */}
        </div>
        {/* end::Close */}
      </div>
      <div className="d-flex flex-column align-items-left">
        <form onSubmit={formik.handleSubmit} style={{ padding: '20px' }}>
          <div className="form-group">
            <TextField
              type={showCurrentPassword ? 'text' : 'password'}
              id="currentPassword"
              name="currentPassword"
              className="form-control passwordChange"
              value={formik.values.currentPassword}
              onChange={e =>
                formik.setFieldValue('currentPassword', e.target.value)
              }
              onBlur={formik.handleBlur}
              placeholder="Current Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                      edge="end"
                    >
                      {showCurrentPassword ? (
                        <VisibilityIcon style={{ fill: '#103558' }} />
                      ) : (
                        <VisibilityOffIcon style={{ fill: '#103558' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.currentPassword &&
              formik.errors.currentPassword && (
                <div className="text-danger mt-1 ms-1">
                  {formik.errors.currentPassword}
                </div>
              )}
          </div>
          <div className="form-group mt-5">
            {/* <label htmlFor="newPassword">New Password:</label> */}
            <TextField
              type={showPassword ? 'text' : 'password'}
              id="newPassword"
              name="newPassword"
              className="form-control passwordChange"
              value={formik.values.newPassword}
              onChange={e =>
                formik.setFieldValue('newPassword', e.target.value)
              }
              onBlur={formik.handleBlur}
              placeholder="New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityIcon style={{ fill: '#103558' }} />
                      ) : (
                        <VisibilityOffIcon style={{ fill: '#103558' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.newPassword && formik.errors.newPassword && (
              <div className="text-danger mt-1 ms-1">
                {formik.errors.newPassword}
              </div>
            )}
          </div>
          <div className="form-group mt-5">
            {/* <label htmlFor="confirmNewPassword">Confirm New Password:</label> */}
            <TextField
              type={showconfirmPassword ? 'text' : 'password'}
              id="confirmNewPassword"
              name="confirmNewPassword"
              className="form-control passwordChange"
              value={formik.values.confirmNewPassword}
              onChange={e =>
                formik.setFieldValue('confirmNewPassword', e.target.value)
              }
              onBlur={formik.handleBlur}
              placeholder="Confirm New Password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showconfirmPassword)
                      }
                      edge="end"
                    >
                      {showconfirmPassword ? (
                        <VisibilityIcon style={{ fill: '#103558' }} />
                      ) : (
                        <VisibilityOffIcon style={{ fill: '#103558' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formik.touched.confirmNewPassword &&
              formik.errors.confirmNewPassword && (
                <div className="text-danger mt-1 ms-1">
                  {formik.errors.confirmNewPassword}
                </div>
              )}
          </div>
          {error && <div className="text-danger">{error}</div>}
          <div className="d-flex justify-content-center mt-7">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={loadingapi}
              style={{
                marginBottom: '20px',
                padding: '10px 20px',
                width: '100%',
              }}
            >
              {loadingapi ? (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">Change Password</span>
              )}
            </button>
          </div>
        </form>
        {/* <div className="w-100 d-flex align-items-center justify-content-end  p-5">
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                        size="lg"
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                    <div className="w-15px"></div>
                    <Button
                        disabled={loading}
                        onClick={handleConfirmed.bind(null, "")}
                        size="lg"
                        variant="primary"
                    >
                        {loading ? "Loading..." : "Confirm"}
                    </Button>
                </div> */}
      </div>
    </Modal>
  );
};

export default Changepassword;
