import { Box, IconButton, Tooltip } from '@mui/material';
import Popover from '@mui/material/Popover';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Card, Col } from 'react-bootstrap';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/table/MuiTable';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../../../hooks/useDebounce';
import { ReactComponent as View } from '../../../assets/svg/view.svg';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { KTCard } from '../../../helpers';
import {
  getAssignmentSchedulesbyassignment,
  updateProviderStatus,
} from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import { charValidate } from '../utility';
import './shift-style.css';

const ShiftApproval: React.FC = () => {
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);

  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  let defaultQuery = {
    offset: 0,
    limit: 100,
    search: '',
  };
  const tableRef = useRef();
  const navigate = useNavigate();

  const {
    mutateAsync: getAssignmentschedule,
    data: assignmentscheduledata,
    isLoading: isGettingassignmentscheduleData,
  } = useMutation('getassignmentschedule', getAssignmentSchedulesbyassignment);

  const { mutateAsync: updateProviderData, isLoading: isUpdateProfile } =
    useMutation('updateProviderStatus', updateProviderStatus);

  const [showStatusModel, setshowStatusModel] = useState('');
  const [showUserId, setShowUserId] = useState('');

  useEffect(() => {
    getCustomer({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [debounceSearchValue]);

  const getCustomer = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getAssignmentschedule(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };
  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setshowStatusModel('');
  };

  const updateProviderStatusAPI = async (status: any, userId: any) => {
    let statusValue;
    if (status == 'Approve') {
      statusValue = 2;
    } else if (status == 'Block') {
      statusValue = 4;
    }

    let response = await updateProviderData({ id: userId, status: status });
    if (response?.IsSuccess) {
      setShowDeleteConfirmModal(false);
      snackActions.success(response?.Message);
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        search: debounceSearchValue ?? '',
      };
      handleCloseDelete();
      getCustomer(query);
    } else {
      snackActions.error(response?.message);
    }
  };

  const createshift = (value: any, e: any) => {
    setSelectedRow(value);
    setAnchorEl(e.currentTarget);
    navigate('/shifts-detail', { state: { assignmentdata: selectedRow } });
    localStorage.setItem('previousPathnameKey', window.location.pathname);
  };

  const columns: GridColDef[] = [
    {
      field: 'assignmentcode',
      headerName: 'Id',
      flex: 1.5,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.AssignmentCode ?? '-',
    },
    {
      field: 'name',
      headerName: 'Patient Name',
      flex: 1.5,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: data => (
        <Tooltip
          title={
            data?.row?.PatientDetails?.FirstName ? (
              <h6 className="text-white">{`${data?.row?.PatientDetails?.FirstName} ${data?.row?.PatientDetails?.LastName}`}</h6>
            ) : (
              '-'
            )
          }
        >
          <span>
            {data?.row?.PatientDetails?.FirstName
              ? `${charValidate(
                  data?.row?.PatientDetails?.FirstName,
                  30
                )} ${charValidate(data?.row?.PatientDetails?.LastName, 30)}`
              : '-'}
          </span>
        </Tooltip>
      ),
    },
    {
      field: 'contact',
      headerName: 'Contact',
      flex: 1.3,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) =>
        data?.row?.PatientDetails?.ContactNumber ?? '-',
    },
    {
      field: 'shifts',
      headerName: 'Shifts',
      flex: 1,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.TotalShiftCount ?? '-',
    },
    {
      field: 'completed',
      headerName: 'Completed',
      flex: 1.3,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.CompletedShiftCount ?? '-',
    },
    {
      field: 'approved',
      headerName: 'Approved',
      flex: 1.2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.ApprovedShiftCount ?? '-',
    },
    {
      field: 'pending',
      headerName: 'Open',
      flex: 1.3,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => data?.row?.OpenShiftCount ?? '-',
    },

    {
      field: 'assignment status',
      headerName: 'Assignment Status',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        const assignmentStatus = data?.row?.AssignmentStatus ?? '-';
        let backgroundColor = '';
        let statusTextColor = '';

        switch (assignmentStatus.toLowerCase()) {
          case 'completed':
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case 'in progress':
            backgroundColor = '#F6851F29';
            statusTextColor = '#F6851F';
            break;
          case 'active':
            backgroundColor = '#f6851f29';
            statusTextColor = '#f6851f';
            break;
          case 'cancelled':
            backgroundColor = '#ff563029';
            statusTextColor = '#ff5630';
            break;
          case 'approved':
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case 'open':
            backgroundColor = '#007bff1f';
            statusTextColor = '#3495ff';
            break;
          case 'filled':
            backgroundColor = '#6f42c129';
            statusTextColor = '#855dcf';
            break;
          default:
            backgroundColor = '#FFFFFF';
            statusTextColor = '#000000';
        }

        return (
          <div
            className="d-flex align-items-center stsusDatadesign"
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
              {assignmentStatus}
            </span>
          </div>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 1.2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };

        const handleClose = () => {
          setAnchorEl(null);
        };
        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <div className="shift-popover">
                <Col className="p-1" onClick={e => createshift(data?.row, e)}>
                  <VisibilityIcon style={{ fill: '#103558' }} />
                  <label className="ps-2">View Details</label>
                </Col>
              </div>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
    };
    getCustomer(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <div>
      <Card className="p-6 mt-5">
        <>
          <KTCard>
            <MuiTable
              addLabel="Add New Clinician"
              columns={columns}
              isAddEnable={false}
              data={assignmentscheduledata?.Result ?? []}
              loading={isGettingassignmentscheduleData}
              tableRef={tableRef}
              isRowClickable={true}
              handlePagination={handlePagination}
              tableOptions={tableOptions}
              filters={
                <>
                  <Box pb={3} display="flex" alignItems="center">
                    <Box>
                      <div className="position-relative">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid"
                          placeholder="Search Shift"
                          aria-label="Search"
                          style={{
                            backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'left 8px center',
                            padding: '20px 30px 0px 30px',
                          }}
                          onChange={e =>
                            handleChangeFilters('search', e.target.value)
                          }
                          value={tableOptions?.search}
                        />
                        {tableOptions?.search && (
                          <i
                            className="fa fa-times"
                            aria-hidden="true"
                            style={{
                              position: 'absolute',
                              right: '10px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              cursor: 'pointer',
                            }}
                            onClick={() => handleChangeFilters('search', '')}
                          ></i>
                        )}
                      </div>
                    </Box>
                  </Box>
                </>
              }
            />
          </KTCard>

          {showDeleteConfirmModal && (
            <ConfirmEventPopup
              showPopup={showDeleteConfirmModal}
              handleClose={handleCloseDelete}
              handleConfirmed={() =>
                updateProviderStatusAPI(showStatusModel, showUserId)
              }
              loading={isUpdateProfile}
              title={'Update Status'}
              message={`Are you sure, do you want to ${showStatusModel} this Profile?`}
            />
          )}
        </>
      </Card>
    </div>
  );
};

export default ShiftApproval;
