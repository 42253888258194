import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation } from 'react-query';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { decodeBase64 } from '../../../../helpers/commonFunctions';
import { addContentOfPages, getContentForManagePages } from '../api';
import { StaticContentTypeEnum } from '../../../helpers/enum';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';

const AboutUs = () => {
  const [bodyText, setBodyText] = useState<any>('');

  const { mutateAsync: getAboutUs, data: aboutUsData } = useMutation(
    'about-us',
    getContentForManagePages
  );

  const { mutateAsync: addEditContent, isLoading: isAdding } = useMutation(
    'add-about-us',
    addContentOfPages
  );

  useEffect(() => {
    getAboutUs({ type: StaticContentTypeEnum?.AboutUs });
  }, []);

  useEffect(() => {
    if (aboutUsData?.Result?.[0]?.Content) {
      const decodedValue = decodeBase64(aboutUsData?.Result?.[0]?.Content);
      setBodyText(decodedValue);
    }
  }, [aboutUsData]);

  const handleSubmit = async () => {
    const encodedData = btoa(bodyText);
    const bodyParams = {
      id:
        aboutUsData?.Result && aboutUsData?.Result?.length > 0
          ? aboutUsData?.Result?.[0]?.StaticContentId
          : 0,
      contentType: StaticContentTypeEnum?.AboutUs,
      content: encodedData,
    };
    let response = await addEditContent(bodyParams);
    if (response?.IsSuccess) {
      snackActions.success(
        response?.message ?? 'About Us Updated Successfully!'
      );
    } else {
      snackActions.error(response?.message ?? 'Something went wrong!');
    }
  };

  return (
    <div>
      <div className="card mb-5 mb-xl-10 p-14">
        <ReactQuill
          theme="snow"
          placeholder="Write something..."
          modules={AboutUs.modules}
          formats={AboutUs.formats}
          style={{ height: '50vh' }}
          value={bodyText}
          onChange={e => setBodyText(e)}
        />
        <div className="d-flex justify-content-end">
          <Button
            variant="success"
            style={{ width: '100px', marginTop: '50px' }}
            onClick={handleSubmit}
            disabled={!bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, '')}
            color={
              !bodyText || !bodyText?.replace(/<\/?[^>]+(>|$)/g, '')
                ? 'disabled'
                : 'success'
            }
          >
            {isAdding ? 'Loading.' : 'Submit'}
          </Button>{' '}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;

AboutUs.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: [] }],
  ],
};
AboutUs.formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color',
];
