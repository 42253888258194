export const AssignmentStatusEnum = {
  Open: 1,
  Filled: 2,
  Completed: 3,
  Approved: 4,
  Cancelled: 5,
};

export const ProviderSkillStatusEnum = {
  Pending: 1,
  Approved: 2,
  Expired: 3,
  Reject: 4,
};

export const AssignmentscheduleStatusEnum = {
  Pending: 1,
  Accepted: 2,
  Completed: 3,
  Dropped: 4,
  Rejected: 5,
  Inprogress: 6,
  NoShow: 7,
};

export const DocumentCategoryEnum = {
  Document: 1,
  BackgroundCheck: 2,
  OnBoardDocument: 3,
};

export const AssignmentScheduleVerificationStatusEnum = {
  Pending: 1,
  Approved: 2,
  Rejected: 3,
};

export const StaticContentTypeEnum = {
  TermsAndCondition: 1,
  PrivacyPolicy: 2,
  AboutUs: 3,
  ContactUs: 4,
};

export const RoleEnum = {
  SuperAdmin: 1,
  CompanyAdmin: 2,
  Admin: 3,
};
