import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import { useMutation, useQuery } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';
import { getDocumentsDetails, uploadPicture } from '../../api';
import { DocumentCategoryEnum } from '../../../../helpers/enum';
import { ReactComponent as Visibility } from '../../../../assets/svg/visibility.svg';
import { APP_URLs } from '../../../../../constants/appURLs';

interface IProps {
  editData: any;
  uploadedFiles: any;
  setUploadedFiles: any;
  currentPage: any;
}

const AddEditOnBoardingDocs = ({
  uploadedFiles,
  setUploadedFiles,
  currentPage,
}: IProps) => {
  const [documentFile, setDocumentFileURL] = useState('');
  const [documentFileName, setDocumentFileName] = useState('');

  const { mutateAsync: pictureUpload, isLoading: isLoadingDoc } = useMutation(
    'upload-image',
    uploadPicture
  );

  const { data: documentTypes, isLoading: isDocumentLoading } = useQuery(
    'documentsDetails',
    getDocumentsDetails
  );

  const detailsTypeList = documentTypes?.Result;

  const onBoardingDocs = detailsTypeList?.filter(
    (item: any) =>
      item?.DocumentCategoryId === DocumentCategoryEnum?.OnBoardDocument
  );

  const handleChangeImageUpload = async (e: any, item: any) => {
    let file = e?.target?.files[0];
    let formData = new FormData();
    setDocumentFileName(file?.name ?? '');
    let filterList =
      uploadedFiles?.filter((e: any) => e.documentTypeId !== item.Id) || [];
    formData.append('files', file);
    formData.append('fileDriveName', 'CompanyDocuments');
    if (file) {
      let response: any = await pictureUpload(formData);
      if (response?.IsSuccess) {
        snackActions.success(
          response.Message ?? 'Files Uploaded successfully.'
        );
        setDocumentFileURL(response?.Result[0]?.FileUrl);
        setDocumentFileName(response?.Result[0]?.FileName);

        if (file) {
          const fileIndex = onBoardingDocs.find((y: any) => y.Id === item?.Id);
          const data = uploadedFiles?.find((y: any) => y.id === item?.Id);
          const newFile = {
            id: data?.Id != 0 ? data?.Id : 0,
            document: {
              documentUrl: response?.Result[0]?.FileUrl,
              fileName: response?.Result[0]?.FileName,
            },
            documentTypeId: item?.Id,
          };
          const listUpdated = [...filterList, newFile];
          setUploadedFiles(listUpdated);
        }
      } else {
        snackActions.error(
          response?.message ?? 'Issue in image upload, try again latter!'
        );
      }
    }
  };

  const handleRemove = (item: any) => {
    let filterList = uploadedFiles.filter(
      (e: any) => e.documentTypeId !== item.Id
    );
    setUploadedFiles(filterList);
  };

  const handleVisibilityIcon = (url: any) => {
    const baseURL = APP_URLs?.viewDocBaseURL;
    window.open(`${baseURL}/${url?.document?.documentUrl}`, '_blank');
  };

  return (
    <div>
      {(isLoadingDoc || isDocumentLoading) && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}
      <Row>
        {onBoardingDocs?.map((item: any, index: any) => {
          const uploadedFile = uploadedFiles?.find(
            (file: any) => file?.documentTypeId === item?.Id
          );
          // const uploadedFile = uploadedFiles?.find(
          //   (file: any) => file.documentTypeId === item.Id
          // );
          return (
            <Col
              xl={currentPage === 'view_clinician' ? 6 : 4}
              md={6}
              xs={12}
              key={index}
            >
              <div key={item?.Id} className="mb-4">
                <label className="form-label fw-bold">
                  {item?.DocumentType}
                </label>
                <div>
                  {uploadedFile?.document.fileName ? (
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex align-items-center justify-content-between selectedFile">
                          <div className="uploadedFile">
                            {uploadedFile?.document.fileName && (
                              <p className="m-0">
                                {uploadedFile?.document.fileName}
                              </p>
                            )}
                          </div>
                          <div className="btn btn-sm btn-icon btn-active-color-primary">
                            <span
                              className="material-symbols-outlined closeBtn"
                              onClick={() => handleRemove(item)}
                            >
                              cancel
                            </span>
                          </div>
                          <div className="btn btn-sm btn-icon btn-active-color-primary">
                            <Visibility
                              className="ms-1"
                              onClick={() => handleVisibilityIcon(uploadedFile)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx,image/*"
                      onChange={e => {
                        const selectedFile = e.currentTarget.files?.[0];
                        if (selectedFile) {
                          handleChangeImageUpload(e, item);
                        }
                      }}
                      className="rounded form-control-file chooseFile Onboarding"
                    />
                  )}
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default AddEditOnBoardingDocs;
