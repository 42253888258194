import clsx from 'clsx';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ToggleComponent } from '../../../assets/ts/components';
import { toAbsoluteUrl } from '../../../helpers';
import { useLayout } from '../../core';
import { ReactComponent as Sidebarmenu } from '../../../assets/svg/sidebarmenu.svg';

type PropsType = {
  sidebarRef: MutableRefObject<HTMLDivElement | null>;
};

const SidebarLogo = (props: PropsType) => {
  const { config } = useLayout();
  const toggleRef = useRef<HTMLDivElement>(null);

  const appSidebarDefaultMinimizeDesktopEnabled =
    config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
  const appSidebarDefaultCollapseDesktopEnabled =
    config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
  const toggleType = appSidebarDefaultCollapseDesktopEnabled
    ? 'collapse'
    : appSidebarDefaultMinimizeDesktopEnabled
      ? 'minimize'
      : '';
  const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
  const appSidebarDefaultMinimizeDefault =
    config.app?.sidebar?.default?.minimize?.desktop?.default;

  useEffect(() => {
    setTimeout(() => {
      const toggleObj = ToggleComponent.getInstance(
        toggleRef.current!
      ) as ToggleComponent | null;

      if (toggleObj === null) {
        return;
      }

      // Add a class to prevent sidebar hover effect after toggle click
      toggleObj.on('kt.toggle.change', function () {
        // Set animation state
        props.sidebarRef.current!.classList.add('animating');

        // Wait till animation finishes
        setTimeout(function () {
          // Remove animation state
          props.sidebarRef.current!.classList.remove('animating');
        }, 300);
      });
    }, 600);
  }, [toggleRef, props.sidebarRef]);

  return (
    <div className="app-sidebar-logo" id="kt_app_sidebar_logo">
      <Link to="/clinician">
        <div
          className="container"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
            marginTop: '10px',
            padding: '8px',
          }}
        >
          <img
            src="/media/bg/Thrivelogo.png"
            style={{ height: '66px', width: '66px', objectFit: 'contain' }}
          />
          <label
            className="logoText"
            style={{ color: '#FFFFFF', fontSize: '25px', padding: '8px' }}
          >
            ThriveCE
          </label>
        </div>
      </Link>

      {(appSidebarDefaultMinimizeDesktopEnabled ||
        appSidebarDefaultCollapseDesktopEnabled) && (
        <div
          ref={toggleRef}
          id="kt_app_sidebar_toggle"
          className={clsx('app-sidebar-toggle', {
            active: appSidebarDefaultMinimizeDefault,
          })}
          data-kt-toggle="true"
          data-kt-toggle-state={toggleState}
          data-kt-toggle-target="body"
          data-kt-toggle-name={`app-sidebar-${toggleType}`}
        >
          <Sidebarmenu />
        </div>
      )}
    </div>
  );
};

export { SidebarLogo };
