/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { charValidate } from '../../../../components/utility';
import { useUserInfo } from '../../../../hooks/useUserInfo';
import ConfirmEventPopup from '../../../layout/components/common/ConfirmEventPopup';
import CustomTooltip from '../../../layout/components/common/CustomTooltip';
import Changepassword from '../../../layout/components/common/changepassword';
import { avatarInitials } from '../../../layout/components/header/Navbar';
import EditProfile from './EditProfile';
import versionInfo from '../../../../versionInfo';
import { Tooltip } from '@mui/material';

const HeaderUserMenu: FC = () => {
  const userInfo = useUserInfo();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSignOutConfirmModal, setShowSignOutConfirmModal] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);

  const userInfoString = localStorage.getItem('userInfo');
  const userInfoData = userInfoString ? JSON.parse(userInfoString) : null;

  const logoutUser = async () => {
    setShowLoading(true);
    setShowLoading(false);
    localStorage.clear();
    window.location.reload();
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowEditModal(false);
    if (isSubmit) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }
  };

  const TooltipWrapper = ({ children, onClick }: any) => {
    const handleClick = (e: any) => {
      e.stopPropagation();
      if (onClick) onClick();
    };

    return <div onClick={handleClick}>{children}</div>;
  };

  return (
    <div
      className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="menu-item px-3">
        <div className="menu-content d-flex align-items-center px-3">
          <div className="symbol symbol-50px me-5">
            <div style={avatarInitials}>
              <span style={{ color: '#fff' }}>{userInfo?.FirstName?.[0]}</span>
              <span style={{ color: '#fff' }}>{userInfo?.LastName?.[0]}</span>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              <Tooltip
                title={
                  userInfo?.FirstName && userInfo?.LastName ? (
                    <h6 style={{ color: 'white' }}>
                      {userInfo?.FirstName} {userInfo?.LastName}
                    </h6>
                  ) : (
                    '-'
                  )
                }
              >
                <span>
                  {userInfo?.FirstName && userInfo?.LastName
                    ? charValidate(
                        `${userInfo?.FirstName} ${userInfo?.LastName}`,
                        20
                      )
                    : '-'}
                </span>
              </Tooltip>
            </div>
            {/* <TooltipWrapper onClick={(e: any) => e.stopPropagation()}> */}
            <Tooltip
              title={
                userInfo?.Email ? (
                  <h6 style={{ color: 'white' }}>{userInfo?.Email}</h6>
                ) : (
                  '-'
                )
              }
            >
              <span>
                {userInfo?.Email ? charValidate(userInfo?.Email, 20) : '-'}
              </span>
            </Tooltip>
            {/* </TooltipWrapper> */}

            <span>Version: {versionInfo?.buildNumber}</span>

            {/* <span>Role: {userInfoData?.Roles?.[0]?.RoleName ?? '-'}</span> */}

            {/* </a> */}
          </div>
        </div>
      </div>

      <div className="separator my-2"></div>

      {/* <Languages /> */}

      <div onClick={() => setShowEditModal(true)} className="menu-item px-5">
        <a className="menu-link px-5">Edit Profile</a>
      </div>

      <div
        onClick={() => setShowChangePassword(true)}
        className="menu-item px-5"
      >
        <a className="menu-link px-5">Change password</a>
      </div>

      <div className="menu-item px-5">
        <a
          onClick={() => setShowSignOutConfirmModal(true)}
          className="menu-link px-5"
        >
          Sign Out
        </a>
      </div>

      {showEditModal && (
        <EditProfile
          show={showEditModal}
          handleClose={handleModalClose}
          userInfo={userInfoData}
        />
      )}

      {showSignOutConfirmModal && (
        <ConfirmEventPopup
          showPopup={showSignOutConfirmModal}
          handleClose={() => setShowSignOutConfirmModal(false)}
          handleConfirmed={logoutUser}
          loading={showLoading}
          title={'Sign Out'}
          message={'Are you sure, do you want to sign out?'}
        />
      )}
      {showChangePassword && (
        <Changepassword
          showPopup={showChangePassword}
          handleClose={() => setShowChangePassword(false)}
          handleConfirmed={logoutUser}
          loading={showLoading}
        />
      )}
    </div>
  );
};

export { HeaderUserMenu };
