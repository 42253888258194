const Clinicialleads = () => {
  return (
    <div>
      <h2
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        Coming soon...
      </h2>
    </div>
  );
};
export default Clinicialleads;
