import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import { useMutation } from 'react-query';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { editSkill, skillData } from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import './skill.css';

const modalsRoot = document.getElementById('root-modals') || document.body;

const Addskill = ({ show, handleClose, editData }: any) => {
  const [documentRequired, setDocumentRequired] = useState(false);
  const [expiryDate, setExpiryDate] = useState(false);

  const validationSchema = Yup.object().shape({
    option: Yup.string().required('Please select an option'),
    skillName: Yup.string().required('Skill Name is required'),
    rateperhour: Yup.string().when('option', ([type], schema) =>
      type === 'Option 1'
        ? schema.required('Rate per Hour is required')
        : schema.nullable()
    ),
    weekday: Yup.string().when('option', ([type], schema) =>
      type === 'Option 2'
        ? schema.required('Weekday is required')
        : schema.nullable()
    ),
    weekend: Yup.string().when('option', ([type], schema) =>
      type === 'Option 2'
        ? schema.required('Weekend is required')
        : schema.nullable()
    ),
  });

  useEffect(() => {
    if (editData?.HasExpiry) {
      setExpiryDate(true);
    } else {
      setExpiryDate(false);
    }
  }, [editData]);

  useEffect(() => {
    if (editData?.IsDocumentRequired) {
      setDocumentRequired(true);
    } else {
      setDocumentRequired(false);
    }
  }, [editData]);

  const formik: any = useFormik({
    initialValues: {
      option:
        editData?.HasFixedRate === false
          ? 'Option 2'
          : editData
          ? 'Option 1'
          : 'Option 1',
      skillName: editData?.SkillName ?? '',
      rateperhour: editData?.HasFixedRate == true ? editData?.RatePerHour : '',
      isActive: false,
      weekend:
        editData?.HasFixedRate == false ? editData?.WeekOffRate.toFixed(2) : '',
      weekday:
        editData?.HasFixedRate == false
          ? editData?.RatePerHour?.toFixed(2)
          : '',
    },
    validationSchema: validationSchema,
    onSubmit: async values => {
      let bodyParams = {
        id: editData ? editData?.Id : undefined,
        skillName: values?.skillName ?? '',
        ratePerHour:
          values?.option == 'Option 1' ? values?.rateperhour : values?.weekday,
        weekOffRate:
          values?.option == 'Option 2' ? values?.weekend : values?.rateperhour,
        isDocumentRequired: documentRequired,
        hasExpiry: expiryDate,
        hasFixedRate: values?.option == 'Option 1' ? true : false,
      };
      let response = editData
        ? await editAPI(bodyParams)
        : await addAPI(bodyParams);
      if (response?.IsSuccess) {
        snackActions.success(response?.Message ?? 'Success');
        formik.resetForm();
        handleClose(true);
      } else {
        snackActions.error(response?.Message ?? 'Something went wrong!!');
      }
    },
  });

  const stateValue = formik.values.state;

  const { mutateAsync: addAPI, isLoading: isAdding } = useMutation(
    'add-skill',
    skillData
  );
  const { mutateAsync: editAPI, isLoading: isEditing } = useMutation(
    'edit-skill',
    editSkill
  );

  const handleDocumentRequiredChange = (e: any) => {
    setDocumentRequired(e.target.checked);
  };

  const handleExpiryDateChange = (e: any) => {
    setExpiryDate(e.target.checked);
  };

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="skillPopup">
        <div className="modal-header">
          <h2 className="mb-0">{editData ? 'Edit' : 'Add'} Skill</h2>
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate className="form">
          <div className="p-4">
            <Container fluid>
              <Row className="gy-3">
                <Col xs={12}>
                  <label className="form-label fw-bold">
                    Skill Name{' '}
                    <span className="fw-bolder mt-5 text-danger">*</span>
                  </label>
                  <div>
                    <input
                      type="text"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 skillInput autofillField"
                      placeholder="Skill Name"
                      aria-label="Skill Name"
                      {...formik.getFieldProps('skillName')}
                    />
                    {formik.touched.skillName && formik.errors.skillName && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.skillName}
                        </div>
                      </div>
                    )}
                  </div>
                </Col>
                <Col xs={12}>
                  <div className="d-flex mb-2">
                    <label className="d-flex align-items-center pe-5">
                      <input
                        type="radio"
                        name="option"
                        value="Option 1"
                        checked={formik.values.option === 'Option 1'}
                        onChange={formik.handleChange}
                      />{' '}
                      <p className="mb-0 ms-2">Default</p>
                    </label>
                    <label className="d-flex align-items-center pe-5">
                      <input
                        type="radio"
                        name="option"
                        value="Option 2"
                        checked={formik.values.option === 'Option 2'}
                        onChange={formik.handleChange}
                      />{' '}
                      <p className="mb-0 ms-2">Custom</p>
                    </label>
                  </div>
                  {formik.values.option === 'Option 1' && (
                    <>
                      <label className="form-label fw-bold">
                        Rate per Hour{' '}
                        <span className="fw-bolder mt-5 text-danger">*</span>
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 rateField autofillField"
                        placeholder="Rate per Hour"
                        aria-label="rateperhour"
                        {...formik.getFieldProps('rateperhour')}
                      />
                      {formik.touched.rateperhour && formik.errors.rateperhour && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.rateperhour}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {formik.values.option === 'Option 2' && (
                    <Row className="gy-3">
                      <Col sm={6} xs={12}>
                        <label className="form-label fw-bold">
                          Weekday ($ per hour){' '}
                          <span className="fw-bolder mt-5 text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 rateField"
                          placeholder="Weekday ($ per hour)"
                          aria-label="weekday"
                          {...formik.getFieldProps('weekday')}
                        />
                        {formik?.touched?.weekday && formik?.errors?.weekday && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.weekday}
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col sm={6} xs={12}>
                        <label className="form-label fw-bold">
                          Weekend ($ per hour){' '}
                          <span className="fw-bolder mt-5 text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 rateField"
                          placeholder="Weekend ($ per hour)"
                          aria-label="weekend"
                          {...formik.getFieldProps('weekend')}
                        />
                        {formik?.touched?.weekend && formik?.errors?.weekend && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.weekend}
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <div className="mt-2 p-4 ps-0 d-flex">
                <div className="form-check me-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={documentRequired}
                    onChange={handleDocumentRequiredChange}
                    id="documentRequiredCheckbox"
                  />
                  <label
                    className="form-check-label fw-normal documentRequiredlabel"
                    htmlFor="documentRequiredCheckbox"
                  >
                    Document Required
                  </label>
                </div>

                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={expiryDate}
                    onChange={handleExpiryDateChange}
                    id="hasExpiryCheckbox"
                  />
                  <label
                    className="form-check-label fw-normal documentRequiredlabel"
                    htmlFor="hasExpiryCheckbox"
                  >
                    Has Expiry?
                  </label>
                </div>
              </div>
            </Container>
          </div>
          <div className="d-flex justify-content-end p-6 pt-0">
            <Button
              type="submit"
              size="lg"
              variant="primary"
              //  style={{ width: "175px" }}
            >
              {isAdding || isEditing ? 'Loading...' : 'Save'}
            </Button>
          </div>
        </form>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default Addskill;
