import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { passwordchange } from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';

function ChangePasswordForm(props: any) {
  let Useremail = props?.email;
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowConfirmPassword] = useState(false);
  const [userCode, setUserCode] = useState('');
  const initialValues = {
    newPassword: '',
    confirmNewPassword: '',
  };
  const navigate = useNavigate();

  const {
    mutateAsync: passwordverify,
    data: otpdata,
    isLoading: otpsending,
  } = useMutation('change-password', passwordchange);

  const PasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New password is required')
      .min(6, 'Password must be at least 6 characters long'),
    confirmNewPassword: Yup.string()
      .required('Confirm new password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords do not match'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: PasswordSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        const response = await passwordverify({
          newPassword: values.newPassword,
          email: Useremail,
          resetPasswordToken: props.userCode,
        });

        if (response?.IsSuccess) {
          snackActions.success(
            response.Message ?? 'Password reset successfully.'
          );
          setUserCode(response.Result);
          setLoading(false);
          navigate('/auth/login');
        } else {
          console.error('API Error:', response?.Message);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error calling API:', error);
        setLoading(false);
      }
    },
  });

  return (
    <div className="container">
      <div className="logo-box text-center mb-6">
        <figure>
          <img
            src="/media/bg/Thrivelogo.png"
            style={{ height: '95px', width: '80px' }}
          />
        </figure>

        <label
          className="mb-5"
          style={{
            fontSize: '34px',
            color: '#ffffff',
            textAlign: 'center',
            alignItems: 'center',
          }}
        >
          ThriveCE
        </label>
      </div>
      <div className="mb-6">
        <h1 style={{ textAlign: 'center', color: '#F2F2F2' }}>
          Change Password
        </h1>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="form-group mt-5">
          {/* <label htmlFor="newPassword">New Password:</label> */}
          <TextField
            type={showPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            className="form-control"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <div className="text-danger">{formik.errors.newPassword}</div>
          )}
        </div>
        <div className="form-group mt-5">
          {/* <label htmlFor="confirmNewPassword">Confirm New Password:</label> */}
          <TextField
            type={showconfirmPassword ? 'text' : 'password'}
            id="confirmNewPassword"
            name="confirmNewPassword"
            className="form-control"
            value={formik.values.confirmNewPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            placeholder="Create New Password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showconfirmPassword)}
                    edge="end"
                  >
                    {showconfirmPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword && (
              <div className="text-danger">
                {formik.errors.confirmNewPassword}
              </div>
            )}
        </div>
        {error && <div className="text-danger">{error}</div>}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <button
            type="submit"
            className="btn btn-dark"
            disabled={loading}
            style={{
              marginBottom: '20px',
              backgroundColor: '#107FA9',
              padding: '10px 20px',
              width: '100%',
            }}
          >
            {loading ? (
              <span className="indicator-progress" style={{ display: 'block' }}>
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">Change Password</span>
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordForm;
