import { RoleEnum } from '../_metronic/helpers/enum';

let userInfo = localStorage.getItem('userInfo');

export function useUserInfo() {
  if (userInfo) {
    return JSON.parse(userInfo);
  } else {
    return null;
  }
}

export const isSuperAdmin = userInfo
  ? (() => {
      const parsedInfo = JSON.parse(userInfo);
      const isAdmin = parsedInfo?.Roles?.some(
        (role: any) => role.RoleId == RoleEnum?.SuperAdmin
      );
      return isAdmin;
    })()
  : false;

export const isAdmin = userInfo
  ? JSON.parse(userInfo)?.Roles?.some(
      (role: any) => role.RoleId == RoleEnum?.Admin
    )
    ? true
    : false
  : false;

export const isFacilityAdmin = userInfo
  ? JSON.parse(userInfo)?.Roles?.some(
      (role: any) => role.RoleId == RoleEnum?.CompanyAdmin
    )
    ? true
    : false
  : false;
