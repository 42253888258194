import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { useMutation, useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  nextStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import { ReactComponent as DeleteIcon } from '../../../../assets/svg/ic_delete.svg';
import { KTIcon } from '../../../../helpers';
import AddEditAssignmentDocument from '../../addeditassignment/addAssignmentDocument';
import {
  getCompanyDetailsById,
  getCompanyDocument,
  getCompanyName,
  getProgram,
} from '../../api';
import './stepper-style.css';

interface IProps {
  isLoading: boolean;
}

export const Step_0 = ({ isLoading }: IProps) => {
  const dispatch = useDispatch();
  const [showAddDocumentModal, setShowAddDocumentModal] = useState(false);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [skillCompanyRatePerHour, setSkillCompanyRatePerHour] =
    useState<Number>(0);
  const [skillcompanyweekendrate, setSkillCompanyWeekendRate] =
    useState<Number>(0);
  const formDatas: any = useSelector((state: any) => state.assignment.formData);

  const Step_0_validation_schema = Yup.object().shape({
    assignmentCode: Yup.string().trim().required('Assignment code is required'),
    assignmentName: Yup.string().trim().required('Assignment name is required'),
    instruction: Yup.string().trim(),
    selectedProgramId: Yup.string()
      .required('Program field is required')
      .trim(),
    companySkillIds: Yup.mixed()
      .required('Skill name is required')
      .test(
        'is-valid-skill',
        'Skill name is required',
        value =>
          typeof value === 'string' ||
          (Array.isArray(value) && value.length > 0)
      ),
    companyId: Yup.string().required('Facility name is required'),
    assignmentDocuments: Yup.array(),
    companyDocumentIds: Yup.array().of(
      Yup.object().shape({
        label: Yup.string().required('Label is required'),
        value: Yup.number()
          .required('Value is required')
          .typeError('Value must be a number'),
      })
    ),
  });
  const {
    data: documentData,
    mutateAsync: getCompanyDocumentById,
    isLoading: isCompanyDocumentLoading,
  } = useMutation('document', getCompanyDocument);

  const { data: companyData, isLoading: isCompanyDataLoading } = useQuery(
    'company-name',
    getCompanyName
  );
  const { data: programData, isLoading: isProgramDataLoading } = useQuery(
    'program-list',
    getProgram
  );

  const { data: skillData, mutateAsync: getSkillDataAPI } = useMutation(
    'comapnydetail',
    getCompanyDetailsById
  );

  const formik: any = useFormik({
    initialValues: {
      assignmentCode: formDatas?.assignmentCode || '',
      assignmentName: 'Hospice Care',
      companyId: formDatas?.companyId || '',
      companySkillIds: formDatas?.companySkillIds || '',
      selectedProgramId: formDatas?.programId || '',
      companyDocumentIds: formDatas?.companyDocumentIds || [],
      instruction: formDatas?.instruction || '',
      isRequiredProviderDocumentUpload: true,
    },
    validationSchema: Step_0_validation_schema,
    onSubmit: async values => {
      const selectedCompanyDocumentValues = values?.companyDocumentIds?.map(
        (item: any) => item?.value
      );
      dispatch(nextStep());
      dispatch(setAssignmentFormData(values));
      dispatch(
        setAssignmentFormData({
          ratePerHour: skillCompanyRatePerHour,
          weekendrate: skillcompanyweekendrate,
          companyDocumentIds: selectedCompanyDocumentValues,
          programId: values?.selectedProgramId ?? '',
        })
      );
    },
  });

  const [isChecked, setIsChecked] = useState(
    formik.initialValues.isRequiredProviderDocumentUpload
  );

  useEffect(() => {
    if (documentData) {
      const updatedOptions = documentData?.Result?.map((d: any) => ({
        label: d?.DocumentName,
        value: d?.Id,
        url: d?.DocumentUrl,
      }));
      setDocumentOptions(updatedOptions);
    } else {
      setDocumentOptions([]);
    }
  }, [documentData]);

  useEffect(() => {
    if (skillData) {
      const companySkills = skillData?.Result?.CompanySkills;
      const skillId = Number(formik.values.companySkillIds);
      if (skillId && companySkills) {
        const skill = companySkills.find((skill: any) => skill.Id == skillId);
        if (skill) {
          setSkillCompanyRatePerHour(skill?.RatePerHour);
          setSkillCompanyWeekendRate(skill?.WeekOffRate);
        } else {
          console.log('Skill not found');
        }
      }
    }
  }, [skillData, formik.values.companySkillIds]);

  useEffect(() => {
    if (formik.values.companyId) {
      getSkillDataAPI({ id: formik.values.companyId });
    }
  }, [formik.values.companyId]);

  useEffect(() => {
    if (formik.values.companyId) {
      getCompanyDocumentById({ companyId: formik.values.companyId });
    }
  }, [formik.values.companyId]);

  useEffect(() => {
    if (formDatas?.Id) {
      formik.setFieldValue('assignmentCode', formDatas?.assignmentCode ?? '');
      formik.setFieldValue('companyId', formDatas?.companyId ?? '');
      formik.setFieldValue('companySkillIds', formDatas?.companySkillIds ?? '');
      formik.setFieldValue('instruction', formDatas?.instruction ?? '');
      formik.setFieldValue('selectedProgramId', formDatas?.programId ?? '');
      if (documentOptions && documentOptions.length > 0) {
        const companyDocumentIds = formDatas?.companyDocumentIds;
        if (Array.isArray(companyDocumentIds)) {
          const selectedDocs = documentOptions.filter((option: any) =>
            companyDocumentIds.includes(option.value)
          );
          formik.setFieldValue('companyDocumentIds', selectedDocs);
        } else {
          console.error(
            'companyDocumentIds is not an array or is undefined:',
            companyDocumentIds
          );
        }
      }
      formik.setFieldValue(
        'isRequiredProviderDocumentUpload',
        formDatas?.isRequiredProviderDocumentUpload
      );
      setIsChecked(formDatas?.isRequiredProviderDocumentUpload);
    }
  }, [formDatas?.Id]);

  const handleAddDocuments = () => {
    setShowAddDocumentModal(true);
  };

  const handleCallBack = (value: any) => {
    setShowAddDocumentModal(false);
  };

  const handleDeleteDocument = (index: number) => {
    const existingDocuments = [...formDatas.assignmentDocuments];
    const updatedDocuments = existingDocuments.filter(
      (document, i) => i !== index
    );
    dispatch(setAssignmentFormData({ assignmentDocuments: updatedDocuments }));
  };

  useEffect(() => {
    if (formDatas) {
      setIsChecked(formDatas?.isRequiredProviderDocumentUpload);
      if (documentOptions && documentOptions.length > 0) {
        const companyDocumentIds = formDatas?.companyDocumentIds;
        if (Array.isArray(companyDocumentIds)) {
          const selectedDocs = documentOptions.filter((option: any) =>
            companyDocumentIds.includes(option.value)
          );
          formik.setFieldValue('companyDocumentIds', selectedDocs);
        } else {
          console.error(
            'companyDocumentIds is not an array or is undefined:',
            companyDocumentIds
          );
        }
      }
    }
  }, [documentOptions.length > 0, formDatas]);

  const handleMultiSelectChange = (selectedItems: any) => {
    const selectedCompanyDocumentValues = selectedItems.map(
      (item: any) => item.value
    );
    formik.setFieldValue('companyDocumentIds', selectedCompanyDocumentValues);
    dispatch(
      setAssignmentFormData({
        companyDocumentIds: selectedCompanyDocumentValues,
      })
    );
  };

  const isShowLoader =
    isLoading ||
    isProgramDataLoading ||
    isCompanyDataLoading ||
    isCompanyDocumentLoading;

  return (
    <form onSubmit={formik.handleSubmit} noValidate className="form">
      <div className="my-5">
        <h3>Assignment Information</h3>
      </div>
      {isShowLoader && (
        <Box className="spinner-loader">
          <CircularProgress />
        </Box>
      )}
      <Row className="gy-4">
        <Col md={6} xs={12}>
          <label className="form-label fw-normal inputTitle">
            Assignment / Internal ID
            <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
          </label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            aria-label="assignment/internalid"
            style={{
              border: '1px solid #919EAB',
              color: 'black',
              padding: '15px',
              backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
            }}
            autoComplete="off"
            placeholder="Assignment / Internal ID"
            disabled={formDatas?.Id}
            {...formik.getFieldProps('assignmentCode')}
          />
          {formik.touched.assignmentCode &&
            formik.errors.assignmentCode &&
            typeof formik.errors.assignmentCode === 'string' && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.assignmentCode}
                </div>
              </div>
            )}
        </Col>

        <Col md={6} xs={12}>
          <label className="form-label fw-normal inputTitle">
            Assignment Name
            <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
          </label>
          <input
            type="text"
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            style={{
              border: '1px solid #919EAB',
              color: 'black',
              padding: '15px',
              backgroundColor: '#e0e0e0',
            }}
            disabled={true}
            placeholder="Assignment Name"
            {...formik.getFieldProps('assignmentName')}
          />
          {formik.touched.assignmentName && formik.errors.assignmentName && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.assignmentName}
              </div>
            </div>
          )}
        </Col>

        <Col md={6} xs={12}>
          <label className="form-label fw-normal inputTitle">
            Select Facility
            <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
          </label>
          <select
            style={{
              border: '1px solid #919EAB',
              backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
            }}
            className="form-select form-select-solid form-select-lg"
            {...formik.getFieldProps('companyId')}
            disabled={formDatas?.Id}
          >
            <option value="">Select Facility</option>
            {companyData
              ? companyData?.Result?.map((company: any) => (
                  <option value={company?.Id} key={company?.Id}>
                    {company?.CompanyName}
                  </option>
                ))
              : null}
          </select>
          {formik.touched.companyId && formik.errors.companyId && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.companyId}</div>
            </div>
          )}
        </Col>

        <Col md={6} xs={12}>
          <label className="form-label fw-normal inputTitle">
            Skill Required
            <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
          </label>
          <select
            style={{
              border: '1px solid #919EAB',
              backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
            }}
            className="form-select form-select-solid form-select-lg"
            {...formik.getFieldProps('companySkillIds')}
            disabled={formDatas?.Id}
          >
            <option value="">Select skill</option>
            {skillData
              ? skillData?.Result?.CompanySkills?.map((skill: any) => (
                  <option value={skill?.Id} key={skill?.Id}>
                    {skill?.SkillName}
                  </option>
                ))
              : null}
          </select>
          {formik.touched.companySkillIds && formik.errors.companySkillIds && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.companySkillIds}
              </div>
            </div>
          )}
        </Col>

        <Col md={6} xs={12}>
          <label className="form-label fw-normal inputTitle">
            Select Program
            <span className="fw-bolder mt-5 text-danger">&nbsp;*</span>
          </label>
          <select
            style={{
              border: '1px solid #919EAB',
              backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
            }}
            className="form-select form-select-solid form-select-lg"
            {...formik.getFieldProps('selectedProgramId')}
            disabled={formDatas?.Id}
          >
            <option value="">Select program</option>
            {programData
              ? programData?.Result?.map((program: any) => (
                  <option value={program?.Id} key={program?.Name}>
                    {program?.Name}
                  </option>
                ))
              : null}
          </select>
          {formik.touched.selectedProgramId &&
            formik.errors.selectedProgramId && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.selectedProgramId}
                </div>
              </div>
            )}
        </Col>

        <Col xs={12}>
          <label className="form-label fw-normal inputTitle">
            Instructions
          </label>
          <textarea
            rows={5}
            style={{
              height: 'auto',
              border: '1px solid #919EAB',
              color: 'black',
              padding: '15px',
              whiteSpace: 'normal',
            }}
            className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
            {...formik.getFieldProps('instruction')}
          />
        </Col>

        <Col xs={12}>
          <label className="form-label fw-normal inputTitle">Documents</label>
          <div className="d-sm-flex align-items-center">
            <MultiSelect
              options={documentOptions}
              onChange={handleMultiSelectChange}
              value={formik.values.companyDocumentIds}
              labelledBy="Select"
              className="form-control form-control-lg form-control-solid p-1"
            />
            {formik.touched.companyDocumentIds &&
              formik.errors.companyDocumentIds && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span className="alert text-danger">
                      {formik.errors.companyDocumentIds}
                    </span>
                  </div>
                </div>
              )}
            <h6 className="mx-sm-4 my-sm-0 my-4 fw-bolder text-center">OR</h6>
            <div>
              <Button
                className="d-flex align-items-center justify-content-center text-align-center m-auto w-100 addDocumentButton"
                onClick={handleAddDocuments}
                disabled={isShowLoader}
              >
                <KTIcon iconName="plus" className="fs-1" />
                Add Document
              </Button>
            </div>
          </div>
        </Col>

        {formDatas?.assignmentDocuments &&
          formDatas?.assignmentDocuments.length > 0 &&
          formDatas?.assignmentDocuments?.map((item: any, index: any) => (
            <Col md={4} sm={6} xs={12}>
              <div key={index} className="selectedDocumentBox">
                <div className="d-flex align-items-center justify-content-between">
                  <div>
                    <Typography variant="subtitle1">
                      {item?.DocumentName || item?.documentName}
                    </Typography>
                  </div>
                  <div>
                    <DeleteIcon onClick={e => handleDeleteDocument(index)} />
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>

      <Row>
        <div className="d-flex justify-content-end mt-6">
          <Button
            type="submit"
            size="lg"
            variant="primary"
            disabled={isShowLoader}
          >
            Save & Next
          </Button>
        </div>
      </Row>

      {showAddDocumentModal && (
        <AddEditAssignmentDocument
          show={showAddDocumentModal}
          handleCallBack={(document: any) => handleCallBack(document)}
          handleClose={() => setShowAddDocumentModal(false)}
        />
      )}
    </form>
  );
};
