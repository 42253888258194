import { FC, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../_metronic/assets/ts/_utils';
import { WithChildren } from '../_metronic/helpers';
import Addnewassignment from '../_metronic/layout/components/assignment/addnewassignment';
import Assignment from '../_metronic/layout/components/assignment/assignment';
import Addassignment from '../_metronic/layout/components/assignment/SearchProvider';
import ViewInvite from '../_metronic/layout/components/assignment/Viewinvite';
import Viewshift from '../_metronic/layout/components/assignment/Viewshift';
import Clinician from '../_metronic/layout/components/Clinician/Clinician';
import Company from '../_metronic/layout/components/company/company';
import Document from '../_metronic/layout/components/documents/Document';
import DocumentType from '../_metronic/layout/components/documentType/DocumentType';
import ShiftApproval from '../_metronic/layout/components/ShiftApproval/Shiftapproval';
import ShiftDetail from '../_metronic/layout/components/ShiftApproval/Shiftdetail';
import { MasterLayout } from '../_metronic/layout/MasterLayout';
import ChatPage from '../pages/chat/ChatPage';
import Clinicialleads from '../pages/Clinicial-leads/Clinicialleads';
import Skill from '../pages/Skill/Skill';
import Admin from '../components/Admin/Admin';
import Nursinghome from '../pages/NursingHome/Nursinghome';
import Program from '../pages/program/Program';
import ShiftPage from '../pages/shift_creation/ShiftPage';
import Timesheet from '../pages/Timesheet/Timesheet';
import View from '../pages/Timesheet/View';
import AddEditProvider from '../_metronic/layout/components/Clinician/AddClinician';
import PrivacyPolicy from '../_metronic/layout/components/manage-pages/PrivacyPolicy';
import TermsConditions from '../_metronic/layout/components/manage-pages/TermsCondition';
import AboutUs from '../_metronic/layout/components/manage-pages/AboutUs';
import ContactUs from '../_metronic/layout/components/manage-pages/Contact-us';
import City from '../pages/city/City';
import { isSuperAdmin } from '../hooks/useUserInfo';

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../pages/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('../pages/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('../pages/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('../pages/widgets/WidgetsPage'));
  // const ChatPage = lazy(() => import("../pages/chat/ChatPage"));

  const routes = [
    { pathname: '/auth/*', element: <Navigate to="/assignment" /> },
    // { pathname: "/", element: isSuperAdmin  ? <Navigate to ="/dashboard" /> : <Navigate to ="/golf-club" /> }, // <----- Redirect to Dashboard after success login/registartion
    { pathname: '/crafted/pages/profile/*', element: <ProfilePage /> },
    { pathname: '/crafted/pages/wizards/*', element: <WizardsPage /> },
    { pathname: '/crafted/widgets/*', element: <WidgetsPage /> },
    { pathname: 'crafted/account/*', element: <AccountPage /> },
    // { pathname: "apps/chat/*", element: <ChatPage /> },
    // { pathname: "*", element: <Navigate to="/error/404" /> }, // <----- Redirect to Page not found if no route match
    { pathname: '/clinician', element: <Clinician /> },
    { pathname: '/company', element: <Company /> },
    { pathname: '/documents', element: <Document /> },
    { pathname: '/city', element: isSuperAdmin ? <City /> : <Navigate to="/error/404" />},
    { pathname: '/documents-type', element: <DocumentType /> },
    { pathname: '/view-assignment-shift', element: <Viewshift /> },
    { pathname: '/shift-creation', element: <ShiftPage /> },

    { pathname: '/shifts', element: <ShiftApproval /> },
    { pathname: '/shifts-detail', element: <ShiftDetail /> },

    { pathname: '/view-invite', element: <ViewInvite /> },
    { pathname: '/chat', element: <ChatPage /> },
    { pathname: '/clincian-leads', element: <Clinicialleads /> },
    { pathname: '/timeSheet', element: <Timesheet /> },
    { pathname: '/view-timeSheet', element: <View /> },
    { pathname: '/program', element: <Program /> },
    { pathname: '/nursing-home', element: <Nursinghome /> },

    { pathname: '/Admin', element: <Admin /> },
    { pathname: '/new-assignment', element: <Addnewassignment /> },
    { pathname: '/manage-clinician', element: <AddEditProvider /> },
    { pathname: '/search-provider', element: <Addassignment /> },

    { pathname: '/skill', element: <Skill /> },

    { pathname: '/assignment', element: <Assignment /> },

    //manage-pages routing

    { pathname: '/manage-privacy-policy', element: <PrivacyPolicy /> },
    { pathname: '/manage-terms-condition', element: <TermsConditions /> },
    { pathname: '/manage-about-us', element: <AboutUs /> },
    { pathname: '/manage-contact-us', element: <ContactUs /> },
  ];

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {routes.map(route => (
          <Route
            key={route.pathname}
            path={route.pathname}
            element={<SuspensedView>{route.element}</SuspensedView>}
          />
        ))}
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary');
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
