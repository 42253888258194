import { Box, Grid, IconButton, Popover } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Card, Col } from 'react-bootstrap';
import { useMutation } from 'react-query';
import MuiTable from '../../../../components/table/MuiTable';
import { snackActions } from '../../../../helpers/SnackUtilsConfigurator';
import useDebounce from '../../../../hooks/useDebounce';
import { isSuperAdmin, useUserInfo } from '../../../../hooks/useUserInfo';
import { KTCard, KTIcon } from '../../../helpers';
import { deleteDocument, getDocumentList } from '../api';
import ConfirmEventPopup from '../common/ConfirmEventPopup';
import AddEditDocument from './AddDocument';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { ReactComponent as Edit } from '../../../assets/svg/edit.svg';
import { ReactComponent as Delete } from '../../../assets/svg/delete.svg';

const Document: React.FC = () => {
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [showActiveDeActivePopup, setShowActiveDeActivePopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const userInfo = useUserInfo();

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    companyId: 0,
  });
  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);
  const tableRef = useRef();
  const {
    mutateAsync: getAppProviders,
    data: appProviderListData,
    isLoading: isGettingThriveData,
  } = useMutation('golf-list', getDocumentList);

  const deleteDocumentAPI = async (value: any) => {
    const response = await deleteDocumentData({ id: value?.Id });
    if (response) {
      snackActions.success('Document deleted successfully.');
      handleCloseDelete();
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      getCustomer(query);
    } else {
      snackActions.error('Failed to delete document.');
    }
  };

  const { mutateAsync: deleteDocumentData, isLoading: isDeletingDocument } =
    useMutation('delete-document', deleteDocument);

  const [showStatusModel, setshowStatusModel] = useState('');
  const [showUserId, setShowUserId] = useState('');

  useEffect(() => {
    getCustomer({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [tableOptions?.companyId, debounceSearchValue]);

  const getCustomer = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
        }
      | undefined
  ) => {
    let response = await getAppProviders(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  const handleStatus = (value: any, id: any) => {
    setShowDeleteConfirmModal(true);
    setshowStatusModel(value);
    setShowUserId(id);
  };

  const handleModalClose = (isSubmit: boolean) => {
    setShowAddEditModal(false);
    setShowViewModal(false);
    handleCloseActivePopup();
    setSelectedRow('');
    if (isSubmit) {
      let query = {
        offset: tableOptions?.offset ?? 10,
        limit: tableOptions?.limit ?? 10,
        roleId: 3,
        search: debounceSearchValue ?? '',
        companyId: tableOptions?.companyId ?? 0,
      };
      getCustomer(query);
    }
  };
  const handleCloseActivePopup = () => {
    setShowActiveDeActivePopup(false);
  };

  const handleEditModalOpen = () => {
    setShowAddEditModal(true);
    setAnchorEl(null);
  };

  const handleCloseDelete = () => {
    setShowDeleteConfirmModal(false);
    setshowStatusModel('');
  };

  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  const columns: GridColDef[] = [
    {
      field: 'documentName',
      headerName: 'Document Name',
      flex: 2,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row?.DocumentName ?? '-',
    },
    {
      field: 'companyName',
      headerName: 'Facility',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => data?.row?.CompanyName ?? '-',
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 2.5,
      sortable: false,
      headerAlign: 'center',
      renderCell: (data: any) => {
        const isActive = data?.row?.IsActive;
        const backgroundColor = isActive ? '#00786729' : '#FF000029';
        const statusTextColor = isActive ? '#007867' : '#FF0000';

        return (
          <div
            className="d-flex align-items-center stsusDatadesign"
            style={{ backgroundColor: backgroundColor }}
          >
            <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
              {isActive ? 'Active' : 'Inactive'}
            </span>
          </div>
        );
      },
    },

    {
      field: 'action',
      headerName: 'Actions',
      flex: 1.5,
      minWidth: 120,
      sortable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        let status: any;
        const handleClick = (value: any, e: any) => {
          setSelectedRow(value);
          setAnchorEl(e.currentTarget);
        };
        const handleClose = () => {
          setAnchorEl(null);
        };

        return (
          <div className="d-flex align-items-center">
            <IconButton onClick={e => handleClick(data?.row, e)}>
              <Setting />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              sx={{
                p: 1,
                '& .MuiPaper-root': {
                  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.03)',
                },
              }}
            >
              <Card className="documentpopover">
                <Card.Body className="p-0">
                  <Col className="pb-2" onClick={handleEditModalOpen}>
                    <Edit />
                    <label className="ms-3">Edit</label>
                  </Col>

                  <Col
                    onClick={() => {
                      handleStatus(status, data?.row?.UserId);
                      setShowDeleteConfirmModal(true);
                      setAnchorEl(null);
                    }}
                  >
                    <Delete />
                    <label className="ms-3" color="#FF5630">
                      Delete
                    </label>
                  </Col>
                </Card.Body>
              </Card>
            </Popover>
          </div>
        );
      },
    },
  ];

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
    };
    getCustomer(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  return (
    <Card className="p-6">
      <>
        <KTCard>
          <MuiTable
            addLabel="Add New Documents"
            columns={columns}
            isAddEnable={false}
            data={appProviderListData?.Result ?? []}
            loading={isGettingThriveData}
            tableRef={tableRef}
            isRowClickable={true}
            handlePagination={handlePagination}
            tableOptions={tableOptions}
            options={{
              onRowClick: (data: any) => {
                setSelectedRow(data?.row);
              },
            }}
            filters={
              <>
                <div className="d-sm-flex mb-4 align-items-end justify-content-between">
                  <Box>
                    {/* <label
                      className="form-label"
                      style={{
                        marginLeft: 5,
                        fontWeight: "900",
                        fontSize: "15.6px",
                      }}
                    >
                      Search
                    </label> */}
                    <div className="mb-sm-0 mb-4 position-relative">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder="Search documents"
                        aria-label="Search"
                        style={{
                          backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'left 8px center',
                          padding: '20px 30px 0px 30px',
                        }}
                        onChange={e =>
                          handleChangeFilters('search', e.target.value)
                        }
                        value={tableOptions?.search}
                      />
                      {tableOptions?.search && (
                        <i
                          className="fa fa-times"
                          aria-hidden="true"
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleChangeFilters('search', '')}
                        ></i>
                      )}
                    </div>
                  </Box>

                  <div className="d-flex justify-content-end">
                    <Button
                      className="d-flex align-items-center addDocumentBtn"
                      onClick={() => {
                        setShowAddEditModal(true);
                        setSelectedRow('');
                      }}
                    >
                      <KTIcon iconName="plus" className="fs-1" />
                      Add Document
                    </Button>
                  </div>
                </div>
              </>
            }
          />
        </KTCard>
        {showAddEditModal && (
          <AddEditDocument
            editData={selectedRow}
            show={showAddEditModal}
            handleClose={handleModalClose}
          />
        )}

        {showDeleteConfirmModal && (
          <ConfirmEventPopup
            showPopup={showDeleteConfirmModal}
            handleClose={handleCloseDelete}
            handleConfirmed={() => deleteDocumentAPI(selectedRow)}
            loading={isDeletingDocument}
            title={'Delete Document'}
            message={`Are you sure, do you want to delete this document?`}
          />
        )}
      </>
    </Card>
  );
};

export default Document;
