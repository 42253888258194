import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import {
  convertDateToMMDDYYYY,
  convertUTCTimesToLocal,
  convertUTCTimesToLocalFuncForCheckInOut,
} from '../../../../../helpers/commonFunctions';
import {
  nextStep,
  prevStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getShift } from '../../api';
import './stepper-style.css';

export const Step_1 = () => {
  const dispatch = useDispatch();
  const [selectedDates, setSelectedDates] = useState();
  const formDatas = useSelector(state => state.assignment.formData);
  const { parse, isAfter, addDays } = require('date-fns');

  const Step_1_validation_schema = Yup.object().shape({
    option: Yup.string().required('Please select an option'),
    selectedShiftValue: Yup.mixed().when('option', ([type], schema) =>
      type != 'Option 2' && !formDatas?.shiftId
        ? schema
            .required('Select at least one shift')
            .test(
              'is-array-or-string',
              'Please select one shift',
              function (value) {
                return Array.isArray(value) || typeof value === 'string';
              }
            )
        : schema.nullable()
    ),
    dateforshift: Yup.array()
      .min(1, 'Date is required')
      .required('Date is required'),
    startTime: Yup.string().when('option', ([type], schema) =>
      type != 'Option 1'
        ? schema
            .required('Start time is required')
            .matches(
              /^(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/,
              'Start time must be in HH:mm format'
            )
        : schema.notRequired()
    ),
    endTime: Yup.string().when('option', ([type], schema) =>
      type !== 'Option 1'
        ? schema
            .required('End time is required')
            .matches(
              /^(?:(?:1[0-2]|0?[1-9]):[0-5][0-9] (AM|PM))|(?:2[0-3]|[01]?[0-9]):[0-5][0-9]$/,
              'End time must be in HH:mm or hh:mm AM/PM format'
            )
            .test(
              'is-greater',
              'End time must be greater than Start time',
              function (value) {
                const startTime = this.parent.startTime;
                if (!startTime || !value) return true;

                // Parse times into Date objects using date-fns
                const startTimeDate = parse(
                  startTime,
                  /[AP]M$/i.test(startTime) ? 'hh:mm a' : 'HH:mm',
                  new Date()
                );
                let endTimeDate = parse(
                  value,
                  /[AP]M$/i.test(value) ? 'hh:mm a' : 'HH:mm',
                  new Date()
                );

                // Adjust endTimeDate if it's before startTimeDate and spans midnight
                if (endTimeDate < startTimeDate) {
                  endTimeDate = addDays(endTimeDate, 1); // Move end time to the next day
                }

                // Compare times
                return isAfter(endTimeDate, startTimeDate);
              }
            )
            .test(
              'max-thirteen-hours',
              'End time cannot exceed 13 hours after Start time',
              function (value) {
                const startTime = this.parent.startTime;
                if (!startTime || !value) return true;

                // Parse times into Date objects using date-fns
                const startTimeDate = parse(
                  startTime,
                  /[AP]M$/i.test(startTime) ? 'hh:mm a' : 'HH:mm',
                  new Date()
                );
                let endTimeDate = parse(
                  value,
                  /[AP]M$/i.test(value) ? 'hh:mm a' : 'HH:mm',
                  new Date()
                );

                // Adjust endTimeDate if it's before startTimeDate and spans midnight
                if (endTimeDate < startTimeDate) {
                  endTimeDate = addDays(endTimeDate, 1); // Move end time to the next day
                }

                // Calculate time difference in milliseconds
                const maxTimeDiffInMillis = 13 * 60 * 60 * 1000; // 13 hours in milliseconds
                const timeDiffInMillis = endTimeDate - startTimeDate;

                // Check if the time difference is within the allowed range
                return timeDiffInMillis <= maxTimeDiffInMillis;
              }
            )
        : schema.notRequired()
    ),
    noOfPersonRequired: Yup.string(),
  });

  const { data: shiftData } = useQuery('shift', getShift);

  const StyledDatePicker = styled(DatePicker)`
    width: 100% !important;
    height: 46.8px;
    border: 1px solid rgb(145, 158, 171);
    color: black;
    padding: 15px;
    border-radius: 8px;
    background-color: var(--bs-gray-100);
  `;

  const formik = useFormik({
    initialValues: {
      dateforshift: formDatas?.assignmentSchedules?.dateforshift ?? [],
      selectedShiftValue:
        formDatas?.assignmentSchedules[0]?.selectedShiftValue ?? '',
      option: formDatas?.shiftTimeOption || 'Option 1',
      startTime: formDatas?.assignmentSchedules[0]?.startTime ?? '',
      endTime: formDatas?.assignmentSchedules[0]?.endTime ?? '',
      noOfPersonRequired: 1,
    },
    validationSchema: Step_1_validation_schema,
    onSubmit: async values => {
      const starttime =
        shiftData?.Result?.find(
          shift => shift.Id == values?.selectedShiftValue
        )?.StartTime.slice(0, 5) || '';
      const endtime =
        shiftData?.Result?.find(
          shift => shift.Id == values?.selectedShiftValue
        )?.EndTime.slice(0, 5) || '';
      const locallyConvertedStartTime = convertUTCTimesToLocal(
        currentDate,
        starttime,
        endtime
      );
      const { ratePerHour, ...assignmentSchedulesData } = values;
      if (values?.option == 'Option 1') {
        assignmentSchedulesData.startTime =
          locallyConvertedStartTime?.startTime?.slice(0, 5);
        assignmentSchedulesData.endTime =
          locallyConvertedStartTime?.endTime?.slice(0, 5);
      }
      const updatedValues = {
        assignmentSchedules: [assignmentSchedulesData],
      };
      const Customshift = values?.option == 'Option 1' ? false : true;
      dispatch(setAssignmentFormData(updatedValues));
      dispatch(
        setAssignmentFormData({
          shiftTimeOption: values?.option,
          isCustomShiftTime: Customshift,
        })
      );
      dispatch(nextStep());
    },
  });

  useEffect(() => {
    if (formDatas) {
      if (formDatas?.shiftTimeOption == 'Option 1') {
        formik.setFieldValue(
          'selectedShiftValue',
          formDatas?.shiftId ||
            formDatas?.assignmentSchedules[0]?.selectedShiftValue
        );
      } else {
        formik.setFieldValue('selectedShiftValue', '');
      }
      formik.setFieldValue(
        'option',
        formDatas?.isCustomShiftTime === true ? 'Option 2' : 'Option 1'
      );
      let assignmentDate = [];
      if (formDatas?.assignmentSchedules?.[0]?.dateforshift) {
        assignmentDate = formDatas?.assignmentSchedules?.[0]?.dateforshift?.map(
          x => new Date(x)
        );
      } else if (formDatas?.assignmentSchedules) {
        assignmentDate = formDatas?.assignmentSchedules?.map(
          x => new Date(x.EndTime)
        );

        const StartTime = convertUTCTimesToLocalFuncForCheckInOut(
          formDatas?.assignmentSchedules[0]?.StartTime ?? null,
          formDatas?.assignmentSchedules[0]?.StartTime ?? null,
          formDatas?.assignmentSchedules[0]?.EndTime ?? null,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'checkIn'
        );

        const Endtime = convertUTCTimesToLocalFuncForCheckInOut(
          formDatas?.assignmentSchedules[0]?.StartTime ?? null,
          formDatas?.assignmentSchedules[0]?.StartTime ?? null,
          formDatas?.assignmentSchedules[0]?.EndTime ?? null,
          Intl.DateTimeFormat().resolvedOptions().timeZone,
          'clockOut'
        );
        formik.setFieldValue('startTime', StartTime);
        formik.setFieldValue('endTime', Endtime);
      }
      if (assignmentDate?.length) {
        let dateFromReduxStore = formDatas?.assignmentSchedules?.[0];
        const utcAssignmentDate = dateFromReduxStore?.StartTime?.split('T')[0];
        const utcStartTime = dateFromReduxStore?.StartTime?.split('T')[1];
        const utcEndTime = dateFromReduxStore?.EndTime?.split('T')[1];
        const localTime = convertUTCTimesToLocal(
          utcAssignmentDate,
          utcStartTime,
          utcEndTime
        );
        const formattedDate = moment(
          localTime?.assignmentDate,
          'MM-DD-YYYY'
        ).format('MM/DD/YYYY');
        const isValidDate = moment(localTime?.assignmentDate, 'YYYY-MM-DD');
        if (isValidDate?.isValid() && !formDatas?.Id) {
          formik.setFieldValue('dateforshift', formattedDate);
        } else {
          formik.setFieldValue('dateforshift', assignmentDate);
        }
        setSelectedDates(assignmentDate);
      }
    }
  }, [formDatas]);

  // useEffect(() => {
  //   if (formDatas?.Id) {
  //     const date = formDatas?.assignmentSchedules?.map(date =>
  //       date?.AssignmentDate ? date?.AssignmentDate : date?.dateforshift
  //     );
  //     const startTime = formDatas?.assignmentSchedules[0]?.StartTime
  //       ? formDatas?.assignmentSchedules[0]?.StartTime.substring(11)
  //       : formDatas?.assignmentSchedules[0]?.startTime;
  //     const EndTime = formDatas?.assignmentSchedules[0]?.EndTime
  //       ? formDatas?.assignmentSchedules[0]?.EndTime.substring(11)
  //       : formDatas?.assignmentSchedules[0]?.endTime;
  //     let x = convertUTCTimesToLocal(date, startTime, EndTime);

  //     if (startTime) {
  //       formik.setFieldValue('startTime', startTime);
  //       formik.setFieldValue('endTime', EndTime);
  //       const StartTime = convertUTCTimesToLocalFuncForCheckInOut(
  //         formDatas?.assignmentSchedules[0]?.StartTime,
  //         formDatas?.assignmentSchedules[0]?.StartTime,
  //         formDatas?.assignmentSchedules[0]?.EndTime,
  //         Intl.DateTimeFormat().resolvedOptions().timeZone,
  //         'checkIn'
  //       );

  //       const Endtime = convertUTCTimesToLocalFuncForCheckInOut(
  //         formDatas?.assignmentSchedules[0]?.startTime,
  //         formDatas?.assignmentSchedules[0]?.startTime,
  //         formDatas?.assignmentSchedules[0]?.EndTime,
  //         Intl.DateTimeFormat().resolvedOptions().timeZone,
  //         'clockOut'
  //       );
  //       formik.setFieldValue('startTime', StartTime);
  //       formik.setFieldValue('endTime', Endtime);
  //     } else {
  //       formik.setFieldValue('startTime', (x?.startTime).slice(0, 5));
  //       formik.setFieldValue('endTime', (x?.endTime).slice(0, 5));
  //     }
  //   }
  // }, []);

  const onChangeDateShift = dates => {
    setSelectedDates(dates);
    formik.setFieldValue('dateforshift', dates);
  };

  useEffect(() => {
    if (shiftData) {
      const dropdownId = shiftData?.Result;
      const timeValue = formik?.values?.selectedShiftValue;
      if (timeValue && dropdownId) {
        const selectedTime = dropdownId?.find(time => time?.Id == timeValue);
        if (!formDatas?.isCustomShiftTime) {
          formik.setFieldValue('startTime', '');
          formik.setFieldValue('endTime', '');
        } else {
          formik.setFieldValue('startTime', selectedTime?.StartTime);
          formik.setFieldValue('endTime', selectedTime?.EndTime);
        }
      }
    }
  }, [shiftData, formik.values.selectedShiftValue]);

  const currentDate = moment().format('YYYY-MM-DD');

  const removeDate = dateToRemove => {
    const updatedDates = selectedDates.filter(date => date !== dateToRemove);
    setSelectedDates(updatedDates);
    formik.setFieldValue('dateforshift', updatedDates);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} noValidate className="form">
        <div className="my-5">
          <h3>Create Shift</h3>
        </div>
        <Row className="gy-4">
          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">
              Start Date
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>

            <div className="input-group custome">
              <StyledDatePicker
                showIcon
                selectedDates={selectedDates}
                selectsMultiple
                {...formik.getFieldProps('dateforshift')}
                shouldCloseOnSelect={false}
                disabledKeyboardNavigation
                placeholderText="MM/DD/YYYY"
                minDate={new Date()}
                onChange={onChangeDateShift}
                autoComplete="off"
              />{' '}
              <br />
              {formik.touched.dateforshift && formik.errors.dateforshift && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.dateforshift}
                  </div>
                </div>
              )}
            </div>
            <div>
              {selectedDates && selectedDates?.length > 0 && (
                <div className="selected-dates-container mt-3 d-flex flex-wrap selectedDateView">
                  {selectedDates?.map((date, index) => (
                    <div key={index} className="chip d-flex align-items-center">
                      {convertDateToMMDDYYYY(date)}
                      <HighlightOffIcon
                        className="remove-icon"
                        onClick={() => removeDate(date)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Col>

          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle">
              No of Person Required
              <span className="fw-bolder mt-5 text-danger"> *</span>
            </label>
            <input
              type="text"
              className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
              aria-label="rateperhour"
              style={{
                border: '1px solid #919EAB',
                color: 'black',
                padding: '15px',
                backgroundColor: '#e0e0e0',
              }}
              value="1"
              disabled
            />
          </Col>
          <Col md={6} xs={12}>
            <label className="form-label fw-normal inputTitle pe-5">
              Shift Time
              {''} <span className="fw-bolder mt-5 text-danger">*</span>
            </label>
            <div>
              <Row>
                <Col xs="12" className="mb-3">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <label className="pe-5 d-flex align-items-center">
                        <input
                          type="radio"
                          name="option"
                          value="Option 1"
                          checked={formik.values.option === 'Option 1'}
                          onChange={formik.handleChange}
                          disabled={formDatas?.Id}
                        />{' '}
                        <p className="mb-0 ms-2">Default</p>
                      </label>
                      <label className="pe-5 d-flex align-items-center">
                        <input
                          type="radio"
                          name="option"
                          value="Option 2"
                          checked={formik.values.option === 'Option 2'}
                          onChange={formik.handleChange}
                          disabled={formDatas?.Id}
                        />{' '}
                        <p className="mb-0 ms-2">Custom</p>
                      </label>
                    </div>
                    {formik.values.option === 'Option 1' && (
                      <select
                        style={{
                          border: '1px solid #919EAB',
                          backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                        }}
                        className="form-select form-select-solid form-select-lg"
                        {...formik.getFieldProps('selectedShiftValue')}
                        disabled={formDatas?.Id}
                      >
                        <option value="">Select Shift</option>
                        {shiftData?.Result?.map(shift => (
                          <option value={shift?.Id} key={shift?.Id}>
                            {/* {convertUTCTimesToLocal1(currentDate, shift?.StartTime, shift?.EndTime)} */}
                            {convertUTCTimesToLocal(
                              currentDate,
                              shift?.StartTime,
                              shift?.EndTime
                            ).startTime?.slice(0, 5)}{' '}
                            -{' '}
                            {convertUTCTimesToLocal(
                              currentDate,
                              shift?.StartTime,
                              shift?.EndTime
                            ).endTime?.slice(0, 5)}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  {formik?.touched?.selectedShiftValue &&
                    formik?.errors?.selectedShiftValue && (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik?.errors?.selectedShiftValue}
                        </div>
                      </div>
                    )}
                </Col>

                {formik.values.option === 'Option 2' && (
                  <>
                    <Col xs="6" className="d-flex flex-column">
                      <label className="form-label fw-normal inputTitle">
                        Start Time
                      </label>
                      <input
                        type="time"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputStyleField"
                        aria-label="start time"
                        step="1800"
                        placeholder="Start Time"
                        style={{
                          backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                        }}
                        {...formik.getFieldProps('startTime')}
                        disabled={formDatas?.Id}
                      />
                      {formik.touched.startTime && formik.errors.startTime && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.startTime}
                          </div>
                        </div>
                      )}
                    </Col>

                    <Col xs="6" className="d-flex flex-column">
                      <label className="form-label fw-normal inputTitle">
                        End Time
                      </label>
                      <input
                        type="time"
                        className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 inputStyleField me-0"
                        {...formik.getFieldProps('endTime')}
                        aria-label="end time"
                        placeholder="End Time"
                        style={{
                          backgroundColor: formDatas?.Id ? '#e0e0e0' : '',
                        }}
                        disabled={formDatas?.Id}
                      />
                      {formik.touched.endTime && formik.errors.endTime && (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors.endTime}
                          </div>
                        </div>
                      )}
                    </Col>
                  </>
                )}
              </Row>
            </div>
          </Col>

          <Col md={6} xs={12}>
            <div className="d-flex justify-content-between">
              <div className="rateTitle">
                <label
                  className="form-label fw-normal"
                  style={{ fontSize: '16px' }}
                >
                  Rate ($/hr)
                  <span className="fw-bolder mt-5" style={{ color: 'red' }}>
                    {' '}
                    *
                  </span>
                </label>
              </div>
              <div className="w-100 pe-3">
                <label className="form-label fw-bold">
                  Weekday <span className="fw-bolder mt-5 text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 WeeklyRate"
                  placeholder="Weekday"
                  aria-label="weekday"
                  style={{
                    backgroundColor: '#e0e0e0',
                  }}
                  value={formDatas?.ratePerHour?.toFixed(2)}
                  disabled
                />
                {formik?.touched?.weekday && formik?.errors?.weekday && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.weekday}</div>
                  </div>
                )}
              </div>
              <div className="w-100">
                <label className="form-label fw-bold">
                  Weekend <span className="fw-bolder mt-5 text-danger">*</span>
                </label>
                <input
                  type="number"
                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 WeeklyRate"
                  placeholder="Weekend"
                  aria-label="weekend"
                  style={{
                    backgroundColor: '#e0e0e0',
                  }}
                  disabled
                  value={formDatas?.weekendrate?.toFixed(2)}
                />
                {formik?.touched?.weekend && formik?.errors?.weekend && (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">{formik.errors.weekend}</div>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
        <div className="d-flex justify-content-between mt-10">
          <Button
            size="lg"
            className="px-10"
            onClick={() => dispatch(prevStep())}
            variant="secondary"
          >
            Back
          </Button>

          <Button type="submit" size="lg" variant="primary">
            Save & Next
          </Button>
        </div>
      </form>
    </>
  );
};
