import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useMutation } from 'react-query';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Forgotpassword } from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import OTPPage from './otp';

const initialValues = {
  email: '',
};

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
});

export function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [userCode, setUserCode] = useState('');
  const [error, setError] = useState('');
  const [step, setStep] = useState('step0');
  const navigate = useNavigate();
  const  location = useLocation()
  const emailValue = location.state

  const {
    mutateAsync: sendotpforverfication,
    data: otpdata,
    isLoading: otpsending,
  } = useMutation('send-otp', Forgotpassword);

  const formik = useFormik({
    initialValues: {
      email: emailValue ?? ""
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      try {
        setLoading(true);
        const response = await sendotpforverfication(values);

        if (response?.IsSuccess) {
          setUserCode(response.Result);
          snackActions.success(
            response.Message ?? 'OTP has been sent successfully'
          );
          setStep('step1');
          const result = response.data.Result;
          setLoading(false);
        }
        else{
          setLoading(false)
        }
      } catch (error) {
        console.error('Error sending OTP:', error);
        setError('Failed to send OTP. Please try again.');
        setLoading(false);
      }
    },
  });

  return (
    <>
      {step === 'step1' ? (
        <OTPPage userCode={userCode} formik={formik} />
      ) : (
        <form
          className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework p-0"
          noValidate
          id="kt_login_password_reset_form"
          onSubmit={formik.handleSubmit}
        >
          <div className="text-center mb-10 vh-50">
            {/* begin::Title */}

            <div className="logo-box">
              <figure>
                <img
                  src="/media/bg/Thrivelogo.png"
                  style={{ height: '95px', width: '80px' }}
                />
              </figure>
            </div>

            <label
              className="mb-5"
              style={{ fontSize: '34px', color: '#ffffff' }}
            >
              ThriveCE
            </label>
            <h1 className="fw-bolder mb-3" style={{ color: '#F2F2F2' }}>
              Forgot Password
            </h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className="fw-semibold fs-6 m-0" style={{ color: '#ECEBDF' }}>
              Enter your email to reset your password.
            </div>
            {/* end::Link */}
          </div>

          {/* begin::Form group */}
          <div className="fv-row mb-5">
            <input
              type="email"
              placeholder="Email"
              autoComplete="off"
              style={{ backgroundColor: '#F2F2F2', padding:"16.5px 14px" }}
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.email as string}</span>
                </div>
              </div>
            )}
          </div>

          <div className="m-0 p-0">
            <button
              type="submit"
              id="kt_password_reset_submit"
              className="btn"
              disabled={loading}
              style={{
                color: '#FFFFFF',
                backgroundColor: '#107FA9',
                width: '100%',
              }}
            >
              {!loading && <span className="indicator-label">Submit</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Please wait...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <div>
            <div className="mt-5">
              <Link to="/auth/login" state={emailValue}>
                {/* <button
                type="button"
                id="kt_login_password_reset_form_cancel_button"
                className="btn btn-light"
                disabled={formik.isSubmitting || loading}
                style={{backgroundColor:"#103558"}}
              >
                Back to Login page
              </button> */}
                <a
                  style={{
                    color: 'white',
                    textAlign: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {' '}
                  Back to{' '}
                  <span style={{ color: '#88BDD1', marginLeft: '5px' }}>
                    {' '}
                    Login
                  </span>
                </a>
              </Link>
            </div>
          </div>
          {/* </div> */}
          {/* end::Form group */}
        </form>
      )}
    </>
  );
}
