const getLabel = (typeId: number) => {
  switch (typeId) {
    case 1:
      return 'Licence (blue copy)';
    case 2:
      return 'CPR';
    case 3:
      return 'TB/2 Step';
    case 4:
      return 'Physical/Statement of Health';
    case 6:
      return 'Resume';
    case 5:
      return 'COVID vaccine document';
    case 7:
      return 'State verification of license';
    case 8:
      return 'AHCA background';
    case 9:
      return 'OFAC';
    case 10:
      return 'OIG Exclusion';
    case 11:
      return 'Sexual Offender Registry Check';
    case 12:
      return 'SAM';
    case 13:
      return 'Drug Screen';
    case 14:
      return 'Relias/CEUs';
    default:
      return null;
  }
};

const getBodyText = (typeId: number) => {
  switch (typeId) {
    case 1:
      return 'A blue copy license is an official duplicate of a professional license.';
    case 2:
      return 'lifesaving technique used in emergencies to restore breathing and blood circulation in individuals experiencing cardiac arrest or respiratory failure. Certification requires regular renewal.';
    case 3:
      return 'A TB (tuberculosis) test, detects latent or active TB infection in individuals.';
    case 4:
      return 'A Physical/Statement of Health for Clinician assesses their fitness for duty, ensuring they meet health standards required for patient care.';
    case 6:
      return "A resume is a document summarizing an individual's education, work experience, skills, and achievements, tailored for career opportunities.";
    case 5:
      return 'A Covid vaccine document provides official proof of vaccination which required for employment.';
    case 7:
      return "State Verification of a license confirms the validity and status of a professional's credentials, ensuring compliance with state regulations.";
    case 8:
      return 'The AHCA (Agency for Health Care Administration) background check ensures healthcare providers meet state regulations, screening for criminal history and compliance to maintain patient safety and quality care standards.';
    case 9:
      return 'The OFAC (Office of Foreign Assets Control) administers and enforces economic and trade sanctions based on U.S. foreign policy and national security goals.';
    case 10:
      return 'The OIG Exclusion List identifies individuals and entities barred from participating in federally funded healthcare programs due to fraud, patient abuse, or other violations.';
    case 11:
      return 'A Sexual Offender Registry Check typically refers to verifying whether an individual is listed on a registry of persons convicted of sex crimes.';
    case 12:
      return 'SAM (System for Award Management) is a U.S. government database that consolidates information about entities interested in doing business with the federal government.';
    case 13:
      return "A Drug Screen is a test conducted to detect the presence of drugs or their metabolites in an individual's system.";
    case 14:
      return 'Relias offers Continuing Education Units (CEUs) to healthcare professionals, providing ongoing training and certification updates essential for career development and compliance.';
    default:
      return null;
  }
};
export { getLabel, getBodyText };
