import React from 'react';
import { Popover, IconButton, Typography, Box } from '@mui/material';
import { convertDateToMMDDYYYY } from './commonFunctions';

const ShowNotesHistoryInPopup = ({
  id,
  open,
  anchorEl,
  onClose,
  title,
  notes,
}: any) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <IconButton
        sx={{
          position: 'absolute',
          top: '2px',
          right: '5px',
          color: '#fe7253',
        }}
        onClick={onClose}
      >
        <span className="material-symbols-outlined">cancel</span>
      </IconButton>
      <div className="text-center p-4">
        <Typography variant="h6" gutterBottom className="mb-0">
          {title}
        </Typography>
      </div>
      <hr className="m-0" />
      <Box sx={{ padding: '12px 20px' }}>
        {notes?.map((historyItem: any) => (
          <Box
            key={historyItem?.Id}
            sx={{
              backgroundColor: '#F3F3F3',
              padding: '10px',
              borderRadius: '5px',
              marginBottom: '10px',
              '&:last-child': {
                margin: '0px',
              },
            }}
          >
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
              <span>Date: </span>
              <span style={{ fontWeight: '400', marginLeft: '3px' }}>
                {convertDateToMMDDYYYY(historyItem?.CreatedOn)}
              </span>
            </Typography>
            <Typography style={{ fontSize: '14px', fontWeight: 'bold' }}>
              <span>Reason: </span>
              <span style={{ fontWeight: '400', marginLeft: '3px' }}>
                {historyItem?.Note}
              </span>
            </Typography>
          </Box>
        ))}
      </Box>
    </Popover>
  );
};

export default ShowNotesHistoryInPopup;
