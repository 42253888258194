import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import React from 'react';

interface IProps {
  setUseSnackbarRef: (showSnackbar: any) => void;
}

const InnerSnackbarUtilsConfigurator: React.FC<IProps> = (props: IProps) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef: any;
const setUseSnackbarRef = (useSnackbarRefProp: any) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

export const dismissNotification = (key: SnackbarKey): (() => void) => {
  return () => {
    snackActions.close(key);
  };
};

const dismissAction = (key: SnackbarKey) => (
  <button onClick={dismissNotification(key)}>Dismiss</button>
);

export const snackActions = {
  success(msg: string) {
    this.toast(msg, 'success', 'snackbar-success');
  },
  error(msg: string) {
    this.toast(msg, 'error', 'snackbar-error');
  },
  dismissibleError(msg: string) {
    this.dismissibleToast(msg, 'error');
  },
  close(key: SnackbarKey) {
    this.closeSnackbar(key);
  },
  toast(
    msg: string,
    variant: VariantType = 'default',
    customClass: string = ''
  ) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      autoHideDuration: 2000,
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
      className: customClass,
    });
  },
  dismissibleToast(
    msg: string,
    variant: VariantType = 'default',
    // hideDuation: number = 10000000000,
    hideDuation: number = 3000
  ) {
    useSnackbarRef.enqueueSnackbar(msg, {
      variant,
      style: { whiteSpace: 'pre-line' },
      autoHideDuration: hideDuation,
      action: dismissAction,
    });
  },
  closeSnackbar(key: SnackbarKey) {
    useSnackbarRef.closeSnackbar(key);
  },
};
