/* eslint-disable react/jsx-no-target-blank */
import { KTIcon } from '../../../helpers';

const SidebarFooter = () => {
  return (
    <div
      className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6"
      style={{ backgroundColor: 'rgb(16,53,88)' }}
      id="kt_app_sidebar_footer"
    ></div>
  );
};

export { SidebarFooter };
