import { createSlice } from '@reduxjs/toolkit';
import { userLogin } from './authActions';
import { IAuthState } from '../../../constants/types';

// initialize userToken from local storage
const userToken: null | string = localStorage.getItem('userToken')
  ? localStorage.getItem('userToken')
  : null;

const initialState: IAuthState = {
  loading: false,
  userInfo: null, // for user object
  userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [userLogin.pending]: state => {
      state.loading = true;
      state.error = null;
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.userInfo = payload;
      state.userToken = payload.token;
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default authSlice.reducer;
