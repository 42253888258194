import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import * as Yup from 'yup';
import { OTPverification } from '../../_metronic/layout/components/api';
import { snackActions } from '../../helpers/SnackUtilsConfigurator';
import ChangePasswordForm from './passwordchange';

function OTPPage(props: any) {
  let emailFromForgotPassword = props?.formik?.values?.email;
  const [step, setStep] = useState('step1');
  const [loading, setLoading] = useState(false);
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [userCode, setUserCode] = useState('');

  const initialValues = {
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  };

  const otpSchema = Yup.object().shape({
    otp1: Yup.string().length(1, 'OTP must be exactly 1 character'),
    otp2: Yup.string().length(1, 'OTP must be exactly 1 character'),
    otp3: Yup.string().length(1, 'OTP must be exactly 1 character'),
    otp4: Yup.string().length(1, 'OTP must be exactly 1 character'),
    otp5: Yup.string().length(1, 'OTP must be exactly 1 character'),
    otp6: Yup.string().length(1, 'OTP must be exactly 1 character'),
  });

  const {
    mutateAsync: verifyotp,
    data: otpdata,
    isLoading: otpsending,
  } = useMutation('send-otp', OTPverification);

  const formik = useFormik({
    initialValues,
    validationSchema: otpSchema,
    onSubmit: async values => {
      try {
        setLoading(true);
        const response = await verifyotp({
          email: emailFromForgotPassword ?? '',
          userCode: props.userCode,
          otp: parseInt(
            values.otp1 +
              values.otp2 +
              values.otp3 +
              values.otp4 +
              values.otp5 +
              values.otp6
          ),
        });
        if (response?.IsSuccess) {
          snackActions.success(
            response.Message ?? 'OTP verified successfully.'
          );
          setUserCode(response.Result);
          setLoading(false);
          setStep('step2');
        } else {
          console.error('API Error:', response?.Message);
          setLoading(false);
        }
      } catch (error) {
        console.error('Error calling API:', error);
        setLoading(false);
      }
    },
  });

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = e.target;
    formik.setFieldValue(`otp${index + 1}`, value);
    focusNextInput(index);
  };

  const focusNextInput = (currentIndex: number) => {
    if (currentIndex < inputRefs.current.length - 1) {
      inputRefs.current[currentIndex + 1]?.focus();
    }
  };

  const handleVerifyClick = () => {
    setIsButtonClicked(true);
    if (formik.isValid) {
      formik.handleSubmit();
    }
  };
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Backspace') {
        const currentIndex = inputRefs.current.findIndex(
          ref => ref === document.activeElement
        );
        const currentField = inputRefs.current[currentIndex];
        if (currentIndex > 0 && currentField && currentField.value === '') {
          formik.setFieldValue(`otp${currentIndex}`, '');
          inputRefs.current[currentIndex - 1]?.focus();
        } else if (
          currentIndex === 0 &&
          currentField &&
          currentField.value === ''
        ) {
          event.preventDefault();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [formik]);

  const isAllFieldsFilled = Object.values(formik.values).every(
    value => value !== ''
  );

  return (
    <>
      {step === 'step2' ? (
        <ChangePasswordForm
          userCode={userCode}
          email={emailFromForgotPassword}
        />
      ) : (
        <div>
          <div className="logo-box text-center">
            <figure>
              <img
                src="/media/bg/Thrivelogo.png"
                style={{ height: '95px', width: '80px' }}
              />
            </figure>

            <label
              className="mb-5"
              style={{
                fontSize: '34px',
                color: '#ffffff',
                textAlign: 'center',
                alignItems: 'center',
              }}
            >
              ThriveCE
            </label>
          </div>
          <h1 style={{ textAlign: 'center', color: '#F2F2F2' }}>Enter OTP</h1>
          <p className="mb-7" style={{ color: '#ECEBDF', textAlign: 'center' }}>
            Please enter the 6-digit verification code{' '}
          </p>
          <form onSubmit={formik.handleSubmit}>
            <div>
              <div className="form-group">
                <div className="d-flex justify-content-between">
                  {[...Array(6)].map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      value={
                        formik.values[
                          `otp${index + 1}` as keyof typeof formik.values
                        ]
                      }
                      onChange={e => handleInputChange(e, index)}
                      className="form-control mx-1"
                      maxLength={1}
                      style={{
                        maxWidth: '54px',
                        height: '54px',
                        textAlign: 'center',
                        borderRadius: '8px',
                      }}
                      ref={ref => {
                        inputRefs.current[index] = ref;
                      }}
                    />
                  ))}
                </div>
                {Object.keys(formik.errors).map(fieldName => {
                  if (fieldName.startsWith('otp')) {
                    return (
                      <div key={fieldName} className="text-danger">
                        {formik.errors[fieldName as keyof typeof initialValues]}
                      </div>
                    );
                  }
                  return null;
                })}
              </div>
              <div className="d-grid mb-3 mt-5">
                <button
                  type="submit"
                  className="btn"
                  style={{
                    backgroundColor: '#107FA9',
                    fontSize: '14px',
                    width: '100%',
                    marginBottom: '20px',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  disabled={loading || !isAllFieldsFilled}
                  onClick={handleVerifyClick}
                >
                  {!loading ? (
                    <span
                      className="indicator-label"
                      style={{ fontSize: '18px', color: '#FFFFFF' }}
                    >
                      Verify
                    </span>
                  ) : (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block', color: 'white' }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default OTPPage;
