import { FieldArray, Form, Formik } from 'formik';
import { Button, Card, Modal } from 'react-bootstrap';
import { createPortal } from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import 'react-quill/dist/quill.snow.css';
import * as Yup from 'yup';
import { Box, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';

import PhoneInput from 'react-phone-input-2';
import { setProviderReferences } from '../../../../../store/slice/form/document_form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { snackActions } from '../../../../../helpers/SnackUtilsConfigurator';

interface IProps {
  handleClose: (isSubmit: boolean) => void;
  editData: any;
  show: boolean;
  type: any;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const useStyles: any = makeStyles((theme: any) => ({
  formControl: {
    marginBottom: theme.spacing(2),
  },
  addButton: {
    marginTop: theme.spacing(2),
  },
  deleteButton: {
    marginTop: theme.spacing(2),
  },
}));

const AddEditRefDocs = ({ show, handleClose, editData, type }: IProps) => {
  // const classes: any = useStyles();
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    references: Yup.array().of(
      Yup.object().shape({
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
        phoneNumber: Yup.string().required('Phone number is required'),
        countryCode: Yup.string(),
        designation: Yup.string().required('Job designation is required'),
      })
    ),
  });

  const getProviderReferencesData: any = useSelector(
    (state: any) => state.clinicianDoc.documentFormData?.providerReferences
  );

  const [initialValues, setInitialValues] = useState({
    references: [
      {
        id: 0,
        firstName: '',
        lastName: '',
        email: '',
        countryCode: '1',
        phoneNumber: '',
        designation: '',
      },
    ],
  });

  useEffect(() => {
    if (getProviderReferencesData && getProviderReferencesData.length) {
      setInitialValues({
        references: getProviderReferencesData.map((data: any) => ({
          id: data.id || 0,
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          countryCode: data.countryCode || '1',
          phoneNumber: `${data.countryCode} ${data.phoneNumber}` || '',
          designation: data.designation || '',
        })),
      });
    }
  }, [getProviderReferencesData]);

  return createPortal(
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-700px"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '1.5px solid #919EAB66',
            padding: '15px 20px',
          }}
        >
          <h2 style={{ marginBottom: '0px' }}>References</h2>
          {/* end::Close */}

          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span
              className="material-symbols-outlined"
              style={{ color: '#FE7253' }}
            >
              cancel
            </span>
          </div>
        </div>

        <div className="p-8">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={values => {
              if (values.references.length < 2) {
                snackActions.error('Please add at least two references');
              } else {
                const processedReferences = values.references.map(ref => {
                  const cleanedPhoneNumber = ref.phoneNumber.slice(1);
                  return {
                    ...ref,
                    phoneNumber: cleanedPhoneNumber,
                  };
                });
                const payload = {
                  providerReferences: processedReferences,
                };
                dispatch(setProviderReferences(payload));
                handleClose(true);
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              setFieldValue,
              handleChange,
            }: any) => (
              <Form>
                <Typography
                  variant="body1"
                  sx={{
                    mb: 2,
                    color: '#103558',
                    fontSize: '14px',
                    fontWeight: 500,
                  }}
                >
                  References are individuals who can validate qualifications and
                  suitability for a role.
                </Typography>
                <FieldArray name="references">
                  {({ insert, remove, push }: any) => (
                    <>
                      {values.references.length > 0 &&
                        values.references.map((reference: any, index: any) => (
                          <Card className="p-6 mb-4">
                            <Box
                              key={index}
                              // className={classes.referenceContainer}
                              sx={{ mb: 2 }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <label className="form-label fw-bold">
                                    First Name{' '}
                                    <span
                                      className="fw-bolder mt-5"
                                      style={{ color: 'red' }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name={`references.${index}.firstName`}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                                    placeholder="First Name"
                                    aria-label="First Name"
                                    style={{
                                      backgroundColor: 'white',
                                      border: '1px solid #919EAB',
                                    }}
                                    onChange={handleChange}
                                    value={
                                      values.references?.[index]?.firstName
                                    }
                                  />
                                  {touched.references?.[index]?.firstName &&
                                    errors.references?.[index]?.firstName && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          {
                                            errors.references?.[index]
                                              ?.firstName
                                          }
                                        </div>
                                      </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <label className="form-label fw-bold">
                                    Last Name{' '}
                                    <span
                                      className="fw-bolder mt-5"
                                      style={{ color: 'red' }}
                                    >
                                      *
                                    </span>
                                  </label>

                                  <input
                                    type="text"
                                    name={`references.${index}.lastName`}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                                    placeholder="Last Name"
                                    aria-label="Last Name"
                                    style={{
                                      backgroundColor: 'white',
                                      border: '1px solid #919EAB',
                                    }}
                                    onChange={handleChange}
                                    value={values.references?.[index]?.lastName}
                                  />
                                  {touched.references?.[index]?.lastName &&
                                    errors.references?.[index]?.lastName && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          {errors.references?.[index]?.lastName}
                                        </div>
                                      </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <label className="form-label fw-bold">
                                    Email{' '}
                                    <span
                                      className="fw-bolder mt-5"
                                      style={{ color: 'red' }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    name={`references.${index}.email`}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                                    placeholder="Email"
                                    aria-label="Email"
                                    style={{
                                      backgroundColor: 'white',
                                      border: '1px solid #919EAB',
                                    }}
                                    onChange={handleChange}
                                    value={values.references?.[index]?.email}
                                  />
                                  {touched.references?.[index]?.email &&
                                    errors.references?.[index]?.email && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          {errors.references?.[index]?.email}
                                        </div>
                                      </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <label className="form-label fw-bold">
                                    Phone Number{' '}
                                    <span
                                      className="fw-bolder mt-5"
                                      style={{ color: 'red' }}
                                    >
                                      *
                                    </span>
                                  </label>
                                  <PhoneInput
                                    country={'us'}
                                    value={values.references[index].phoneNumber}
                                    onChange={(value, country: any) => {
                                      setFieldValue(
                                        `references.${index}.phoneNumber`,
                                        value
                                      );
                                      setFieldValue(
                                        `references.${index}.countryCode`,
                                        country?.dialCode || ''
                                      );
                                    }}
                                    inputStyle={{
                                      background: '#white',
                                      width: '100%',
                                      border: '1px solid #919EAB',
                                    }}
                                    inputClass="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                                    disableDropdown={false}
                                    disableCountryCode={false}
                                    onlyCountries={['us']}
                                  />
                                  {touched.references?.[index]?.phoneNumber &&
                                    errors.references?.[index]?.phoneNumber && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          {
                                            errors.references?.[index]
                                              ?.phoneNumber
                                          }
                                        </div>
                                      </div>
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                  <label className="form-label fw-bold">
                                    Job Designation{' '}
                                    <span
                                      className="fw-bolder mt-5"
                                      style={{ color: 'red' }}
                                    >
                                      *
                                    </span>
                                  </label>

                                  <input
                                    type="text"
                                    name={`references.${index}.designation`}
                                    className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 autofillField"
                                    placeholder="Job designation"
                                    style={{
                                      backgroundColor: 'white',
                                      border: '1px solid #919EAB',
                                    }}
                                    onChange={handleChange}
                                    value={
                                      values.references?.[index]?.designation
                                    }
                                  />
                                  {touched.references?.[index]?.designation &&
                                    errors.references?.[index]?.designation && (
                                      <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                          {
                                            errors.references?.[index]
                                              ?.designation
                                          }
                                        </div>
                                      </div>
                                    )}
                                </Grid>
                                {values.references.length === 2 ? (
                                  <Grid item xs={12}>
                                    <div style={deleteBtnStyle}>
                                      <button
                                        disabled={
                                          values.references.length === 1
                                        }
                                        style={{
                                          border: 'none',
                                          background: 'transparent',
                                        }}
                                      >
                                        <div
                                          className="btn btn-sm btn-icon btn-active-color-primary"
                                          onClick={() => remove(index)}
                                        >
                                          <span
                                            className="material-symbols-outlined"
                                            style={{
                                              color: '#FE7253',
                                              cursor:
                                                values.references.length === 1
                                                  ? 'not-allowed'
                                                  : 'pointer',
                                            }}
                                          >
                                            cancel
                                          </span>
                                        </div>
                                        <span
                                          style={{
                                            color: '#FE7253',
                                            cursor:
                                              values.references.length === 1
                                                ? 'not-allowed'
                                                : 'pointer',
                                            fontSize: '15px',
                                          }}
                                          onClick={() => remove(index)}
                                        >
                                          Delete
                                        </span>
                                      </button>
                                    </div>
                                  </Grid>
                                ) : null}
                              </Grid>
                            </Box>
                          </Card>
                        ))}
                      {values.references.length < 2 && (
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                          <Button
                            type="button"
                            variant="primary"
                            size="sm"
                            color="primary"
                            onClick={() =>
                              push({
                                id: values.references.length,
                                firstName: '',
                                lastName: '',
                                email: '',
                                phoneNumber: '',
                                designation: '',
                                countryCode: '1',
                              })
                            }
                          >
                            Add <AddCircleOutlineOutlinedIcon />
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
                {/* <div
                  style={{
                    borderBottom: "1.5px solid #919EAB66",
                    padding: "15px 20px",
                    marginLeft: "-25px",
                    marginRight: "-25px",
                  }}
                ></div> */}
                <div
                  className="w-100 d-flex align-items-end justify-content-end mt-3"
                  style={{ marginBottom: '-11px' }}
                >
                  <Button
                    type="submit"
                    size="lg"
                    variant="primary"
                    style={{ padding: '7px 45px' }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Card>
    </Modal>,
    modalsRoot
  );
};

export default AddEditRefDocs;

const deleteBtnStyle: any = {
  position: 'relative',
  bottom: '0px',
  left: '215px',
  top: '65px',
};
