import { useLayout } from '../../core';

const FooterWrapper = () => {
  const { config } = useLayout();
  if (!config.app?.footer?.display) {
    return null;
  }

  return (
    <div className="app-footer" id="kt_app_footer">
      {/* {config.app.footer.containerClass ? (
        <div className={clsx("app-container", config.app.footer.containerClass)}
        >
          <Footer />
        </div>
      ) : (
        <Footer />
      )} */}
      {/* <div className="app-container container-fluid">
        <div className="align-items-center g-3 row">
          <div className="col-md-8">
              <div className="fmenu mb-3 mb-md-0">
                <ul className="d-flex flex-wrap justify-content-center justify-content-md-start">
                  <li>
                    <a href="terms-conditions">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="privacy-policy">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="faq">FAQ</a>
                  </li>
                  <li>
                    <a href="about-app">About App</a>
                  </li>
                </ul>
              </div>
          </div>
          <div className="col-md-4 pb-8 pb-md-0">
              <p className="copyright-text m-0 text-center text-md-end">&copy; 2024 - New Nine Golf</p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export { FooterWrapper };
