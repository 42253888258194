import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiTable from '../../../../../components/table/MuiTable';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  prevStep,
  setAssignmentFormData,
} from '../../../../../store/slice/form/assignment_form';
import { ProviderSkillStatusEnum } from '../../../../helpers/enum';
import { getSearchprovider } from '../../api';
import './stepper-style.css';
import { APP_URLs } from '../../../../../constants/appURLs';
import { ReactComponent as User } from '../../../../assets/svg/user.svg';
import { checkPrime } from 'crypto';

interface LocationState {
  cityvalue: string;
  companyId: string;
  cityid: string;
  selectedskillId: string;
  nursingoptioncityvalue: string;
  selectedOption: string;
  stepvalue: number;
  AssignmentData: any;
  InvitedUsedIds: any;
  TableDates: any;
  shiftDetails: any;
  shiftcheckboxdetails: any;
}

interface ShiftDetails {
  assignmentDate: any;
  startTime: any;
  endTime: any;
  invitedUserIds: any[];
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const Step_4 = () => {
  const formDatas = useSelector((state: any) => state.assignment.formData);
  const companyId = formDatas?.companyId;
  const selectedDateFromTable = formDatas?.selectedTabelDates;
  const cityIds = formDatas?.cityId
    ? formDatas?.cityId
    : formDatas?.nursinghomecityId;
  const skillsIds = formDatas?.companySkillIds;
  const skillIdForSearchProvider = formDatas?.skillIdForProviderSearch;
  const AssignmentShiftDetails = formDatas?.shiftdetail;
  const selecteddatecheckboxvalue = formDatas?.tablecheckbox;
  const IsForViewInvited = formDatas?.isforViewInvited;
  const DateForViewInvited = formDatas?.viewinvitedselecteddate;
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedCity = (location.state as LocationState)?.cityvalue || '';
  const nursingHomeCityValue =
    (location.state as LocationState)?.nursingoptioncityvalue || '';
  const companyid = (location.state as LocationState)?.companyId || '';
  const cityId = (location.state as LocationState)?.cityid || '';
  const skillId = (location.state as LocationState)?.selectedskillId || '';
  const selectedoption =
    (location.state as LocationState)?.selectedOption || '';
  const StepValue = (location.state as LocationState)?.stepvalue || 0;
  const assignmentvalues =
    (location.state as LocationState)?.AssignmentData || '';
  const InvitedUserIds =
    (location.state as LocationState)?.InvitedUsedIds || [];
  const TableSelectedDates =
    (location.state as LocationState)?.TableDates || [];
  const ShiftDetails = (location.state as LocationState)?.shiftDetails || [];
  const tabledatescheckbox =
    (location.state as LocationState)?.shiftcheckboxdetails || [];
  const navigate = useNavigate();
  const tableRef = useRef();

  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
    id: Number(companyId) || 0,
    skillIds: Number(skillIdForSearchProvider) || 0,
    programId: formDatas?.programId || null,
  });

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [checkedStatuses, setCheckedStatuses] = useState<{
    [key: string]: boolean;
  }>({});
  const [updatedshiftdetails, setUpdatedshiftdeatails] = useState<any[]>([]);
  const [newItem, setNewItem] = useState<string>('');
  const [filteredInvitedUsers, setFilteredInvitedUsers] = useState([]);
  const [checkedIds, setCheckedIds] = useState<any>([]);

  const {
    mutateAsync: getSearchProviderdata,
    data: appProviderListData,
    isLoading: isGettingAppProviderData,
  } = useMutation('search-list', getSearchprovider);

  // const handleCheckboxChange = (item: any) => {
  //   if (IsForViewInvited) {
  //     const updatedCheckedIds = checkedIds.includes(item?.Id)
  //       ? checkedIds.filter((id: any) => id !== item?.Id)
  //       : [...checkedIds, item?.Id];
  //     setCheckedIds(updatedCheckedIds);
  //     if (formDatas?.Id) {
  //       const updatedShiftDetails = formDatas?.shiftdetail?.map(
  //         (detail: any) => {
  //           if (updatedCheckedIds.includes(item?.Id)) {
  //             const shiftdate = new Date(detail?.assignmentDate);
  //             if (shiftdate?.getTime() === DateForViewInvited?.getTime()) {
  //               const updatedInvitedUserIds = detail.invitedUserIds.filter(
  //                 (id: number) => !updatedCheckedIds.includes(id)
  //               );
  //               return { ...detail, invitedUserIds: updatedInvitedUserIds };
  //             } else {
  //               return { ...detail };
  //             }
  //           }
  //           return detail;
  //         }
  //       );
  //       setUpdatedshiftdeatails(updatedShiftDetails);
  //     } else {
  //       const updatedShiftDetails = formDatas?.shiftdetail.map(
  //         (detail: any) => {
  //           if (updatedCheckedIds.includes(item?.Id)) {
  //             if (
  //               detail.assignmentDate.getTime() === DateForViewInvited.getTime()
  //             ) {
  //               const updatedInvitedUserIds = detail.invitedUserIds.filter(
  //                 (id: number) => !updatedCheckedIds.includes(id)
  //               );
  //               return { ...detail, invitedUserIds: updatedInvitedUserIds };
  //             } else {
  //               return { ...detail };
  //             }
  //           }
  //           return detail;
  //         }
  //       );
  //       setUpdatedshiftdeatails(updatedShiftDetails);
  //     }
  //   } else {
  //     if (selectedItems.includes(item)) {
  //       setSelectedItems(prevSelectedItems =>
  //         prevSelectedItems.filter(selectedItem => selectedItem !== item)
  //       );
  //     } else {
  //       setSelectedItems(prevSelectedItems => [...prevSelectedItems, item]);
  //     }
  //   }
  //   setCheckedStatuses(prevStatuses => ({
  //     ...prevStatuses,
  //     [item.Id]: !prevStatuses[item.Id],
  //   }));
  // };


  const handleCheckboxChange = (item: any) => {
    if (IsForViewInvited) {
      const updatedCheckedIds = checkedIds.includes(item?.Id)
        ? checkedIds.filter((id: any) => id !== item?.Id)
        : [...checkedIds, item?.Id];
  
      setCheckedIds(updatedCheckedIds);
  
      const shiftDetailsToUpdate =  updatedshiftdetails;
  
      const updatedShiftDetails = shiftDetailsToUpdate.map((detail: any) => {
        const shiftdate = new Date(detail.assignmentDate);
  
        if ((shiftdate.getDate() && shiftdate.getDay() ) === (DateForViewInvited.getDate() && DateForViewInvited.getDay())) {
          return { ...detail, invitedUserIds: updatedCheckedIds };
        }
  
        return detail;
      });
      setUpdatedshiftdeatails(updatedShiftDetails);
    } else {
      // Handle the case when IsForViewInvited is false
      if (selectedItems.includes(item)) {
        setSelectedItems(prevSelectedItems =>
          prevSelectedItems.filter(selectedItem => selectedItem !== item)
        );
      } else {
        setSelectedItems(prevSelectedItems => [...prevSelectedItems, item]);
      }
    }
  
    setCheckedStatuses(prevStatuses => ({
      ...prevStatuses,
      [item.Id]: !prevStatuses[item.Id],
    }));
  };
  
  useEffect(() => {
    const updatedCheckedStatuses: { [key: string]: boolean } = {};
    if (IsForViewInvited) {
      filteredInvitedUsers.forEach((item: any) => {
        updatedCheckedStatuses[item.Id] = checkedIds.some(
          (checkedItem: any) => checkedItem === item.Id
        );
      });
    } else {
      selectedItems.forEach((item: any) => {
        updatedCheckedStatuses[item.Id] = true;
      });
    }
    setCheckedStatuses(updatedCheckedStatuses);
    setUpdatedshiftdeatails(formDatas?.shiftdetail);
  }, [IsForViewInvited, filteredInvitedUsers]);

  const handleAddItem = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    let finalItems: any[] = [...selectedItems];
    if (newItem.trim() !== '') {
      finalItems.push(newItem.trim());
      setNewItem('');
    }
    finalItems = finalItems.filter(
      (item, index) => finalItems.indexOf(item) === index
    );
    setSelectedItems(finalItems);
    dispatch(prevStep());
    dispatch(
      setAssignmentFormData({
        InvitedUserIds: finalItems.length > 0 ? finalItems : [],
        selectedTabelDates: selectedDateFromTable,
        shiftdetail: IsForViewInvited
          ? updatedshiftdetails
          : AssignmentShiftDetails,
        tablecheckbox: selecteddatecheckboxvalue,
      })
    );
  };

  const debounceSearchValue: string = useDebounce(tableOptions?.search, 500);

  useEffect(() => {
    getcliniciandata(tableOptions);
  }, []);

  useEffect(() => {
    getcliniciandata({
      ...tableOptions,
      search: debounceSearchValue ?? '',
    });
  }, [tableOptions?.id, debounceSearchValue]);

  const handlePagination = (values: { page: number; pageSize: number }) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    let query = {
      offset:
        pageSize == tableOptions?.limit ? (page === 0 ? 0 : `${page}` + 0) : 0,
      limit: pageSize ?? 10,
      search: debounceSearchValue ?? '',
      id: Number(companyId),
      skillIds: Number(skillIdForSearchProvider) || 0,
      programId: formDatas?.programId,
    };
    getcliniciandata(query);
  };

  const handleChangeFilters = (fieldName: string, value: string) => {
    setTableOptions((prevState: any) => {
      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const getcliniciandata = async (
    query:
      | {
          offset: number | string;
          limit: number;
          search: string;
          id: number;
          skillIds: number;
          programId: any;
        }
      | undefined
  ) => {
    let response = await getSearchProviderdata(query);
    if (response?.IsSuccess) {
      setTableOptions((prevState: any) => {
        return {
          ...prevState,
          offset: response?.PageDetail?.Skip,
          limit: response?.PageDetail?.Take,
          hasNextPage: response?.PageDetail?.HasNext,
          hasPrevPage: response?.PageDetail?.HasPrevious,
          totalRowCount: response?.PageDetail?.Count,
        };
      });
    }
  };

  useEffect(() => {
    if (IsForViewInvited) {
      if (
        DateForViewInvited &&
        AssignmentShiftDetails &&
        appProviderListData?.Result
      ) {
        const matchingShiftDetails = AssignmentShiftDetails?.filter(
          (detail: any) => {
            const assignmentDate =
              detail?.assignmentDate || detail?.AssignmentDate;
            return assignmentDate
              ? new Date(assignmentDate).getDate() ===
                  DateForViewInvited.getDate()
              : false;
          }
        );
        const invitedUserIds = matchingShiftDetails.flatMap(
          (detail: any) => detail.invitedUserIds || detail.InvitedUserIds || []
        );

        const matchingUsers = appProviderListData.Result.filter((user: any) =>
          invitedUserIds.includes(user.Id)
        );

        setFilteredInvitedUsers(matchingUsers);
        const initialCheckedIds = invitedUserIds.map((userId: any) =>
          appProviderListData.Result.find((user: any) => user.Id === userId)?.Id
        );
        setCheckedIds(initialCheckedIds);
        setUpdatedshiftdeatails(formDatas?.shiftdetail)
      }
    }
  }, [IsForViewInvited, formDatas, appProviderListData]);

  const columns: GridColDef[] = [
    {
      field: 'profile',
      headerName: '',
      flex: 0.5,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <input
            type="checkbox"
            className="form-check-input findCheckBox"
            checked={!!checkedStatuses[data?.row?.Id]}
            onChange={() => handleCheckboxChange(data?.row)}
          />
        );
      },
    },
    {
      field: 'profile1',
      headerName: '',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div>
            {data.row.ProfilePictureUrl ? (
              <img
                className="clinicianImage"
                src={`${APP_URLs.viewDocBaseURL}/${data.row.ProfilePictureUrl}`}
                alt="API Image"
              />
            ) : (
              <User />
            )}
          </div>
        );
      },
    },
    {
      field: 'profile2',
      headerName: 'Clinician',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <div className="clinicianUser">
            <label className="clinicianUserText">
              {data.row.FirstName + ' ' + data.row.LastName}
            </label>
          </div>
        );
      },
    },
    {
      field: 'mobileno',
      headerName: 'Mobile Number',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div className="clinicianUser">
            <label className="clinicianUserText">{data.row.MobileNumber}</label>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div className="clinicianUser">
            <label className="clinicianUserText">{data.row.Email}</label>
          </div>
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <div className="clinicianUser">
            <label className="clinicianUserText">{data.row.Address}</label>
          </div>
        );
      },
    },
    {
      field: 'skillstatus',
      headerName: 'Skill Status',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        const status = data?.row?.ProviderSkill?.StatusName;
        let backgroundColor = '';
        let statusTextColor = '';
      
        switch (status) {
          case "Approved":
            backgroundColor = '#00786729';
            statusTextColor = '#007867';
            break;
          case "Pending":
            backgroundColor = '#f6851f29';
            statusTextColor = '#f6851f';
            break;
          case "Expired":
            backgroundColor = '#6f42c129';
            statusTextColor = '#855dcf';
            break;
          case "Reject":
            backgroundColor = '#ff563029';
            statusTextColor = '#ff5630';
            break;
            case "Inprogress":
            backgroundColor = '#d9671629';
            statusTextColor = '#d96716';
            break;
          default:
            backgroundColor = '';
            statusTextColor = '';
            break;
        }      
        return (
          <div
            className="d-flex align-items-center clinicianSkillstatus"
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            <span style={{ color: statusTextColor, fontWeight: 'bold' }}>
              {status}
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Card>
        {!IsForViewInvited && (
          <div className="modal-header mt-5 findClinician">
            <h2>Find Clinician</h2>
          </div>
        )}

        <form>
          <div className="p-5">
            {!IsForViewInvited && (
              <Box mt={2}>
                <label className="fw-normal mb-4">Select Clinicians</label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Search Clinician"
                  aria-label="Search"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left 8px center',
                    padding: '20px 30px 0px 30px',
                    border: '1px solid #919EAB66',
                  }}
                  onChange={e => handleChangeFilters('search', e.target.value)}
                  value={tableOptions?.search}
                />
              </Box>
            )}
            <MuiTable
              columns={columns}
              isAddEnable={false}
              data={
                IsForViewInvited
                  ? filteredInvitedUsers
                  : appProviderListData?.Result || []
              }
              loading={isGettingAppProviderData}
              tableRef={tableRef}
              isRowClickable={true}
              handlePagination={handlePagination}
              tableOptions={tableOptions}
            />
            <div className="d-flex justify-content-between mt-10">
              <Button
                size="lg"
                className="px-10"
                onClick={() => dispatch(prevStep())}
                variant="secondary"
              >
                Back
              </Button>

              <Button
                type="button"
                size="lg"
                variant="primary"
                onClick={handleAddItem}
              >
                Select
              </Button>
            </div>
          </div>
        </form>
      </Card>
    </>
  );
};

export default Step_4;
