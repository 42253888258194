import { useEffect } from 'react';
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { getCompanyDetailsById } from '../api';
import './company.css';
import { APP_URLs } from '../../../../constants/appURLs';
import { ReactComponent as Userprofile } from '../../../assets/svg/userprofile.svg';
type Props = {
  show: boolean;
  handleClose: (isSubmit: boolean) => void;
  title?: string | undefined;
  isForEditProfile?: boolean;

  viewData: {
    Id: any;
    isAdmin: boolean;
    skillName: string;
    lastName: string;
    firstName: string;
    phone: string;
    lastname: string;
    email: string;
    firstname: string;
    isPrimaryAddress: boolean;
    address: any;
    ZipCode: string;
    state: string;
    city: string;
    AddressLine2: string;
    AddressLine1: string;
    description: string;
    CompanyWebsiteUrl: string;
    CompanyName: string;
    companySkills: any;
  };
};

const ViewCompany = ({
  show,
  handleClose,
  viewData,
  title,
  isForEditProfile = false,
}: Props) => {
  const {
    data: companyData,
    mutateAsync: getCompanyDataById,
    isLoading: isLoadingCompany,
    error: companyAPIError,
  } = useMutation('comapnydetail', getCompanyDetailsById);

  useEffect(() => {
    if (viewData) {
      getCompanyDataById({ id: viewData.Id });
    }
  }, [viewData]);

  return (
    <Modal
      id="kt_modal_create_app"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-850px mx-md-auto mx-3"
      show={show}
      onHide={() => handleClose(false)}
      backdrop="static"
    >
      <Card className="companyModel">
        <div className="modal-header py-2 px-5">
          <h2 className="mb-0">{title ? title : 'View Facility'}</h2>
          <div
            className="btn btn-icon btn-active-color-primary"
            onClick={() => handleClose(false)}
          >
            <span className="material-symbols-outlined closeBtn">cancel</span>
          </div>
        </div>
        <div className="p-4">
          <form>
            <Container fluid>
              <Row className="p-0 m-0">
                <Col md={3} sm={4} xs={12}>
                  <div>
                    <label
                      htmlFor="golf-img"
                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0 cursor-pointer viewImageLabel"
                    >
                      {companyData?.Result?.CompanyLogoUrl ? (
                        <img
                          className="viewCompanyImage"
                          src={`${APP_URLs?.viewDocBaseURL}/${companyData?.Result?.CompanyLogoUrl}`}
                        />
                      ) : (
                        <Userprofile
                          style={{
                            width: '140px',
                            height: '140px',
                            position: 'absolute',
                            top: '0',
                            left: '-6px',
                          }}
                          onClick={() => {
                            const inputElement =
                              document.getElementById('golf-img');
                            if (inputElement) {
                              inputElement.click();
                            }
                          }}
                        />
                      )}
                    </label>
                  </div>
                </Col>
                <Col md={9} sm={8} xs={12} className="pt-3">
                  <Row>
                    <label className="viewCompanyName">
                      {companyData?.Result?.CompanyName}
                    </label>
                    <label className="fw-normal viewCompanyUrl">
                      {companyData?.Result?.CompanyWebsiteUrl}
                    </label>
                    <label className="fw-normal  viewCompanydescription">
                      {companyData?.Result?.Description}
                    </label>
                  </Row>
                </Col>
              </Row>

              <Row className="mt-3">
                <label className="form-label  mb-3 titleFont">Address</label>
                {companyData?.Result?.CompanyAddresses.map(
                  (company: any, index: number) => (
                    <Col key={index} sm={6} xs={12}>
                      <div className="customeboxShadow addressBox">
                        <div className="d-flex flex-column">
                          <label>
                            {company.AddressLine1},{company.AddressLine2}
                          </label>
                          <label>{company.CityName}</label>
                          <label>
                            {company.StateName} {company.ZipCode}
                          </label>
                        </div>
                        <div className="primaryAddress">
                          <label>
                            {company.IsPrimaryAddress ? 'Primary Address' : ''}
                          </label>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>

              <Row>
                <label className="form-label mb-3 titleFont">
                  Contact info
                </label>
                {companyData?.Result?.CompanyContactInfos.map(
                  (company: any, index: number) => (
                    <Col key={index} sm={6} xs={12}>
                      <div className="mb-3 customeboxShadow contactInfoBox">
                        <div className="d-flex flex-column">
                          <label className="fw-semibold">
                            {company.FirstName} {company.LastName}
                          </label>
                          <label className="fw-normal">{company.Email}</label>
                          <label className="fw-normal">
                             {company.PhoneNumber}
                          </label>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>

              <Row>
                <label className="form-label mb-3 titleFont">Skills</label>
                {companyData?.Result?.CompanySkills.map(
                  (company: any, index: number) => (
                    <Col key={index} md={4} sm={6} xs={12} className="mb-3">
                      <div className="customeboxShadow contactInfoBox">
                        <div>
                          <label>{company.SkillName}</label>
                        </div>
                      </div>
                    </Col>
                  )
                )}
              </Row>
            </Container>
          </form>
        </div>
      </Card>
    </Modal>
  );
};
export default ViewCompany;
