import clsx from 'clsx';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { userLogin } from '../../store/slice/auth/authActions';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { InputAdornment, TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 characters required')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
});

const initialValues = {
  email: '',
  password: '',
};

export function Login() {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const enteredEmail = location.state

  const formik = useFormik({
    initialValues:{
      email: enteredEmail ?? '',
  password: '',
    },
    validationSchema: loginSchema,
    onSubmit: async values => {
      setLoading(true);
      const payload = {
        username: values.email,
        password: values.password,
      };
      await dispatch(userLogin(payload));
      setLoading(false);
    },
  });

  return (
    <form
      className="form w-100"
      onSubmit={formik.handleSubmit}
      noValidate
      id="kt_login_signin_form"
    >
      <div className="text-center mb-4">
        <div className="logo-box">
          <figure>
            <img
              src="/media/bg/Thrivelogo.png"
              style={{ height: '95px', width: '80px' }}
            />
          </figure>
        </div>

        <label className="mb-3" style={{ fontSize: '34px', color: '#ffffff' }}>
          ThriveCE
        </label>

        <h1 className="fw-bolder" style={{ color: '#F2F2F2' }}>
          Sign In
        </h1>
        <label className="mb-5" style={{ color: '#ECEBDF', fontSize: '20px' }}>
          Welcome to ThriveCE
        </label>
      </div>

      <div className="fv-row mb-4">
        <TextField
          placeholder="Email"
          {...formik.getFieldProps('email')}
          type="email"
          name="email"
          autoComplete="off"
          style={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: '#F2F2F2',
          }}
        />
        {formik.touched.email && formik.errors.email && (
          <div className="fv-plugins-message-container">
            <span className="alert text-danger">{formik.errors.email as string}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-4">
        <TextField
          className="input-field"
          type={showPassword ? 'text' : 'password'}
          placeholder="Password"
          autoComplete="off"
          style={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: '#F2F2F2',
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? (
                    <VisibilityIcon style={{ fill: '#103558' }} />
                  ) : (
                    <VisibilityOffIcon style={{ fill: '#103558' }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
          {...formik.getFieldProps('password')}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="fv-plugins-message-container">
            <span className="alert text-danger">{formik.errors.password}</span>
          </div>
        )}
      </div>

      <div className="mb-5">
        <Link
          to="/auth/forgot-password"
          state={formik.values.email}
          className=" d-flex justify-content-end"
          style={{ color: '#88BDD1', fontSize: '14px' }}
        >
          Forgot Password ?
        </Link>
      </div>

      <div className="d-grid mb-3">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn"
          style={{ backgroundColor: '#107FA9' }}
        >
          {!loading && (
            <span
              className="indicator-label"
              style={{ fontSize: '15px', color: '#FFFFFF' }}
            >
              Sign In
            </span>
          )}
          {loading && (
            <span
              className="indicator-progress"
              style={{ display: 'block', color: 'white' }}
            >
              Please wait...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
