/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Outlet, Route, Routes, useLocation } from 'react-router-dom';
import { ForgotPassword } from '../../components/auth/ForgotPassword';
import { Login } from '../../components/auth/Login';
import versionInfo from '../../versionInfo';

// import { Box } from "@mui/material";

const AuthLayout = () => {
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add('bg-body');
    return () => {
      document.body.classList.remove('bg-body');
    };
  }, []);

  return (
    <>
      {/* <Box
      component="img"
      src="/assets/thrive-logo.png"
      sx={{ width: 40, height: 40, cursor: 'pointer',position:"relative",top:"24px",left:"24px"}} */}
      {/* <svg xmlns="http://www.w3.org/2000/svg"  width="79" height="75" viewBox="0 0 79 93" fill="none">
        <g clip-path="url(#clip0_434_24067)">
          <path d="M36.2654 79.7866H54.3306V55.1473H66.8759C68.4228 55.1473 69.9547 54.8386 71.3839 54.2389C72.8131 53.6391 74.1118 52.76 75.2057 51.6518C76.2995 50.5436 77.1672 49.2279 77.7593 47.78C78.3513 46.332 78.656 44.7801 78.656 43.2129C51.5946 45.8205 34.8425 53.7174 24.483 62.239V67.8545C24.4842 71.0195 25.7261 74.0545 27.9356 76.292C30.1451 78.5296 33.1413 79.7866 36.2654 79.7866Z" fill="#8BC53F" />
          <path d="M23.494 55.1479H24.4829V62.2396C34.8425 53.718 51.5946 45.8119 78.6559 43.2135V32.1074C67.1248 33.9191 40.6184 39.6179 23.494 55.1479Z" fill="#578412" />
          <path d="M78.656 24.9092C78.656 24.9092 4.20234 41.7071 10.1404 92.168C10.1404 92.168 8.43433 51.0825 78.656 36.5197V24.9092Z" fill="#8BC53F" />
          <path d="M54.3306 24.893V0.267578H36.2653C34.7182 0.267274 33.1861 0.575738 31.7566 1.17536C30.3271 1.77499 29.0282 2.65402 27.9341 3.76226C26.8399 4.8705 25.972 6.18625 25.3799 7.63435C24.7877 9.08245 24.4829 10.6345 24.4829 12.202V24.9092H0.159912V43.2134C0.159912 46.3778 1.4004 49.4126 3.60861 51.6506C5.81682 53.8886 8.81197 55.1466 11.9355 55.1478H16.2131C24.8666 40.7377 40.7485 30.9967 54.3306 24.893Z" fill="#06A9B1" />
        </g>
        <defs>
          <clipPath id="clip0_434_24067">
            <rect width="78.4983" height="91.9005" fill="white" transform="translate(0.159912 0.267578)" />
          </clipPath>
        </defs>
      </svg> */}
      {/* <label style={{fontSize:"25px",paddingTop:"15px",position:"absolute",top:"75%",color:"#ffffff"}}>ThriveCE</label> */}

      <div
        style={{ minHeight: '100vh', overflow: 'auto' }}
        className="position-relative d-flex p-lg-15 p-sm-10 align-items-center flex-column"
      >
        <div className="bg-image-shape-box">
          <img
            className="login-bg-image"
            src={'/media/bg/ThriveCEbackgroundimage.png'}
          />
        </div>
        {/* <div className="d-grid h-100 w-50">
        <div className="position-absolute" style={{ left: "19%", top: "10%" }}>
          <img
            className="w-200px"
            // style={{ height: "100vh" }}
            src={toAbsoluteUrl("/media/app-logo/logo-light.png")}
          />
        </div>
        <img
          className="w-100 m-auto"
          style={{ height: "100vh" }}
          src={toAbsoluteUrl("/media/bg/image_1.png")}
        />
      </div> */}
        <div className="align-content-center justify-content-md-start justify-content-center g-0 h-100 px-sm-10 px-2 px-lg-15 row w-100">
          {/* begin::Content */}
          <div
            className="align-items-center d-flex h-100"
            style={{ width: '460px' }}
          >
            {/* begin::Logo */}
            {/* <a href="#" className="mb-12">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/default.svg")}
            className="h-45px"
          />
        </a> */}
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className="scrollbarHide login-form-box w-100 p-sm-11 p-8">
              <div className="logo-box mb-3 mb-md-8">
                {/* <figure>
                <img src={("/media/app-logo/logo.png")} />
              </figure> */}
              </div>
              <Outlet />
              {location.pathname === '/auth/login' && (
                <div className="d-flex justify-content-center align-items-center">
                  <div>
                    <p
                      className="p-0 m-0 text-center"
                      style={{ color: '#F9FAFB', fontSize: '12px' }}
                    >
                      Copyright © 2024 ThriveCE All rights reserved.{' '}
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="d-flex align-items-center justify-content-center fw-bold fs-6 mt-2">
                <p
                  className="p-0 m-0 text-center"
                  style={{ color: '#F9FAFB', fontSize: '12px' }}
                >
                  Version: {versionInfo?.buildNumber}
                </p>
              </div> */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
          {/* begin::Footer */}
          {/* end::Footer */}
        </div>
      </div>
    </>
  );
};

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
);

export { AuthPage };
