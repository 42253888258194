import { Box, IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import MuiTable from '../../../../components/table/MuiTable';
import { ProviderSkillStatusEnum } from '../../../helpers/enum';
import { getSearchprovider } from '../api';
import { Settings } from '../../../../components/accounts/settings/Settings';
import { ReactComponent as Setting } from '../../../assets/svg/setting.svg';
import { ReactComponent as UserProfile } from '../../../assets/svg/userprofile.svg';
import { APP_URLs } from '../../../../constants/appURLs';

interface LocationState {
  cityvalue: string;
  companyId: string;
  cityid: string;
  selectedskillId: string;
  nursingoptioncityvalue: string;
  selectedOption: string;
  stepvalue: number;
  AssignmentData: any;
  InvitedUsedIds: any;
  TableDates: any;
  shiftDetails: any;
  shiftcheckboxdetails: any;
}

const modalsRoot = document.getElementById('root-modals') || document.body;

const Addassignment = () => {
  const location = useLocation();
  const selectedCity = (location.state as LocationState)?.cityvalue || '';
  const nursingHomeCityValue =
    (location.state as LocationState)?.nursingoptioncityvalue || '';
  const companyid = (location.state as LocationState)?.companyId || '';
  const cityId = (location.state as LocationState)?.cityid || '';
  const skillId = (location.state as LocationState)?.selectedskillId || '';
  const selectedoption =
    (location.state as LocationState)?.selectedOption || '';
  const StepValue = (location.state as LocationState)?.stepvalue || 0;
  const assignmentvalues =
    (location.state as LocationState)?.AssignmentData || '';
  const InvitedUserIds =
    (location.state as LocationState)?.InvitedUsedIds || [];
  const TableSelectedDates =
    (location.state as LocationState)?.TableDates || [];
  const ShiftDetails = (location.state as LocationState)?.shiftDetails || [];
  const tabledatescheckbox =
    (location.state as LocationState)?.shiftcheckboxdetails || [];
  const navigate = useNavigate();
  const tableRef = useRef();
  const [tableOptions, setTableOptions] = useState({
    offset: 0,
    limit: 10,
    hasNextPage: true,
    hasPrevPage: true,
    totalRowCount: undefined,
    search: '',
  });

  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const [newItem, setNewItem] = useState<string>('');

  const {
    mutateAsync: getSearchProviderdata,
    data: appProviderListData,
    isLoading: isGettingAppProviderData,
  } = useMutation('golf-list', getSearchprovider);

  const handleBackClick = () => {
    let finalItems: any[] = [...selectedItems];
    if (newItem.trim() !== '') {
      finalItems.push(newItem.trim());
      setNewItem('');
    }
    finalItems = finalItems.filter(
      (item, index) => finalItems.indexOf(item) === index
    );
    setSelectedItems(finalItems);
    navigate('/new-assignment', {
      state: {
        selectedItems,
        StepValue,
        AssignmentDataValues: assignmentvalues,
        TableSelectedDates,
        ShiftDetails,
        selectedCity,
        selectedoption,
        tabledatescheckbox,
      },
    });
  };

  const handleCheckboxChange = (item: any) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(prevSelectedItems =>
        prevSelectedItems.filter(selectedItem => selectedItem !== item)
      );
    } else {
      setSelectedItems(prevSelectedItems => [...prevSelectedItems, item]);
    }
  };
  const handleAddItem = () => {
    let finalItems: any[] = [...selectedItems];
    if (newItem.trim() !== '') {
      finalItems.push(newItem.trim());
      setNewItem('');
    }
    finalItems = finalItems.filter(
      (item, index) => finalItems.indexOf(item) === index
    );
    setSelectedItems(finalItems);
    navigate('/new-assignment', {
      state: {
        selectedItems,
        StepValue,
        AssignmentDataValues: assignmentvalues,
        TableSelectedDates,
        ShiftDetails,
        selectedCity,
        selectedoption,
        tabledatescheckbox,
      },
    });
  };

  useEffect(() => {
    if (companyid && cityId && skillId) {
      getSearchProviderdata({
        id: Number(companyid),
        cityId: Number(cityId),
        skillIds: Number(skillId),
      });
    }
  }, [companyid, cityId, skillId]);

  const columns: GridColDef[] = [
    {
      field: 'profile',
      headerName: '',
      flex: 0.5,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <input
            type="checkbox"
            className="form-check-input"
            style={{
              height: '20px',
              width: '20px',
              marginTop: '2px',
              alignItems: 'center',
              justifyItems: 'center',
              border: '1px solid #103558',
              marginBottom: '5px',
            }}
            onChange={() => handleCheckboxChange(data?.row)}
          />
        );
      },
    },
    {
      field: 'profile1',
      headerName: '',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              position: 'relative',
              width: '140px',
              height: '125px',
              margin: '5px',
            }}
          >
            {data.row.ProfilePictureUrl ? (
              <img
                height="100%"
                width="100%"
                style={{ borderRadius: '10px' }}
                src={`${APP_URLs.viewDocBaseURL}/${data.row.ProfilePictureUrl}`}
                alt="API Image"
              />
            ) : (
              <UserProfile
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                }}
              />
            )}
          </div>
        );
      },
    },
    {
      field: 'profile2',
      headerName: 'Clinician',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              fontFamily: 'Red Hat Display',
              height: '70px',
              lineHeight: '70px',
            }}
          >
            <label style={{ fontSize: '14px' }} className="">
              {data.row.FirstName + ' ' + data.row.LastName}
            </label>
          </div>
        );
      },
    },
    {
      field: 'mobileno',
      headerName: 'Mobile Number',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              fontFamily: 'Red Hat Display',
              height: '70px',
              lineHeight: '70px',
            }}
          >
            <label style={{ fontSize: '14px' }}>{data.row.MobileNumber}</label>
          </div>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              fontFamily: 'Red Hat Display',
              height: '70px',
              lineHeight: '70px',
            }}
          >
            <label style={{ fontSize: '14px' }}>{data.row.Email}</label>
          </div>
        );
      },
    },
    {
      field: 'address',
      headerName: 'Address',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              fontFamily: 'Red Hat Display',
              height: '70px',
              lineHeight: '70px',
            }}
          >
            <label style={{ fontSize: '14px' }}>{data.row.Address}</label>
          </div>
        );
      },
    },
    {
      field: 'skillstatus',
      headerName: 'Skill Status',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <div
            style={{
              backgroundColor: '#00786729',
              width: '80px',
              height: '24px',
              position: 'absolute',
              padding: '4px 10px',
              borderRadius: '6px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {data.row.ProviderSkill.StatusId ===
              ProviderSkillStatusEnum.Approved && (
              <label style={{ fontWeight: '400', fontSize: '14px' }}>
                <p
                  style={{ fontWeight: 'bold', marginBottom: 0, marginTop: 0 }}
                >
                  {data.row.ProviderSkill.StatusName}
                </p>
              </label>
            )}
            {data.row.ProviderSkill.StatusId ===
              ProviderSkillStatusEnum.Pending && (
              <label style={{ fontWeight: '400', fontSize: '14px' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    color: '#F6851F',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {data.row.ProviderSkill.StatusName}
                </p>
              </label>
            )}
            {data.row.ProviderSkill.StatusId ===
              ProviderSkillStatusEnum.Expired && (
              <label style={{ fontWeight: '400', fontSize: '14px' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    color: 'red',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {data.row.ProviderSkill.StatusName}
                </p>
              </label>
            )}
            {data.row.ProviderSkill.StatusId ===
              ProviderSkillStatusEnum.Reject && (
              <label style={{ fontWeight: '400', fontSize: '14px' }}>
                <p
                  style={{
                    fontWeight: 'bold',
                    color: 'red',
                    marginBottom: 0,
                    marginTop: 0,
                  }}
                >
                  {data.row.ProviderSkill.StatusName}
                </p>
              </label>
            )}
          </div>
        );
      },
    },
    {
      field: 'prvoidertype',
      headerName: 'Clinician Type',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      align: 'center',
      renderCell: (data: any) => {
        return (
          <>
            {data.row.CompanyProviders.find(
              (type: any) => type.CompanyId == companyid
            ) && (
              <div
                style={{
                  backgroundColor: '#88BDD1',
                  width: '127px',
                  height: '24px',
                  position: 'absolute',
                  padding: '4px 10px',
                  borderRadius: '6px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <b>Facility Clinician</b>
              </div>
            )}
          </>
        );
      },
    },

    {
      field: 'option',
      headerName: 'Option',
      flex: 2,
      sortable: false,
      cellClassName: 'cell-padding',
      headerAlign: 'center',
      renderCell: (data: any) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <IconButton>
                <Setting />
              </IconButton>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Col xs={6}>
        <Button
          size="lg"
          onClick={handleBackClick}
          style={{
            color: 'black',
            background: 'none',
            border: '1px solid #103558',
            width: '140px',
          }}
          variant="secondary"
        >
          Back
        </Button>
      </Col>
      <Card className="" style={{ backgroundColor: '#f9f9f9' }}>
        <div
          className="modal-header mt-5"
          style={{
            backgroundColor: '#f9f9f9',
            borderBottom: '2px solid #919EAB66',
            padding: '10px 22px',
          }}
        >
          <h2>Find Clinician</h2>
        </div>

        <div className="p-5" style={{ height: '80vh', overflowY: 'auto' }}>
          <form>
            <Container>
              <div className="mt-5">
                <p
                  className="fw-normal"
                  style={{
                    fontWeight: '300',
                    fontSize: '15px',
                    margin: '5px 0px',
                  }}
                >
                  Looking for Clinician in this city area:
                  {selectedoption === 'patients-home' && selectedCity && (
                    <span style={{ fontWeight: '500' }}>{selectedCity}</span>
                  )}
                  {selectedoption === 'nursing-home' &&
                    nursingHomeCityValue && (
                      <span style={{ fontWeight: '500' }}>
                        {nursingHomeCityValue}
                      </span>
                    )}
                </p>
              </div>
              {/* ))} */}

              <Box mt={2}>
                <label
                  className="fw-normal mb-4"
                  style={{ fontWeight: 'normal' }}
                >
                  Invite Clinician
                </label>
                <input
                  type="text"
                  className="form-control form-control-lg form-control-solid"
                  placeholder="Search Clinician"
                  aria-label="Search"
                  style={{
                    backgroundImage: `url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.80454 2.40976C7.78799 2.40985 6.78619 2.65303 5.88273 3.11903C4.97928 3.58503 4.20036 4.26032 3.61097 5.08857C3.02158 5.91683 2.6388 6.87402 2.49457 7.88029C2.35035 8.88657 2.44885 9.91274 2.78188 10.8732C3.1149 11.8337 3.67278 12.7006 4.40897 13.4016C5.14516 14.1026 6.03832 14.6173 7.01393 14.903C7.98953 15.1886 9.0193 15.2367 10.0173 15.0434C11.0153 14.8501 11.9526 14.421 12.751 13.7918L15.49 16.5308C15.6315 16.6674 15.8209 16.743 16.0176 16.7413C16.2142 16.7396 16.4024 16.6607 16.5414 16.5216C16.6805 16.3826 16.7593 16.1945 16.761 15.9978C16.7628 15.8012 16.6872 15.6117 16.5505 15.4703L13.8115 12.7313C14.5525 11.7912 15.0139 10.6616 15.1429 9.47155C15.2718 8.28155 15.0631 7.07928 14.5407 6.00233C14.0183 4.92538 13.2032 4.01728 12.1888 3.38193C11.1743 2.74659 10.0015 2.40968 8.80454 2.40976ZM3.92954 8.78476C3.92954 7.49183 4.44316 6.25185 5.3574 5.33761C6.27164 4.42337 7.51161 3.90976 8.80454 3.90976C10.0975 3.90976 11.3374 4.42337 12.2517 5.33761C13.1659 6.25185 13.6795 7.49183 13.6795 8.78476C13.6795 10.0777 13.1659 11.3177 12.2517 12.2319C11.3374 13.1461 10.0975 13.6598 8.80454 13.6598C7.51161 13.6598 6.27164 13.1461 5.3574 12.2319C4.44316 11.3177 3.92954 10.0777 3.92954 8.78476Z' fill='%23637281'/%3E%3C/svg%3E")`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left 8px center',
                    padding: '20px 30px 0px 30px',
                    border: '1px solid #919EAB66',
                  }}
                />
              </Box>

              <div className="mt-5">
                <label style={{ fontSize: '16px', fontWeight: 'normal' }}>
                  Select Clinician
                </label>
              </div>
              <MuiTable
                columns={columns}
                isAddEnable={false}
                data={appProviderListData?.Result ?? []}
                loading={isGettingAppProviderData}
                tableRef={tableRef}
                isRowClickable={true}
                tableOptions={tableOptions}
              />
              <div className="w-100 d-flex p-5 align-items-end justify-content-end mt-3">
                <Button
                  type="button"
                  variant="primary"
                  style={{
                    width: '170px',
                    fontSize: '13px',
                    padding: '10px 0px',
                  }}
                  onClick={handleAddItem}
                >
                  Add
                </Button>
              </div>
              {/* </div > */}
            </Container>
          </form>
        </div>
      </Card>
    </>
  );
};

export default Addassignment;
